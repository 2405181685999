<template>
	<div class="content">
		<div class="banner">
			<div class="banner_showConfig">
				<div
					@click="showMsg"
					class="config_body"
					v-for="item in configList"
					v-bind:key="item.config_code"
					:style="{ background: `${item.background_color}` }"
				>
					<div class="config_body_top">
						<div class="config_body_top_icon">
							<Icon :icon="item.icon"></Icon>
						</div>
						<div class="config_body_top_amount">*** ￥</div>
					</div>
					<div class="config_body_bottom">{{ item.config_name }}</div>
				</div>
			</div>
			<div class="newbackgroundImg" :style="{ backgroundImage: `url(` + banner + `)` }"></div>
			<a-button type="primary" class="banner_btn"
				><RouterLink to="/Register">{{
					this.$lang.GetLangByKey('CreateAccount')
				}}</RouterLink></a-button
			>
		</div>
		<div class="form">
			<div class="form_title">
				<div class="form_title_logo">
					<img :src="formIcon" alt="log" />
					<div class="form_title_logo_text"></div>
				</div>
				<div class="form_title_wellcome">
					{{ this.$lang.GetLangByKey('WelcomeBack') }}
				</div>
				<div class="form_title_continue">
					{{ this.$lang.GetLangByKey('SignInToContinue') }}
				</div>
			</div>
			<div class="form_context">
				<a-form :hideRequiredMark="true" layout="vertical">
					<a-form-item :label="this.$lang.GetLangByKey('Email')" v-bind="validateInfos.email">
						<a-input
							v-model:value="dataModel.email"
							:placeholder="this.$lang.GetLangTipsByKey('LoginEmail')"
							@blur="validate('email', { trigger: 'blur' }).catch(() => {})"
						/>
					</a-form-item>
					<a-form-item
						:label="this.$lang.GetLangByKey('Password')"
						v-bind="validateInfos.password"
						class="form_context_pwd"
					>
						<a-input-password
							autocomplete="true"
							:placeholder="this.$lang.GetLangTipsByKey('LoginPwd')"
							v-model:value="dataModel.password"
							@blur="
								validate('password', {
									trigger: 'blur',
								}).catch(() => {})
							"
						/>
					</a-form-item>
				</a-form>
			</div>

			<div class="forget">
				<a @click="showService">{{ this.$lang.GetLangByKey('ForgotPassword') }}</a>
			</div>
			<div class="submit">
				<a-button type="primary" @click="onSubmit">{{
					this.$lang.GetLangByKey('Submit')
				}}</a-button>
			</div>
			<!-- <a-divider>Or Sign In With</a-divider> -->
			<div class="signInWithThired">
				<!-- <div><img :src="googleAccountIcon" alt="sign on google account" /></div>
        <div>
          <img :src="facebookAccountIcon" alt="sign on facebook account" />
        </div> -->
				<div></div>
			</div>
		</div>
	</div>
	<service-modal
		v-model:visible="visible"
		@changeVisible="changeVisible"
		:email="email"
		:title="title"
	>
	</service-modal>
</template>

<script>
	import { Icon } from '@/components/util/iconUtil.js';
	import { ref, reactive } from 'vue';
	import cookies from 'js-cookie';
	import userApi from '@/http/apiUtil/userApi';
	import { getIp } from '@/http/api.js';
	import { Form, message } from 'ant-design-vue';
	import lang from '@/lang/index';
	import serviceModal from '@/components/service/serviceModal.vue';
	const useForm = Form.useForm;
	export default {
		components: { serviceModal, Icon },
		data() {
			return {
				configList: [
					{
						config_name: '直播间人气',
						config_price: 220,
						config_code: 1001,
						proxy_price: 220,
						userid: 0,
						username: null,
						user_key: null,
						token: null,
						host: null,
						agent_domain_name: null,
						email: null,
						background_color: 'linear-gradient(150deg, #fa7d74 0%, #f94e39 100%)',
						icon: 'FireOutlined',
					},
					{
						config_name: '直播间发言',
						config_price: 10000,
						config_code: 1002,
						proxy_price: 10000,
						userid: 0,
						username: null,
						user_key: null,
						token: null,
						host: null,
						agent_domain_name: null,
						email: null,
						background_color: 'linear-gradient(150deg, #fa7d74 0%, #f94e39 100%)',
						icon: 'MessageOutlined',
					},
					{
						config_name: '视频评论 ',
						config_price: 200,
						config_code: 1004,
						proxy_price: 200,
						userid: 0,
						username: null,
						user_key: null,
						token: null,
						host: null,
						agent_domain_name: null,
						email: null,
						background_color: 'linear-gradient(39deg, #00A4FA 0%, #006BF2 100%)',
						icon: 'MessageOutlined',
					},
					{
						config_name: '视频点赞',
						config_price: 7,
						config_code: 1005,
						proxy_price: 7,
						userid: 0,
						username: null,
						user_key: null,
						token: null,
						host: null,
						agent_domain_name: null,
						email: null,
						background_color: 'linear-gradient(39deg, #00A4FA 0%, #006BF2 100%)',
						icon: 'LikeOutlined',
					},
					{
						config_name: '直播间点赞',
						config_price: 3000,
						config_code: 1003,
						proxy_price: 3000,
						userid: 0,
						username: null,
						user_key: null,
						token: null,
						host: null,
						agent_domain_name: null,
						email: null,
						background_color: 'linear-gradient(150deg, #fa7d74 0%, #f94e39 100%)',
						icon: 'LikeOutlined',
					},
					{
						config_name: '关注粉丝',
						config_price: 10,
						config_code: 1006,
						proxy_price: 10,
						userid: 0,
						username: null,
						user_key: null,
						token: null,
						host: null,
						agent_domain_name: null,
						email: null,
						background_color: 'linear-gradient(150deg, #fa7d74 0%, #f94e39 100%)',
						icon: 'UserOutlined',
					},

					{
						config_name: '业务扩展中',
						config_price: 10,
						config_code: 1006,
						proxy_price: 10,
						userid: 0,
						username: null,
						user_key: null,
						token: null,
						host: null,
						agent_domain_name: null,
						email: null,
						background_color: 'linear-gradient(39deg, #00A4FA 0%, #006BF2 100%)',
						icon: 'MoreOutlined',
					},
					{
						config_name: '业务扩展中',
						config_price: 10,
						config_code: 1006,
						proxy_price: 10,
						userid: 0,
						username: null,
						user_key: null,
						token: null,
						host: null,
						agent_domain_name: null,
						email: null,
						background_color: 'linear-gradient(39deg, #00A4FA 0%, #006BF2 100%)',
						icon: 'MoreOutlined',
					},
				],
			};
		},
		setup() {
			let visible = ref(false);
			let email = ref('');
			let title = ref('请联系客服激活账户');
			const banner = ref(require('@/assets/homepage/login_new.png'));
			const formIcon = ref(require('@/assets/account/TitleIcon.png'));
			const googleAccountIcon = ref(require('@/assets/account/GoogleAccountLog.png'));
			const facebookAccountIcon = ref(require('@/assets/account/FacebookAccountLog.png'));

			const dataModel = reactive({
				email: '',
				password: '',
			});
			let validateEmail = async (_rule, value) => {
				let pattEmail = /^[A-Za-z0-9\u4e00-\u9fa5.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
				if (value === '') {
					return Promise.reject(lang.GetLangTipsByKey('LoginEmail'));
				} else if (!pattEmail.test(value)) {
					return Promise.reject('请输入正确的邮箱格式');
				} else {
					return Promise.resolve();
				}
			};
			const rulesRef = reactive({
				email: [
					{
						required: true,
						// message: lang.GetLangTipsByKey("LoginEmail"),
						trigger: 'blur',
						validator: validateEmail,
					},
				],
				password: [
					{
						required: true,
						message: lang.GetLangTipsByKey('LoginPwd'),
					},
				],
			});
			const { resetFields, validate, validateInfos } = useForm(dataModel, rulesRef);
			let changeVisible = (flag) => {
				visible.value = flag;
			};
			const onSubmit = () => {
				validate()
					.then(async () => {
						let info = {
							email: dataModel.email,
							userpwd: dataModel.password,
						};
						let res = await userApi.Login(info);
						if (res.ret_code == 200) {
							cookies.set('userInfo', encodeURI(JSON.stringify(res.ret_obj)));
							//写入cookie
							cookies.set('token', res.ret_obj.token);
							cookies.set('userid', res.ret_obj.userid);
							cookies.set('userkey', res.ret_obj.user_key);
							cookies.set('username', res.ret_obj.username);
							cookies.set('userInfo', encodeURI(JSON.stringify(res.ret_obj)));
							message.success(res.ret_msg);
							window.location.href = '/#/Index/HomePage';
						} else if (res.ret_msg == '您的账号已停用，请联系管理员！') {
							title.value = '请联系客服激活账户';
							email.value = dataModel.email;
							changeVisible(true);
						}
					})
					.catch((err) => {
						console.log('error', err);
					});
			};

			return {
				email,
				title,
				visible,
				rulesRef,
				banner,
				formIcon,
				validate,
				validateInfos,
				resetFields,
				onSubmit,
				changeVisible,
				dataModel,
				googleAccountIcon,
				facebookAccountIcon,
			};
		},
		beforeUnmount() {
			document.removeEventListener('keydown', this.handleWatchEnter);
		},
		async created() {
			var response = await getIp();
			if (response.status == 200) {
				response = response.data;
				cookies.set('Current-IP', response.ip);
			}
			console.log(response);
			document.addEventListener('keydown', this.handleWatchEnter);
		},
		methods: {
			showMsg() {
				this.$message.error('请先登录后再进行下单');
			},
			showService() {
				this.title = '请联系客服';
				this.changeVisible(true);
			},
			handleWatchEnter(e) {
				var key = window.event ? e.keyCode : e.which;
				let length = window.location.hash.indexOf('?');
				if (length == -1) {
					length = window.location.hash.length;
				}
				if (
					key === 13 &&
					window.location.hash.substring(0, length).toLowerCase() === '#/Login'.toLowerCase()
				) {
					this.onSubmit();
				}
			},
		},
		mounted() {
			cookies.remove('token');
			cookies.remove('userid');
			cookies.remove('user_key');
			cookies.remove('userkey');
			cookies.remove('username');
			cookies.remove('userInfo');
		},
	};
</script>

<style lang="scss" scoped>
	.content {
		height: 100vh;
		display: flex;
		.newbackgroundImg {
			background-size: cover;
			width: 768px;
			height: 477px;
		}
	}
	.banner {
		width: 58%;
		background-size: cover;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		background: linear-gradient(180deg, #ffffff 0%, #0080f5 100%);
		.banner_showConfig::after {
			flex: auto;
		}
		.banner_showConfig {
			width: 80%;
			position: absolute;
			top: 1rem;
			flex-wrap: wrap;
			display: grid;
			justify-content: space-around;
			grid-gap: 20px;
			grid-template-columns: repeat(auto-fill, 172px);
			.config_body {
				cursor: pointer;
				width: 172px;
				height: 93px;
				//background: linear-gradient(150deg, #fa7d74 0%, #f94e39 100%);
				border-radius: 22px;
				// border: 1px solid #ff8778;

				.config_body_top {
					display: flex;
					width: 100%;
					height: 50%;
					.config_body_top_icon {
						width: 50%;
						font-size: 22px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #ffffff;
						line-height: 47.5px;
						padding-left: 1rem;
					}
					.config_body_top_amount {
						width: 50%;
						font-size: 22px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #ffffff;
						line-height: 47.5px;
						padding-left: 1rem;
					}
				}
				.config_body_bottom {
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #ffffff;
					line-height: 45.5px;
					width: 100%;
					height: 50;
					padding-left: 1rem;
				}
			}
		}
		.banner_btn {
			width: 260px;
			height: 55px;
			border-radius: 25px;
			position: absolute;
			line-height: 45px;
			bottom: 100px;
			background-color: #373948;
			border-color: #373948;
		}
	}
	.form {
		padding: 80px;
		.form_title {
			.form_title_logo {
				display: flex;
				img {
					height: 41px;
					width: 64px;
				}
				.form_title_logo_text {
					margin-left: 31px;
					font-size: 23px;
					color: #1a110f;
				}
			}
			.form_title_wellcome {
				margin-top: 45px;
				font-size: 28px;
				color: #1a110f;
			}
			.form_title_continue {
				font-size: 14px;
				color: #877d7a;
				line-height: 17px;
				margin-top: 21px;
			}
		}
		.form_context {
			margin-top: 50px;
			::v-deep {
				.ant-form-item-label label {
					color: #1a110f;
					font-size: 14px;
					line-height: 17px;
				}
				.ant-form-item-control-input-content input {
					padding: 0px 30px;
					width: 512px;
					height: 65px;
					background: #ffffff;
					border-radius: 25px;
					border: 1px solid #dadada;
					font-size: 22px;
				}
			}
			.form_context_pwd {
				::v-deep {
					.ant-input-affix-wrapper {
						border: 0px;
						padding: 0px;
						input {
							outline: invert;
						}
						.ant-input-suffix {
							position: absolute;
							top: 25px;
							right: 20px;
							font-size: 20px;
						}
					}
				}
			}
		}
		.forget {
			margin-top: 31px;
		}
		.submit {
			margin-top: 53px;
			::v-deep {
				.ant-btn-primary {
					width: 512px;
					height: 65px;
					background: linear-gradient(180deg, #0086f5 0%, #46abff 100%);
					border-radius: 25px;
				}
			}
		}
		.signInWithThired {
			margin-top: 63px;
			display: flex;
			justify-content: space-between;
			img {
				width: 237px;
				height: 65px;
			}
		}
	}
</style>
