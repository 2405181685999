<template>
  <div class="service-modal-wrap" ref="warp_ref">
    <!-- <div class="click-div1"></div> -->
    <a-modal
      v-model:visible="isShow"
      title="Service"
      :footer="null"
      :getContainer="() => this.$refs.warp_ref"
    >
      <iframe
        class="iframe-body"
        :src="`${chatLink}/#/chat?username=${username}`"
      ></iframe>
    </a-modal>
  </div>
</template>

<script>
import cookies from "js-cookie";
export default {
  name: "",
  mixins: [],
  components: {},
  props: {
    visible: { type: Boolean, default: false },
  },
  data() {
    return {
      isShow: false,
      chatLink: process.env.VUE_APP_Chat_BASE_URL,
      username: cookies.get("username"),
      images: {},
    };
  },
  computed: {},
  watch: {
    visible: {
      immediate: true,
      handler(value) {
        this.isShow = value;
        if (value) {
          console.log("Username:" + cookies.get("username"));
          if (cookies.get("username") == undefined) {
            window.location.href = "/#/Login";
            this.$message.error("登录失效，请重新登录");
          }
          //记录日志
        }
      },
    },
    isShow: {
      handler(value) {
        this.$emit("changeVisible", value);
      },
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.service-modal-wrap {
  .contentBody {
    display: flex;
    width: 100%;
    justify-content: space-around;

    .info {
      width: 50%;
      text-align: center;
      .app-image {
        height: 8rem;
        width: 8rem;
      }
    }

    :deep(p) {
      font-weight: 700;
    }
  }
  :deep(.ant-modal) {
    height: 80% !important;
    width: 60% !important;
    top: 10%;
  }

  :deep(.ant-modal-content) {
    border-radius: 27px;
    height: 100%;
    .ant-modal-header {
      border-radius: 27px;
      border-bottom: 0px;
      text-align: center;

      .ant-modal-title {
        font-size: 1.1rem !important;
        font-weight: 700 !important;
      }
    }
    .ant-modal-body {
      height: calc(100% - 54px);
    }
  }
  .iframe-body {
    height: 100%;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 20px;
  }
}
@media only screen and (max-width: 900px) {
  .service-modal-wrap {
    :deep(.ant-modal) {
      padding-bottom: 0px;
      height: 90% !important;
      width: 100% !important;
      top: 5%;
    }
  }
}
</style>
