<template>
  <div class="m-home-wrap">
    <div class="home-wrap-header">
      <div class="home-wrap-header-title">
        DY海外版

        <span
          style="position: absolute; right: 1rem; color: rgb(255, 59, 99)"
          @click="showOnlineService(true)"
        >
          <icon
            icon="CustomerServiceOutlined"
            style="font-size: 1.8rem; margin-right: 0.5rem"
          ></icon>
          在线客服</span
        >
      </div>
      <div class="scroll-div">
        <div>
          <i class="icon icon-trumpet"></i>
          <span> 公告 </span>
          {{ headNoticeInfo.notice_remark }}
        </div>
      </div>
      <div class="home-wrap-header-title-menu">
        <div class="title-menu-item">
          <div>
            <router-link to="/Mobile/Order/OrderList">
              <span>我的订单</span>
              <p
                style="
                  position: absolute;
                  z-index: 99;
                  top: 2.8rem;
                  left: 0.8rem;
                  font-size: 0.6rem;
                "
              >
                订单记录
              </p>
              <a-image
                style=""
                :src="image.bg_order"
                :preview="false"
              ></a-image>
            </router-link>
          </div>
          <div>
            <router-link to="/Mobile/User/UserCenter">
              <span>个人账户</span>
              <p
                style="
                  position: absolute;
                  z-index: 99;
                  font-size: 0.8rem;
                  right: 0.5rem;
                  bottom: 0.5rem;
                  font-weight: 600;
                "
              >
                {{ price }}￥
              </p>
              <a-image style="" :src="image.bg_coin" :preview="false"></a-image>
            </router-link>
          </div>
        </div>
        <div class="title-menu-item">
          <div v-if="userAgent > 0">
            <router-link to="/Mobile/Agent/RebateIncome">
              <span>代理页面</span>
              <p
                style="
                  position: absolute;
                  z-index: 99;
                  bottom: 0.5rem;
                  right: 1rem;
                  font-size: 0.6rem;
                "
              >
                AGENT
              </p>
              <a-image
                style=""
                :src="image.bg_proxy"
                :preview="false"
              ></a-image>
            </router-link>
          </div>
          <div>
            <span>账户充值</span>
            <a-button
              type="primary"
              style="
                position: absolute;
                z-index: 99;
                border-radius: 5px;
                right: 0.8rem;
                bottom: 1.2rem;
              "
              @click="changeVisible4(true)"
              >立即充值</a-button
            >
            <a-image style="" :src="image.bg_add" :preview="false"></a-image>
          </div>
        </div>
        <div class="home-wrap-header-service">
          <a-image
            :src="image.bg_buyaccount"
            :preview="false"
            @click="jumpAccountBuy"
          ></a-image>
          <a-image
            :src="image.bg_service"
            :preview="false"
            @click="changeVisible3(true)"
          ></a-image>
        </div>
      </div>
    </div>
    <home-page></home-page>
    <service-modal v-model:visible="visible3" @changeVisible="changeVisible3">
    </service-modal>
    <recharge-modal v-model:visible="visible4" @changeVisible="changeVisible4">
    </recharge-modal>
    <service
      v-model:visible="onlineServiceModal.isShow"
      @changeVisible="showOnlineService"
    ></service>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from "vant";
import "vant/lib/index.css";
import HomePage from "@/views/index/HomePage.vue";
import RechargeModal from "@/components/pay/RechargeModal.vue";
import serviceModal from "@/components/service/serviceModal.vue";
import userApi from "@/http/apiUtil/userApi";
import { Icon } from "@/components/util/iconUtil.js";
import noticeApi from "@/http/apiUtil/noticeApi";
import service from "@/components/service/onlineServiceModel.vue";
import "@/css/icon.scss";
export default {
  name: "",
  mixins: [],
  components: {
    HomePage,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    RechargeModal,
    serviceModal,
    service,
    Icon,
  },
  props: {},
  data() {
    return {
      onlineServiceModal: {
        isShow: false,
      },
      image: {
        bg_order: require("@/assets/mobileImage/bg_order.png"),
        bg_coin: require("@/assets/mobileImage/bg_coin.png"),
        bg_add: require("@/assets/mobileImage/bg_add.png"),
        bg_proxy: require("@/assets/mobileImage/bg_proxy.png"),
        bg_service: require("@/assets/mobileImage/bg_service.png"),
        bg_buyaccount: require("@/assets/mobileImage/bg_buyaccount.png"),
        bg_right: require("@/assets/mobileImage/bg_right.png"),
      },
      visible4: false,
      visible3: false,
      price: 0,
      userAgent: 0,
      headNoticeInfo: {},
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getUserAmount();
    this.getUserIsAgent();
    this.getNotice();
  },
  methods: {
    //展示在线客服弹窗
    showOnlineService(value) {
      this.onlineServiceModal.isShow = value;
    },
    changeVisible3(flag) {
      this.visible3 = flag;
    },
    changeVisible4(flag) {
      this.visible4 = flag;
    },
    async getUserAmount() {
      var res = await userApi.UserAmout();
      if (res.ret_code == 200) {
        this.price = this.$util.formatPrice(res.ret_obj.user_amout);
      }
    },
    async getUserIsAgent() {
      var res = await userApi.UserIsAgent();
      if (res.ret_code == 200) {
        this.userAgent = res.ret_obj;
      }
    },
    jumpAccountBuy() {
      window.location.href = "/#/Mobile/Shpping/ShppingIndex";
    },
    //获取公告
    async getNotice() {
      let res = await noticeApi.NoticeList({});
      if (res.ret_code === 200) {
        this.headNoticeInfo = res.ret_obj.find((t) => t.notice_type == 0);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.bg_order {
  background-image: url(/src/assets/mobileImage/bg_order.png);
}

.m-home-wrap {
  :deep(.right) {
    display: none !important;
  }

  :deep(.left_banner) {
    display: none !important;
  }

  :deep(.left_tk_content_item) {
    width: 130px !important;
    height: auto;
  }

  :deep(.left_tk_content) {
    grid-template-columns: repeat(auto-fill, 130px);
  }

  :deep(.left_tk_content_item_row1_price) {
    font-size: 1rem !important;
  }

  :deep(.left_tk_content_item_row1_icon) {
    font-size: 1rem !important;
  }

  :deep(.left_contact_content) {
    display: none !important;
  }

  :deep(.left_contact_title) {
    display: none !important;
  }

  .home-wrap-header {
    background: linear-gradient(
      179deg,
      #008ff7 0%,
      rgba(0, 131, 245, 0.36) 43%,
      rgba(0, 124, 244, 0) 100%
    );
    border-radius: 0px 0px 22px 22px;

    .home-wrap-header-title {
      padding: 1rem;
      font-size: 1.1rem;
      font-weight: 600;
      color: white;
      position: relative;
    }
    .scroll-div {
      overflow: hidden;
      /* height: 50px; */
      color: red;
      padding: 0.5rem 0px;
      margin: 0px auto 0.5rem;
      background: #ffffff;
      display: flex;
      align-items: center;
      box-shadow: inset 0px 0px 3px 0px rgba(0, 150, 246, 0.54);
      border-radius: 13px;
      border: 1px solid #dcdcdc;
      width: 90%;
    }
    .scroll-div div {
      animation: scroll 20s linear infinite; /* 指定动画属性 */
      white-space: nowrap; /* 让文字不换行 */
      margin: 0; /* 清除默认外边距 */
      display: flex;
      align-items: center;
      span {
        margin-right: 1rem;
      }
    }
    @keyframes scroll {
      0% {
        transform: translateX(50%);
      }
      100% {
        transform: translateX(-100%);
      }
    }
    .home-wrap-header-title-menu {
      width: 90%;
      margin: 0px auto;
      color: #0086f5;

      .title-menu-item {
        display: flex;
        justify-content: space-around;

        div {
          position: relative;
          max-width: 50%;
          // background-size: cover;
          //  height: 6.5rem;

          // :deep(.ant-image) {
          //     position: absolute !important;
          //     width: 100% !important;
          //     height: 6rem !important;
          //     display: block !important;
          // }

          span {
            position: absolute;
            z-index: 999;
            font-weight: 700;
            top: 0.8rem;
            left: 0.8rem;
          }
        }

        div:not(:first-child) {
          margin-left: 1rem;
        }
      }

      .title-menu-item:not(:first-child) {
        margin-top: 1rem;
      }
    }

    .home-wrap-header-service {
      padding: 1rem 0px 1rem 0px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;

      // span {
      //     position: absolute;
      //     top: calc(50% - 1rem);
      //     z-index: 999;
      //     color: white;
      //     font-weight: 600;
      //     font-size: 1rem;
      //     left: 0.8rem;
      // }

      :deep(.ant-image) {
        width: 48%;
      }
    }
  }

  .left_tk_title {
    display: flex;
    padding: 20px 30px;

    .left_tk_title_block {
      width: 4px;
      height: 22px;
      background: linear-gradient(180deg, #00bbfb 0%, #0086f5 100%);
      border-radius: 4px;
    }

    .left_tk_title_content {
      margin-left: 8px;
      font-size: 16px;
      font-weight: 500;
      color: #0086f5;
    }
  }
}
</style>
