<template>
  <div class="loading_warp" v-show="isshow">
    <div class="loading_content">
      <spin size="large" tip="loading..." />
    </div>
  </div>
</template>

<script>
import { toRefs, reactive } from "vue";
import { Spin } from "ant-design-vue";
export default {
  props: { text: { type: String, default: "" } },
  components: { Spin },
  data() {
    return {};
  },
  setup(props) {
    let state = reactive({
      text: props.text,
      isshow: true,
    });

    function show() {
      state.isshow = true;
    }
    function hide() {
      state.isshow = false;
    }
    show();
    //hide();
    return {
      ...toRefs(state),
      show,
      hide,
    };
  },
};
</script>

<style lang="scss" scoped>
.loading_warp {
  text-align: center;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  z-index: 9999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
}
.loading_content {
  margin-top: 25%;
}
</style>
