<template>
    <van-popup class="modal" v-model:show="showModal" round closeable close-icon="close" position="bottom"
        :style="{ height: '80%' }">
        <div class="modal-title">{{ title }}</div>
        <div class="modal-body">
            <slot name="modalBody"></slot>
        </div>
    </van-popup>
</template>

<script>
import { Popup } from "vant";
export default {
    name: '',
    mixins: [],
    components: {
        [Popup.name]: Popup,
    },
    props: {
        title: { type: String, default: "" },
        is_show: { type: Boolean, default: false },

    },
    data() {
        return {
            showModal: false,
        }
    },
    computed: {},
    watch: {
        is_show: {
            immediate: true,
            deep: true,
            handler(value) {
                this.showModal = value;
            },
        },
        showModal: {
            handler(value) {
                this.$emit("changeVisible", value);
            },
        },
    },
    created() { },
    mounted() { },
    methods: {
    }
}
</script>

<style scoped lang="scss">
.modal {
    .modal-title {
        position: relative;
        width: 100%;
        background: #DBEFFF;
        height: 3rem;
        display: flex;
        align-items: center;
        color: #0692FF;
        font-size: 1.2rem;
        font-weight: 600;
        justify-content: center;
    }

    .modal-body {
        height: calc(100% - 3rem);
        overflow-y: auto;
        // display: grid;

    }
}

@media only screen and (min-width: 720px) {
    :deep(.van-popup) {
        z-index: 2007;
        height: 95%;
        width: 600px;
        left: auto;
        right: auto;
    }

}
</style>
