<template>
    <div class="m-agentindex-wrap">
        <mobile-title title="代理"></mobile-title>
        <div class="agentindex-wrap-header">
            <div class="header-menu">
                <div class="title">
                    <i style="position: absolute;" class="icon-more icon" @click="showMenu"></i>
                    <div style="margin: 0 auto;">{{ menuList.find(t => t.selected)?.title }}</div>
                </div>
                <div v-show="menuShow" class="menu">
                    <div :class="`${item.selected ? 'active' : ''}`" v-for="item in menuList" v-bind:key="item.key">
                        <router-link :to="item.to" @click="onClickMenu(item.key)">
                            {{ item.title }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="agentindex-wrap-body">
            <router-view @onClickMenu="onClickMenu"></router-view>
        </div>
    </div>
</template>

<script>
import mobileTitle from "@/components/mobile/title.vue";
export default {
    name: '',
    mixins: [],
    components: {
        mobileTitle
    },
    props: {},
    data() {
        return {
            menuList: [
                {
                    key: 1,
                    title: "返佣收益",
                    selected: false,
                    to: '/Mobile/Agent/RebateIncome',
                },
                {
                    key: 2,
                    title: "支出管理",
                    selected: false,
                    to: '/Mobile/Agent/ConsumeList',
                },
                {
                    key: 3,
                    title: "提现记录",
                    selected: false,
                    to: '/Mobile/Agent/WithdrawList',
                },
                {
                    key: 5,
                    title: "代理配置",
                    selected: false,
                    to: '/Mobile/Agent/ProxyConfigList',
                },
                {
                    key: 4,
                    title: "用户列表",
                    selected: false,
                    to: '/Mobile/Agent/UserList',
                }
            ],
            menuShow: false,
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() {

    },
    methods: {
        onClickMenu(key) {
            this.menuList.forEach(element => {
                if (element.key === key) {
                    element.selected = true;
                } else {
                    element.selected = false;
                }
            });
            this.menuShow = false;
        },
        showMenu() {
            this.menuShow = !this.menuShow;
        }
    }
}
</script>

<style scoped lang="scss">
.m-agentindex-wrap {
    .agentindex-wrap-header {
        padding: 0px 1rem 0px 1rem;

        .header-menu {
            padding: 1rem 0px 1rem 0px;
            // display: flex;
            justify-content: space-around;
            color: #0086F5;
            font-size: 1rem;
            position: relative;



            .title {
                display: flex;
                position: relative;
            }

            .menu {
                .active {
                    background: #F8F8F8;

                    a {
                        color: rgba(0, 0, 0, 0.25) !important;
                    }
                }

                box-shadow: 0px 2px 4px 0px #0086f5,
                0px 2px 4px 0px #0086f5;
                display: inline-block;

                position: absolute;
                background-color: white;

                div {
                    padding: 0.5rem 1rem 0.5rem 1rem;
                }
            }
        }
    }
}
</style>
