<template>
  <div class="createUserModal_warp" ref="createUserModal_warp">
    <a-modal
      v-model:visible="isShow"
      :title="title"
      :footer="null"
      :confirm-loading="confirmLoading"
      :getContainer="() => this.$refs.createUserModal_warp"
      :afterClose="reset"
    >
    
      <div class="createUserModal_warp_form">
        <a-form
          :model="formData"
          :hideRequiredMark="true"
          ref="formRef"
          layout="vertical"
        >
          <a-form-item
            :name="['email']"
            label="邮箱"
            :colon="false"
            :rules="[{ required: true }]"
          >
            <a-input v-model:value="formData.email" placeholder="请输入邮箱" />
          </a-form-item>
          <a-form-item
            :name="['username']"
            label="手机号码"
            :colon="false"
            :rules="[{ required: true }]"
          >
            <a-input
              v-model:value="formData.username"
              placeholder="请输入手机号码"
            />
          </a-form-item>
          <a-form-item
            :name="['userpwd']"
            label="密码"
            :colon="false"
            :rules="[{ required: true }]"
          >
            <a-input-password
              v-model:value="formData.userpwd"
              placeholder="请输入密码"
            />
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 14, offset: 8 }">
            <a-button type="primary" html-type="submit" @click="onSubmit"
              >确认</a-button
            >
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>
<script>
import userApi from "@/http/apiUtil/userApi";
export default {
  props: {
    title: { type: String, default: "创建子用户" },
    visible: { type: Boolean, default: false },
  },
  data() {
    return {
      isShow: false,
      confirmLoading: false,
      compact: false,
      formData: {
        email: null,
        username: null,
        userpwd: null,
      },
    };
  },
  mounted() {},
  methods: {
    reset() {
      this.formData = {
        email: null,
        username: null,
        userpwd: null,
      };
    },
    async onSubmit() {
      const param = await this.$refs.formRef.validateFields();
      let res = await userApi.ProxyCreateChildrenUser(param);
      if (res.ret_code === 200) {
        this.isShow = false;
      }
    },
  },

  setup() {},
  watch: {
    visible: {
      handler(value) {
        this.isShow = value;
      },
    },
    isShow: {
      handler(value) {
        this.$emit("changeVisible", value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.createUserModal_warp {
  .createUserModal_warp_form {
    padding: 0px 2rem;
  }

  ::v-deep {
    .ant-modal-content {
      //   min-width: 449px;
      //   min-height: 597px;
      background: #e5f3ff;
      box-shadow: 4px 13px 20px 0px rgba(146, 146, 146, 0.49);
      border-radius: 12px;
    }

    .ant-input-password {
      border-radius: 6px;
    }

    .ant-input {
      border-radius: 6px;
    }

    .ant-btn-primary {
      border-radius: 7px;
    }

    .ant-form-item-label > label {
      font-size: 10px;
    }

    .ant-input-group.ant-input-group-compact {
      .ant-select:last-child {
        .ant-select-selector {
          border-radius: 15px;
        }
      }
    }

    .ant-modal-header {
      background: #e5f3ff;
      border-radius: 12px;
      text-align: center;
      border-bottom: 0px;
    }

    .ant-modal-title {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0086f5;
      line-height: 20px;
    }
  }
}
</style>
