<template>
	<div class="wrap" ref="HomePage_warp">
		<div class="left">
			<div class="left_banner">
				<div class="left_banner_img">
					<img :src="banner" />
				</div>
				<div class="left_banner_title">
					{{ this.$lang.GetLangByKey('HomeWelCome') }} {{ userName }}！
				</div>
				<div class="left_banner_title_sub">
					<p v-html="String(homeNoticInfo.notice_remark || '').replaceAll('\n', '<br/>')"></p>
					<!-- 欢迎您来到我们的业务页面，这里为您的商业策略提供相关帮助。<br />
          为了您更好的商业策略，新添加发言订单开关弹幕，更换话术，延长时间，更改频率的功能。<br />
          祝您拥有一个愉快的使用体验。<br /> -->
				</div>
				<div class="left_banner_invate" @click="invateCopyClick()" v-show="InvateShow">
					邀请链接：{{
						this.$util.getCookieUserKey('agent_domain_name')
					}}&nbsp;&nbsp;|&nbsp;&nbsp;<span>复制</span>
				</div>
			</div>
			<div class="left_contact_title">
				<div class="left_contact_title_block"></div>
				<div class="left_contact_title_content">
					{{ this.$lang.GetLangByKey('GuidanceContact') }}
				</div>
			</div>
			<div v-if="showQKUrl.indexOf(host) == -1" class="left_contact_content">
				<div
					class="left_contact_content_item"
					v-for="(item, index) in contactMenu"
					:key="index"
					:style="{ flex: item.flex }"
				>
					<a-image
						:src="item.img"
						:preview="false"
						class="left_contact_content_item_img"
						@click="serviceClick(item.id)"
					/>
				</div>
			</div>
			<div v-else class="left_contact_content">
				<div class="left_contact_content_item" :style="{ flex: 4 }">
					<a-image
						:src="tiktok_qunkong"
						:preview="false"
						class="left_contact_content_item_img"
						@click="openQunkong()"
					/>
				</div>
				<div
					class="left_contact_content_item"
					v-for="(item, index) in contactMenu"
					:key="index"
					:style="{ flex: item.flex }"
					v-show="item.id > 3"
				>
					<a-image
						:src="item.img"
						:preview="false"
						class="left_contact_content_item_img"
						@click="serviceClick(item.id)"
					/>
				</div>
			</div>
			<div class="left_tk_title">
				<div class="left_tk_title_block"></div>
				<div class="left_tk_title_content">
					{{ this.$lang.GetLangByKey('TikTok') }}
				</div>
			</div>
			<div class="left_tk_content">
				<div
					@click="showConfig(item.id)"
					class="left_tk_content_item"
					:class="item.bgcolor === `red` ? `left_tk_content_item_red` : `left_tk_content_item_blue`"
					v-for="(item, index) in tkData"
					:key="index"
				>
					<div class="left_tk_content_item_row1">
						<div class="left_tk_content_item_row1_icon">
							<Icon :icon="item.icon"></Icon>
						</div>
						<div class="left_tk_content_item_row1_price">{{ item.price }}</div>
					</div>
					<div class="left_tk_content_item_row2">
						{{ item.title }}
					</div>
				</div>
			</div>

			<promotion-home :promotionType="0"></promotion-home>
		</div>
		<div class="right">
			<div class="right_card">
				<div class="right_card_imgTitle">
					<img
						:src="require(`@/assets/homepage/card.png`)"
						style="width: 100%; border-radius: 18px 18px 0px 0px"
					/>
				</div>
				<div style="padding: 25px; background-color: #8796a1">
					<div class="right_card_title">
						<div>{{ this.$lang.GetLangByKey('PersonalAssets') }}</div>
					</div>
					<div class="right_card_price">
						<div>{{ this.$lang.GetLangByKey('DollerTHRU') }}</div>
						<div>{{ tweened }}{{ this.$lang.GetLangByKey('PriceUnit') }}</div>
					</div>
					<div class="right_card_recharge">
						<button @click="showRechargeModal()">
							{{ this.$lang.GetLangByKey('Recharge') }}
						</button>
					</div>
				</div>
				<div class="right_data">
					<div class="right_data_title">
						<div>{{ this.$lang.GetLangByKey('OrderRecord') }}</div>
						<div @click="OrderDetail()">></div>
					</div>
					<div
						class="right_data_form"
						v-if="this.pagination.total == 0"
						:style="{
							backgroundImage: `url(${orderImage} )`,
							'background-size': `cover`,
							'overflow-y': 'hidden',
						}"
					></div>
					<div class="right_data_form" v-if="this.pagination.total != 0">
						<div class="right_data_form_status">
							<Icon icon="AimOutlined" />
							<div>{{ formDataCount }} {{ this.$lang.GetLangByKey('Data') }}</div>
						</div>
						<a-table
							:dataSource="dataSource"
							:columns="columns"
							:pagination="pagination"
							class="right_data_form_table"
						/>
					</div>
				</div>
			</div>
		</div>
		<transactions-modal
			v-model:visible="visible"
			:title="modalTitle"
			:text="modalText"
			:price="modalPrice"
			:code="modalcode"
			:showTime="modalShowTime"
			:step="ModalStep"
			@changeVisible="changeVisible"
		/>
		<transactions-modal2
			v-model:visible="visible2"
			:title="modalTitle"
			:text="modalText"
			:price="modalPrice"
			:code="modalcode"
			:showTime="modalShowTime"
			@changeVisible="changeVisible2"
		/>
		<service-modal v-model:visible="visible3" @changeVisible="changeVisible3"> </service-modal>
		<recharge-modal v-model:visible="visible4" @changeVisible="changeVisible4"> </recharge-modal>

		<batch-order-modal
			v-model:visible="batchOrderData.isShow"
			@changeVisible="batchOrderDataChangeVisible"
			:title="modalTitle"
			:text="modalText"
			:code="modalcode"
			:on-submit="batchOrderSubmit"
			:price="modalPrice"
		>
		</batch-order-modal>
		<batch-order-confirm-modal
			v-model:visible="batchOrderConfirmData.isShow"
			@changeVisible="batchOrderConfirmChangeVisible"
			:on-submit="batchOrderConfirmSubmit"
			:itle="modalTitle"
			:price="batchOrderConfirmData.price"
			:code="batchOrderConfirmData.code"
			:check-info="batchOrderConfirmData.checkInfo"
			:linksProp="batchOrderConfirmData.linksProp"
			:successLinksProp="batchOrderConfirmData.successLinksProp"
			:failLinksProp="batchOrderConfirmData.failLinksProp"
			:title="batchOrderConfirmData.title"
		/>
		<batch-comments-order-confirm-modal
			v-model:visible="batchCommentsOrderConfirmData.isShow"
			@changeVisible="batchOrderCommentsConfirmChangeVisible"
			:on-submit="batchOrderConfirmSubmit"
			:itle="modalTitle"
			:price="batchOrderConfirmData.price"
			:code="batchOrderConfirmData.code"
			:check-info="batchOrderConfirmData.checkInfo"
			:linksProp="batchOrderConfirmData.linksProp"
			:successLinksProp="batchOrderConfirmData.successLinksProp"
			:failLinksProp="batchOrderConfirmData.failLinksProp"
			:title="batchOrderConfirmData.title"
		></batch-comments-order-confirm-modal>
		<order-success-modal
			v-model:visible="orderSuccessVisible"
			@changeVisible="orderSuccesschangeVisible"
		></order-success-modal>
	</div>
</template>

<script>
	import lang from '@/lang/index';
	import RechargeModal from '@/components/pay/RechargeModal.vue';
	import TransactionsModal from '@/components/transactions/TransactionsModal.vue';
	import TransactionsModal2 from '@/components/transactions/TransactionsModal_two.vue';
	import serviceModal from '@/components/service/serviceModal.vue';
	import userApi from '@/http/apiUtil/userApi';
	import orderApi from '@/http/apiUtil/orderApi';
	import { Icon } from '@/components/util/iconUtil.js';
	import batchOrderModal from '@/components/transactions/batchOrderModal.vue';
	import batchOrderConfirmModal from '@/components/transactions/batchOrderConfirmModal.vue';
	import batchCommentsOrderConfirmModal from '@/components/transactions/batchCommentsOrderConfirmModal.vue';
	import { defineComponent, ref, reactive } from 'vue';
	import promotionHome from '@/components/promotion/promotionHome.vue';
	import orderSuccessModal from '@/components/order/orderSuccessModal.vue';

	import noticeApi from '@/http/apiUtil/noticeApi';
	import gsap from 'gsap';
	import { h } from 'vue';
	export default defineComponent({
		components: {
			Icon,
			orderSuccessModal,
			TransactionsModal,
			TransactionsModal2,
			serviceModal,
			RechargeModal,
			batchOrderModal,
			batchOrderConfirmModal,
			batchCommentsOrderConfirmModal,
			promotionHome,
		},
		data() {
			return {
				showQKUrl: [
					'web.zhuoqingdf11.com',
					'dfc.zhuoqingdf11.com',
					'dec.zhuoqingdf11.com',
					'agp.zhuoqingdf11.com',
					'acp.zhuoqingdf11.com',
					'dpc.zhuoqingdf11.com',
					'adp.zhuoqingdf11.com',
					'dyc.zhuoqingdf11.com',
					'dzc.zhuoqingdf11.com',
					'adp.zhuoqingdf11.com',
					'anp.zhuoqingdf11.com',
					'aop.zhuoqingdf11.com',
					'akp.zhuoqingdf11.com',
				],
				host: window.location.host.toLowerCase(),
				InvateShow: false,
				origin: window.location.origin,
				promotionList: [],
				orderImage: require(`@/assets/homepage/order.png`),
				price: 0,
				visible: false,
				visible2: false,
				visible3: false,
				visible4: false,
				modalTitle: '',
				modalText: '',
				modalcode: '',
				batchOrderData: {
					isShow: false,
				},
				batchOrderConfirmData: {
					isShow: false,
					price: 0,
					code: null,
					linksProp: null,
					checkInfo: [],
					successLinksProp: [],
					failLinksProp: [],
					title: '',
				},
				batchCommentsOrderConfirmData: {
					isShow: false,
				},
				modalShowTime: false,
				modalPrice: 0,
				ModalStep: 0,
				tkData: [],
				dataSource: [],
				userName: '',
				formDataCount: 0,
				pagination: {
					pageNo: 1,
					pageSize: 10,
					current: 1,
					showSizeChanger: true,
					pageSizeOptions: ['5', '10', '20', '50', '100'],
					showTotal: (total) =>
						`${this.$lang.GetLangByKey('Total')} ${total} ${this.$lang.GetLangByKey('Rows')}`,
					onShowSizeChange: (current, pageSize) => this.onSizeChange(current, pageSize),
					onChange: (page, pageSize) => this.onPageChange(page, pageSize),
					total: 0, //总条数
				},
				homeNoticInfo: {},
				orderSuccessVisible: false,
			};
		},
		setup() {
			const banner = ref(require(`@/assets/homepage/banner.png`));
			const tweened = ref(0);
			const contactMenu = reactive([
				{ id: 0, img: require(`@/assets/homepage/contact_1.png`), flex: 1 },
				{ id: 1, img: require(`@/assets/homepage/contact_2.png`), flex: 1 },
				{ id: 2, img: require(`@/assets/homepage/contact_3.png`), flex: 1 },
				{ id: 3, img: require(`@/assets/homepage/contact_4.png`), flex: 1 },
				{ id: 4, img: require(`@/assets/homepage/contact_5.png`), flex: 1.7 },
			]);
			const tiktok_qunkong = reactive(require(`@/assets/homepage/tiktok_qunkong.png`));
			const columns = reactive([
				{
					title: lang.GetLangByKey('BusinessName'),
					dataIndex: 'BusinessName',
					key: 'BusinessName',
				},
				{
					title: lang.GetLangByKey('Payment'),
					dataIndex: 'Payment',
					key: 'Payment',
				},
				{
					title: lang.GetLangByKey('OrderCount'),
					dataIndex: 'OrderNumber',
					key: 'OrderNumber',
				},
				{
					title: lang.GetLangByKey('Time'),
					dataIndex: 'Time',
					key: 'Time',
				},
			]);
			return {
				banner,
				contactMenu,
				tiktok_qunkong,
				columns,
				tweened,
			};
		},
		async mounted() {
			this.userName = this.$cookies.get('username');
			this.getConfig();
			this.getOrderList();
			await this.getUserAmount();
			this.getUserIsAgent();
			this.getNotice();
			// this.getPromotionList();
		},
		methods: {
			openQunkong() {
				window.open('http://qkweb.stplan.cn/');
			},
			//显示充值弹窗
			showRechargeModal() {
				this.visible4 = true;
			},
			async getUserIsAgent() {
				var res = await userApi.UserIsAgent();
				if (res.ret_code == 200) {
					this.InvateShow = res.ret_obj > 0;
				}
			},
			//获取公告
			async getNotice() {
				let res = await noticeApi.NoticeList({});
				if (res.ret_code === 200) {
					this.homeNoticInfo = res.ret_obj.find((t) => t.notice_type == 0);
				}
			},
			invateCopyClick() {
				let text = this.$util.getCookieUserKey('agent_domain_name');

				// 创建输入框元素
				let oInput = document.createElement('input');
				// 将想要复制的值
				oInput.value = text;
				// 页面底部追加输入框
				document.body.appendChild(oInput);
				// 选中输入框
				oInput.select();
				// 执行浏览器复制命令
				document.execCommand('Copy');
				// 弹出复制成功信息
				this.$message.success('复制成功');
				// 复制后移除输入框
				oInput.remove();
			},
			serviceClick(id) {
				if (id == 4) {
					this.visible3 = true;
				}
			},
			OrderDetail() {
				window.location.href = '#/Index/OrderRecord';
			},
			async getUserAmount() {
				var res = await userApi.UserAmout();
				if (res.ret_code == 200) {
					this.price = 0;
					this.price = res.ret_obj.user_amout;
					gsap.to(this, {
						duration: 0.5,
						tweened: this.$util.formatPrice(this.price),
					});
				}
			},
			async getPromotionList() {
				let param = {};
				param.type = 0;
				let res = await userApi.PromotionList(param);
				if (res.ret_code === 200) {
					this.promotionList = res.ret_obj;
				}
			},
			batchOrderDataChangeVisible(flag) {
				this.batchOrderData.isShow = flag;
			},
			batchOrderConfirmChangeVisible(flag) {
				this.batchOrderConfirmData.isShow = flag;
			},
			batchOrderCommentsConfirmChangeVisible(flag) {
				this.batchCommentsOrderConfirmData.isShow = flag;
			},
			//直播间检测
			async batchOrderSubmit(args) {
				let param = {};
				param.order_links = args.comment;
				param.config_code = args.code;
				let res = await orderApi.LiveDetection(param);
				if (res.ret_code == 200) {
					this.batchOrderConfirmData.title = this.modalTitle;
					this.batchOrderConfirmData.linksProp = param.order_links;
					this.batchOrderConfirmData.successLinksProp = res.ret_obj.successData;
					this.batchOrderConfirmData.failLinksProp = res.ret_obj.failData;
					this.batchOrderConfirmData.code = args.code;
					this.batchOrderConfirmData.price = args.price;
					this.batchOrderConfirmData.checkInfo = res.ret_obj.checkInfo;
					if (args.code != '21004') {
						this.batchOrderConfirmChangeVisible(true);
					} else {
						this.batchOrderCommentsConfirmChangeVisible(true);
					}
				}

				console.log(args);
			},
			async batchOrderConfirmSubmit(args) {
				console.log(args);
				console.log(h);
				if (args.order_robot_count == null || args.order_robot_count == 0) {
					this.$message.error('请输入订单数量');
					return;
				}
				console.log(args);
				let res = await orderApi.batchLiveOrderPlace(args);
				if (res.ret_code == 200) {
					//this.$message.success("下单成功");
					// 下单成功弹窗
					this.orderSuccesschangeVisible(true);
					this.batchOrderDataChangeVisible(false);
					this.batchOrderConfirmChangeVisible(false);
					this.batchOrderCommentsConfirmChangeVisible(false);
				} else {
					this.$message.destroy();
					var msgList = res.ret_msg.split('<br/>');
					var pList = [];
					msgList.forEach((item) => {
						pList.push(h('div', item));
					});
					if (res.ret_obj && res.ret_obj.length > 0) {
						this.$modal.confirm({
							getContainer: () => this.$refs.HomePage_warp,
							onOk: async () => {
								let text = res.ret_obj.join('\n');
								// 创建输入框元素
								let oInput = document.createElement('textarea');
								// 将想要复制的值
								oInput.value = text;
								// 页面底部追加输入框
								document.body.appendChild(oInput);
								// 选中输入框
								oInput.select();
								// 执行浏览器复制命令
								document.execCommand('Copy');
								// 弹出复制成功信息
								this.$message.success('复制成功');
								// 复制后移除输入框
								oInput.remove();
								return Promise.reject();
							},
							okText: '复制失败TK号或链接',
							cancelText: '知道了',
							title: h(
								'div',
								{
									style: {
										color: '#0086f5',
										'font-size': '18px',
										'font-weight': '600',
									},
								},
								'提示'
							),
							content: h(
								'div',
								{
									style: {
										color: '#0086f5',
										'font-size': '14px',
										'font-weight': '600',
									},
								},
								pList
							),
						});
					} else {
						this.$modal.error({
							getContainer: () => this.$refs.HomePage_warp,
							onOk: () => {},
							title: h(
								'div',
								{
									style: {
										color: '#0086f5',
										'font-size': '18px',
										'font-weight': '600',
									},
								},
								'提示'
							),
							content: h(
								'div',
								{
									style: {
										color: '#0086f5',
										'font-size': '14px',
										'font-weight': '600',
									},
								},
								pList
							),
						});
					}
				}
				this.getUserAmount();
				this.getOrderList();
			},
			orderSuccesschangeVisible(flag) {
				this.orderSuccessVisible = flag;
			},
			async getOrderInfo(id) {
				var res = await orderApi.OrderInfo({ config_code: id });
				if (res.ret_code == 200) {
					this.modalText = res.ret_obj.config_tips;
					this.modalText = this.modalText?.replaceAll(`\r\n`, '<br/>');

					this.modalTitle = res.ret_obj.config_name;
					this.modalPrice = res.ret_obj.config_price;
					this.modalcode = id + '';
				}
			},
			async showConfig(id) {
				this.getOrderInfo(id);
				if (id === 1002 || id === 1001) {
					this.modalShowTime = true;
				} else {
					this.modalShowTime = false;
				}
				//1002 与 1004用二号模态框
				switch (id) {
					case 1002:
					case 1004:
						this.visible2 = true;
						break;
					case 21006:
					case 21007:
					case 21008:
					case 21009:
					case 21005:
					case 21004:
						this.batchOrderDataChangeVisible(true);
						break;
					default:
						this.visible = true;
						if (id == 1003 || id == 1007) {
							this.ModalStep = 1; // 直播间点赞和视频播放量一万递增
						} else {
							this.ModalStep = 0; //其余0
						}
						break;
				}
			},
			async getConfig() {
				var res = await userApi.ConfigInfo();
				if (res.ret_code == 200) {
					res.ret_obj.forEach((item, index) => {
						console.log(index);
						let info = {
							config_unit: item.config_unit,
							id: item.config_code,
							title: item.config_name,
						};
						info.price = item.config_price;
						//对用户展示的部分数据做单个价格显示
						if (!(info.id == 1003 || info.id == 1007 || info.id == 21007)) {
							info.price /= item.config_unit;
						}
						info.price = this.$util.format1000Price(info.price);
						if (
							!(
								info.id == 1002 ||
								info.id == 1001 ||
								info.id == 1003 ||
								info.id == 1007 ||
								info.id == 21007
							)
						) {
							if (info.id == 1004 || info.id == 21004) {
								info.price += '/条';
							} else {
								info.price += '/个';
							}
						}
						info.price += this.$lang.GetLangByKey('PriceUnit');
						switch (item.config_code) {
							case 1001:
								info.icon = 'FireOutlined';
								break;
							case 1002:
								info.icon = 'MessageOutlined';
								break;
							case 1003:
								info.icon = 'LikeOutlined';
								break;
							case 21004:
							case 1004:
								info.icon = 'MessageOutlined';
								break;
							case 1005:
							case 21005:
								info.icon = 'LikeOutlined';
								break;
							case 1006:
							case 21006:
								info.icon = 'UserAddOutlined';
								break;
							case 1007:
							case 21007:
								info.icon = 'VideoCameraOutlined';
								break;
							case 1008:
							case 21008:
								info.icon = 'ShareAltOutlined';
								break;
							case 1009:
							case 21009:
								info.icon = 'StarOutlined';
								break;
						}
						if (info.title.indexOf('批量') > -1) {
							info.bgcolor = 'red';
						} else {
							info.bgcolor = 'blue';
						}
						this.tkData.push(info);
					});
				}
			},
			async getOrderList() {
				let param = {
					order_userid: this.$cookies.get('userid'),
					page: this.pagination.pageNo,
					size: this.pagination.pageSize,
				};
				this.dataSource = [];
				var res = await orderApi.OrderList(param);
				if (res.ret_code == 200) {
					this.pagination.total = res.ret_count;
					this.formDataCount = res.ret_count;
					res.ret_obj.forEach((item) => {
						this.dataSource.push({
							BusinessName: item.config_name,
							Payment:
								this.$util.formatPrice(item.order_amout) + this.$lang.GetLangByKey('PriceUnit'),
							OrderNumber: item.order_robot_count,
							Time: this.$dayjs(item.order_time).format('YYYY-MM-DD'),
						});
					});
				}
			},
			onSizeChange(page, size) {
				this.pagination.current = page;
				this.pagination.pageNo = page;
				this.pagination.pageSize = size;
				// this.getOrderList();
			},
			onPageChange(page, size) {
				this.pagination.current = page;
				this.pagination.pageNo = page;
				this.pagination.pageSize = size;
				this.getOrderList();
			},
			changeVisible(flag) {
				this.visible = flag;
			},
			changeVisible2(flag) {
				this.visible2 = flag;
			},
			changeVisible3(flag) {
				this.visible3 = flag;
			},
			changeVisible4(flag) {
				this.visible4 = flag;
			},
		},
	});
</script>

<style lang="scss" scoped>
	.wrap {
		display: flex;
		background-color: #fff;
		height: 100%;
	}

	.left {
		padding: 20px 30px;
		flex: 3;
		display: flex;
		flex-direction: column;
		overflow: auto;
	}

	.right {
		flex: 1.5;
		display: flex;
	}

	.left_banner {
		min-width: 500px;
		position: relative;
		min-height: 281px;

		.left_banner_img {
			width: 100%;
			height: 100%;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.left_banner_title {
			font-family: Cochin-Bold, Cochin;
			font-size: 25px;
			position: absolute;
			top: 50px;
			left: 50px;
			color: white;
		}

		.left_banner_title_sub {
			font-family: Cochin-Bold, Cochin;
			font-size: 12px;
			position: absolute;
			top: 90px;
			left: 50px;
			right: 50px;
			color: #bddaff;
		}

		.left_banner_invate {
			cursor: pointer;
			font-family: Cochin-Bold, Cochin;
			font-size: 14px;
			position: absolute;
			bottom: 50px;
			left: 50px;
			color: #0088f6ff;
			padding: 8px 20px 0px;
			height: 37px;
			background: #eaf7ff;
			box-shadow: inset 0px 1px 3px 0px #00a2f8;
			border-radius: 6px;
			opacity: 0.75;
			text-align: center;
		}
	}

	.left_contact_title {
		display: flex;

		.left_contact_title_block {
			width: 4px;
			height: 22px;
			background: linear-gradient(180deg, #00bbfb 0%, #0086f5 100%);
			border-radius: 4px;
		}

		.left_contact_title_content {
			margin-left: 8px;
			font-size: 16px;
			font-weight: 500;
			color: #0086f5;
		}
	}

	.left_contact_content {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.left_contact_content_item {
			padding: 10px 20px;

			.left_contact_content_item_img {
				width: 100%;
				box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.19), 0px 5px 5px 0px rgba(0, 0, 0, 0.07),
					0px -5px 5px 0px rgba(255, 255, 255, 0.8);
				border-radius: 6px;
			}
		}
	}

	.left_tk_title {
		display: flex;

		.left_tk_title_block {
			width: 4px;
			height: 22px;
			background: linear-gradient(180deg, #00bbfb 0%, #0086f5 100%);
			border-radius: 4px;
		}

		.left_tk_title_content {
			margin-left: 8px;
			font-size: 16px;
			font-weight: 500;
			color: #0086f5;
		}
	}

	.left_tk_content {
		display: grid;
		// justify-content: space-around;
		justify-content: space-evenly;
		grid-template-columns: repeat(auto-fill, 170px);
		grid-gap: 30px;
		margin-top: 20px;
		padding-bottom: 20px;

		.left_tk_content_item_blue {
			background: linear-gradient(39deg, #00a4fa 0%, #006bf2 100%);
			border-radius: 22px;
			border: 1px solid #26a6ee;
		}

		.left_tk_content_item_red {
			background: linear-gradient(150deg, #fa7d74 0%, #f94e39 100%);
			border-radius: 22px;
			border: 1px solid #ff8778;
		}

		.left_tk_content_item {
			cursor: pointer;
			height: 110px;
			width: 190px;
			padding: 12px;

			.left_tk_content_item_row1 {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;

				.left_tk_content_item_row1_icon {
					font-size: 30px;
					color: white;
				}

				.left_tk_content_item_row1_price {
					font-size: 25px;
					color: white;
				}
			}

			.left_tk_content_item_row2 {
				margin-top: 9px;
				font-size: 13px;
				font-weight: bold;
				color: #ffffff;
			}
		}
	}

	.right {
		background: #e5f3ff;
		border-radius: 30px 0px 0px 0px;

		.right_card {
			padding: 28px;
			color: white;

			.right_card_title {
				display: flex;
				justify-content: flex-start;
				font-size: 22px;
			}

			.right_card_price {
				display: flex;
				justify-content: space-between;
			}

			.right_card_price :nth-child(1) {
				font-size: 12px;
				align-self: flex-end;
			}

			.right_card_price :nth-child(2) {
				font-size: 42px;
				font-weight: 600;
			}
		}
	}

	.right_card_recharge {
		button {
			cursor: pointer;
			border: 0px;
			background-color: #ff8f8f;
			width: 100%;
			height: 50px;
			font-size: 18px;
			border-radius: 15px;
			margin-top: 15px;
		}
	}

	.right_data {
		margin-top: 15px;

		.right_data_title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;
		}

		.right_data_title :nth-child(1) {
			font-size: 20px;
			font-weight: 500;
			color: #0086f5;
			line-height: 30px;
		}

		.right_data_title :nth-child(2) {
			width: 36px;
			height: 36px;
			background: #0086f5;
			border-radius: 10px;
			text-align: center;
			line-height: 36px;
			font-size: 12px;
			font-weight: 600;
		}

		.right_data_form {
			margin-top: 10px;
			width: 100%;
			height: 430px;
			overflow: hidden;
			overflow-y: scroll;
			border-radius: 28px;
			border: 1px solid #0086f5;

			.right_data_form_status {
				color: #f55945;
				padding: 0px 20px;
				display: flex;
				justify-content: space-between;
				background-color: white;
				align-items: center;
				font-size: 18px;
				padding-top: 10px;
			}

			.right_data_form_status :nth-child(1) {
				border-radius: 10px;
			}

			.right_data_form_table {
				::v-deep {
					.ant-table-thead {
						.ant-table-cell {
							background-color: white;
							color: #f55945;
						}
					}
				}
			}
		}
	}
</style>
