<template>
	<div class="contentWarpModal" ref="warp">
		<a-modal
			v-model:visible="isShow"
			:title="title"
			:footer="null"
			:confirm-loading="confirmLoading"
			:getContainer="() => this.$refs.warp"
		>
			<div class="content_tips">
				<p class="contentText" v-html="text"></p>
			</div>

			<div style="width: 80%; margin: 0px auto">
				<a-input-group compact class="buttonGroup" style="">
					<span class="contentLabel"> 充值金额</span>
					<a-input-number
						v-model:value="entity.amount"
						style="width: calc(100% - 100px); border-radius: 15px; text-align: left"
						min="1"
						step="1"
						oninput="value=value.replace(/[^\d]/g, '')"
						placeholder="请输入充值金额"
					/>
				</a-input-group>
				<a-input-group compact class="buttonGroup" style="">
					<button style="border-radius: 8px" class="submitBtn" @click="onSubmit">确认充值</button>
				</a-input-group>
			</div>
		</a-modal>
		<a-modal v-model:visible="qrcodeModel.isShow" :title="qrcodeModel.title" :footer="null">
			<pay-vue :order_no="qrcodeModel.orderNo" :url="qrcodeModel.url" @qrcodeShow="qrcodeShow">
			</pay-vue>
		</a-modal>
	</div>
</template>
<script>
	import { h } from 'vue';
	import userApi from '@/http/apiUtil/userApi';
	import PayVue from '@/views/order/Pay.vue';
	export default {
		components: {
			PayVue,
		},
		props: {
			title: { type: String, default: '充值' },
			visible: { type: Boolean, default: false },
			text: {
				type: String,
				default: `1.为了您的利益安全，请确保充值金额无误后再点击确认提交<br/>
2.单次充值最低50起充<br/>
3.遇到任何问题，请先联系客服，我们将在第一时间解决<br/>
<span style="font-weight: 600;
    font-size: 1.2rem;
    color: red;">4.单次充值金额2000以上请联系在线客服</span>
`,
			},
		},
		data() {
			return {
				isShow: false,
				entity: {
					amount: null,
				},
				qrcodeModel: {
					isShow: false,
					title: '',
					url: null,
					orderNo: null,
				},
			};
		},
		mounted() {},
		methods: {
			qrcodeShow(flag) {
				this.qrcodeModel.isShow = flag;
			},
			async onSubmit() {
				if (this.entity.amount < 50) {
					this.$message.error('单次充值最低50起充');
					return;
				}
				var res = await userApi.GetPayData({
					pay_amout: this.entity.amount,
				});
				console.log(res);
				if (res.ret_code == 200) {
					this.qrcodeModel.url = res.ret_obj.url;
					this.qrcodeModel.orderNo = res.ret_obj.order_no;
					this.qrcodeModel.isShow = true;
					// const newWindow = window.open(
					//   `/#/pay?u=${encodeURIComponent(res.ret_obj.url)}&n=${res.ret_obj.order_no
					//   }`
					// );
					//支付提醒弹窗
					this.$modal.confirm({
						getContainer: () => this.$refs.warp,
						title: h(
							'div',
							{
								style: {
									color: '#0086f5',
									'font-size': '18px',
									'font-weight': '600',
								},
							},
							'提示'
						),
						content: h(
							'div',
							{
								style: {
									color: '#0086f5',
									'font-size': '14px',
									'font-weight': '600',
								},
							},
							[
								h('p', '支付完成前，请不要关闭此支付验证窗口。'),
								h('p', '支付完成后，请根据您支付的情况点击下面的按钮。'),
							]
						),
						cancelText: '取消支付',
						okText: '支付完成',
						onOk: async () => {
							try {
								this.$parent.getUserAmount();
							} catch (error) {
								console.log(error);
							}
							//获取订单状态
							var orderRes = await userApi.GetPayOrderState({
								param: { orderNo: res.ret_obj.order_no },
							});
							if (orderRes.ret_obj === 1) {
								//刷新余额
								// if (newWindow) {
								//   newWindow.close();
								// }
								this.isShow = false;
							} else {
								this.$message.error('未获取到支付结果，如确认已支付请联系客服');
								return Promise.reject(false);
							}
						},
						onCancel() {
							// if (newWindow) {
							//   newWindow.close();
							// }
						},
					});
				}
			},
			reset() {},
		},
		setup() {},
		watch: {
			visible: {
				handler(a) {
					this.isShow = a;
				},
			},
			isShow: {
				handler(a) {
					this.reset();
					this.$emit('changeVisible', a);
				},
			},
		},
	};
</script>
<style lang="scss" scoped>
	.buttonGroup {
		padding-top: 25px;
		text-align: center;
	}

	.contentText {
		width: 100%;
		font-size: 14px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #0086f5;
		line-height: 20px;
		padding: 11% 25px;
		text-align: left;
	}

	.submitBtn {
		cursor: pointer;
		width: 142px;
		height: 36px;
		background: #0086f5;
		border: 0px;
		color: white;
	}

	.contentWarpModal {
		.content_tips {
			max-width: 322px;
			background: #ffffff;
			border-radius: 15px;
			opacity: 0.8;
			text-align: center;
			margin: 0px auto;
		}

		.content_account {
			margin-right: auto;
			width: 235px;
			min-height: 58px;
			background: #ffffff;
			border-radius: 12px;
			opacity: 0.8;
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #0086f5;

			.content_account_label {
				text-align: left;
				padding-left: 1rem;
				line-height: 29px;
				font-weight: 500;
			}
		}

		:deep(.ant-btn) {
			border-radius: 8px;
		}

		:deep(.ant-input-number-handler-wrap) {
			display: none;
		}

		::v-deep {
			.ant-modal-content {
				//   min-width: 449px;
				//   min-height: 597px;
				background: #e5f3ff;
				box-shadow: 4px 13px 20px 0px rgba(146, 146, 146, 0.49);
				border-radius: 12px;
			}

			.ant-input-group.ant-input-group-compact {
				.ant-select:last-child {
					.ant-select-selector {
						border-radius: 15px;
					}
				}
			}

			.ant-modal-header {
				background: #e5f3ff;
				border-radius: 12px;
				text-align: center;
				border-bottom: 0px;
			}

			.ant-modal-title {
				font-size: 14px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #0086f5;
				line-height: 20px;
			}

			.contentLabel {
				line-height: 32px;
				padding-right: 0.5rem;
				font-size: 14px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #0086f5;
			}
		}
	}
</style>
