<template>
  <div class="Modal_contentWarp" ref="warp">
    <a-modal
      v-model:visible="isShow"
      :title="title"
      :footer="null"
      :getContainer="() => this.$refs.warp"
      :afterClose="reset"
    >
      <div class="contentWarp_message">
        <p class="contentText" v-html="text"></p>
      </div>

      <div id="button">
        <a-input-group compact class="buttonGroup">
          <span class="contentLabel"> 银行卡账号</span>
          <a-input
            v-model:value="entity.bankAccount"
            style="width: 70%; border-radius: 15px; text-align: left"
            placeholder="请填写银行卡账号"
          />
          <!-- <a-button
              @click="confirmBtn()"
              type="primary"
              style="border-radius: 15px; margin-left: -50px; z-index: 9999"
              >Confirm</a-button
            > -->
        </a-input-group>
        <a-input-group compact class="buttonGroup">
          <span class="contentLabel"> 银行名称</span>
          <a-input
            v-model:value="entity.bankName"
            style="width: 70%; border-radius: 15px; text-align: left"
            placeholder="请填写银行名称"
          />
        </a-input-group>
        <a-input-group compact class="buttonGroup">
          <span class="contentLabel"> 开户行名称</span>
          <a-input
            v-model:value="entity.bankRegionName"
            style="width: 70%; border-radius: 15px; text-align: left"
            placeholder="请填写开户行名称"
          />
        </a-input-group>
        <a-input-group compact class="buttonGroup">
          <span class="contentLabel"> 持卡人姓名</span>
          <a-input
            v-model:value="entity.name"
            style="width: 70%; border-radius: 15px; text-align: left"
            placeholder="请填写持卡人姓名"
          />
        </a-input-group>
        <a-input-group compact class="buttonGroup">
          <span class="contentLabel"> 登录密码</span>
          <a-input-password
            v-model:value="entity.withdrawPwd"
            style="width: 70%; border-radius: 15px; text-align: left"
            placeholder="请填写登录密码"
          />
          <p class="contentTip">为了您的利益安全，请填写您的登陆密码。</p>
        </a-input-group>
        <a-input-group compact class="buttonGroup">
          <button style="border-radius: 8px" class="submitBtn" @click="onClick">
            确认绑定
          </button>
        </a-input-group>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: {
    title: { type: String, default: "返佣提现" },
    visible: { type: Boolean, default: false },
    onsubmit: { type: Function, default: null },
    text: {
      type: String,
      default: `提现说明：请先绑定您的银行账户信息。再进行提现操作`,
    },
  },
  data() {
    return {
      isShow: false,
      entity: {
        withdrawPwd: "",
        bankAccount: null,
        bankName: null,
        bankRegionName: null,
        name: null,
      },
    };
  },
  mounted() {},
  methods: {
    reset() {},
    async onClick() {
      let param = this.entity;
      if (!param.bankAccount) {
        this.$message.error("请填写银行卡账号");
        return;
      }
      if (!param.bankName) {
        this.$message.error("请输入银行名称");
        return;
      }
      if (!param.bankRegionName) {
        this.$message.error("请输入开户行名称");
        return;
      }
      if (!param.name) {
        this.$message.error("请输入持卡人姓名");
        return;
      }
      if (!param.withdrawPwd) {
        this.$message.error("请输入登录密码");
        return;
      }
      this.onsubmit(param);
    },
  },
  setup() {},
  watch: {
    visible: {
      handler(a) {
        this.isShow = a;
      },
    },
    isShow: {
      handler(a) {
        this.$emit("changeVisible", a);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.buttonGroup {
  padding-top: 25px;
  text-align: center;
  width: 80%;
  margin: 0px auto;

  span {
    width: 30%;
  }
}

.contentText {
  width: 100%;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0086f5;
  line-height: 20px;
  padding: 11% 25px;
}

.contentTip {
  width: 100%;
  /* text-align: revert; */
  text-align: right;
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0086f5;
  line-height: 14px;
}

.submitBtn {
  cursor: pointer;
  width: 142px;
  height: 36px;
  background: #0086f5;
  border: 0px;
  color: white;
}

.Modal_contentWarp {
  .contentWarp_message {
    width: 322px;
    height: 115px;
    background: #ffffff;
    border-radius: 15px;
    opacity: 0.8;
    text-align: center;
    margin: 0px auto;
  }

  ::v-deep {
    .ant-modal-content {
      //   min-width: 449px;
      //   min-height: 597px;
      background: #e5f3ff;
      box-shadow: 4px 13px 20px 0px rgba(146, 146, 146, 0.49);
      border-radius: 12px;
    }

    .ant-input-group.ant-input-group-compact {
      .ant-select:last-child {
        .ant-select-selector {
          border-radius: 15px;
        }
      }
    }

    .ant-modal-header {
      background: #e5f3ff;
      border-radius: 12px;
      text-align: center;
      border-bottom: 0px;
    }

    .ant-modal-title {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0086f5;
      line-height: 20px;
    }

    .contentLabel {
      line-height: 32px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0086f5;
    }
  }
}
</style>
