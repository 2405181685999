<template>
	<div class="batchOrderConfirmModal_warp" ref="warp">
		<a-modal
			v-model:visible="isShow"
			:title="title"
			:footer="null"
			:confirm-loading="confirmLoading"
			:getContainer="() => this.$refs.warp"
			:afterClose="reset"
		>
			<div class="batchOrderConfirmModal_warp_header">
				<p class="batchOrderConfirmModal_warp_header_hint">
					检测成功数{{ successLinks.length }}条，失败数{{ failLinks.length }}条
				</p>
			</div>

			<div class="batchOrderConfirmModal_warp_successbox">
				<div class="title">检测成功：{{ successLinks.length }}</div>
				<div class="url" v-for="item in successLinks" v-bind:key="item">
					{{ item }}
				</div>
			</div>
			<div class="batchOrderConfirmModal_warp_failbox">
				<div class="header">
					<div class="title">检测失败：{{ failLinks.length }}</div>
					<div class="copy"><a @click="copy">一键复制</a></div>
				</div>
				<div class="url" v-for="item in failLinks" v-bind:key="item">
					{{ item }}
				</div>
			</div>
			<div class="batchOrderConfirmModal_warp-body">
				<a-input-group compact class="buttonGroup">
					<a-textarea
						:placeholder="`请输入评论内容，换行代表一条数据`"
						v-model:value="entity.text"
						:rows="6"
						:style="{
							width: '100%',

							'margin-left': '0px',
							color: '0086f5ff',
							'background-color': 'white',
							'border-radius': '15px',
							'text-align': 'left',
						}"
						@change="blur"
					/>
					<!-- <a-input
            v-model:value="entity.num"
            min="0"
            :onStep="onStep"
            :step="config_step"
            oninput="value=value.replace(/[^\d]/g, '')"
            :placeholder="`请输入${config_step}的倍数`"
            @blur="blur"
            :style="{
              width: 'calc(100% - 5rem)',
              'margin-left': '0px',
              color: '0086f5ff',
              'background-color': 'white',
              'border-radius': '15px',
            }"
          /> -->
					<span
						v-if="config_info?.config_unit > 1 && isFull"
						style="line-height: 32px; font-weight: 600"
						>&nbsp;*&nbsp;{{ config_info?.config_unit }}</span
					>
				</a-input-group>
				<div style="margin: 0px auto; text-align: right">
					<div>
						每个账户
						{{ getCommetnsCount().length || 0 }}
						{{ title }}
					</div>
				</div>
				<a-input-group
					compact
					class="buttonGroup"
					v-if="code == 21006 && false"
					style="display: flex; align-items: center; justify-content: center"
				>
					<a-button type="primary" style="border-radius: 15px" @click="checkTiered()">
						阶梯计价检测</a-button
					>
					<a @click="showTieredDetail" style="margin-left: 0.5rem; text-decoration: underline"
						>查看详情</a
					>
				</a-input-group>
				<a-input-group compact class="buttonGroup">
					<a-input
						disabled="disabled"
						v-model:value="entity.disc"
						style="
							width: calc(100% - 5rem);
							border-radius: 15px;
							background-color: white;
							color: #0086f5ff;
						"
						placeholder=""
					/>
					<!-- <a-input v-model:value="entity.test"> </a-input> -->
					<a-button
						type="primary"
						style="border-radius: 15px; margin-left: -50px; z-index: 9999"
						@click="order()"
						>下单</a-button
					>
				</a-input-group>
			</div>
		</a-modal>
	</div>
</template>
<script>
	import orderApi from '@/http/apiUtil/orderApi';
	import { ref, h } from 'vue';
	export default {
		props: {
			title: { type: String, default: '' },
			visible: { type: Boolean, default: false },
			price: { type: Number, default: null },
			code: { type: String, default: null },
			successLinksProp: { type: Array, default: ref([]) },
			failLinksProp: { type: Array, default: ref([]) },
			onSubmit: { type: Function, default: null },
			checkInfo: { type: Array, default: ref([]) },
		},
		data() {
			return {
				isShow: false,
				confirmLoading: false,
				isFull: false,
				compact: false,
				config_step: 100,
				config_info: null,
				successLinks: [],
				failLinks: [],
				entity: {
					links: '',
					disc: '金额：0￥',
					num: null,
					text: null,
				},
				isCheckTiered: false,
				tiered_total_amount: 0,
				tiered_detail: [],
			};
		},
		mounted() {},
		methods: {
			getCommetnsCount() {
				if (!this.entity.text) {
					return [];
				}
				let comments = this.entity.text.split('\n').filter((t) => t);
				return comments;
			},
			blur() {
				this.isCheckTiered = false;
				let comments = this.getCommetnsCount();
				let sumPrice = this.successLinks.length * comments.length * this.price;
				sumPrice = this.$util.format1000Price(sumPrice);
				if (!this.isFull) {
					sumPrice = (sumPrice / this.config_info.config_unit).toFixed(2);
				}
				sumPrice = Number(sumPrice) + Number(this.tiered_total_amount);
				this.entity.disc = '金额：' + sumPrice + '￥';
			},
			reset() {
				this.entity.num = null;
				this.entity.disc = '金额：0￥';
			},
			/**
			 *阶梯计价检测
			 */
			async checkTiered() {
				let param = {};
				param.config_code = this.code;
				param.robot_total_count = this.entity.num;
				param.is_batch = 1;
				param.check_info = this.checkInfo;
				param.links = this.successLinks.join(',');
				var res = await orderApi.GetTieredPrice(param);
				if (res.ret_code == 200) {
					//   this.$message.success("检测成功");
					this.tiered_total_amount = this.$util.format1000Price(res.ret_obj.total_amount);
					this.tiered_detail = res.ret_obj.tiered_detail;
					this.blur();
					this.isCheckTiered = true;
					this.tiered_total_amount = 0;
				}
				this.showTieredDetail();
			},
			/**
			 *阶梯计价详情展示
			 */
			showTieredDetail() {
				if (this.tiered_detail.length == 0) {
					//下单
					this.orderBtn();
					return;
				}
				let list = [];
				let total_amount = 0;
				this.tiered_detail.forEach((item) => {
					total_amount += item.amount;
					list.push(
						h('p', [
							h('span', { style: { color: 'red' } }, `【${item.vcLink}】`),
							h('span', `总计订单数`),
							h('span', { style: { color: 'red' } }, ` ${item.total_count} `),
							h('span', `，有`),
							h('span', { style: { color: 'red' } }, ` ${item.interval_count} `),
							h('span', `订单在`),
							h('span', { style: { color: 'red' } }, ` ${this.title} `),
							h('span', `阶梯计价的`),
							h('span', { style: { color: 'red' } }, ` ${item.tiered_begin_number} `),
							h('span', `-`),
							h('span', { style: { color: 'red' } }, ` ${item.tiered_end_number} `),
							h('span', `区间，方式为：原有单价上 +`),
							h(
								'span',
								{ style: { color: 'red' } },
								` ${this.$util.format1000Price(item.tiered_add_price)} `
							),
							h('span', `(个)`),
						])
					);

					list.push(
						h('p', [h('span', `此区间计价： ${this.$util.format1000Price(item.amount)} ￥`)])
					);
				});
				list.push(h('p', [h('span', `总计价： ${this.$util.format1000Price(total_amount)} ￥`)]));
				this.$modal.confirm({
					getContainer: () => this.$refs.warp,
					cancelText: '取消下单',
					okText: '确认下单',
					onOk: () => {
						//下单
						this.orderBtn();
					},
					closable: true,
					title: h(
						'div',
						{
							style: {
								color: '#0086f5',
								'font-size': '18px',
								'font-weight': '600',
							},
						},
						'提示'
					),
					content: h(
						'div',
						{
							style: {
								color: '#0086f5',
								'font-size': '14px',
								'font-weight': '600',
								'max-height': '60vh',
								'overflow-y': 'auto',
							},
						},
						list
					),
				});
			},
			onStep(value, type) {
				var config_step = this.config_step;
				console.log(this.entity.num);
				let num = this.entity.num % config_step;
				if (type.type == 'up') {
					this.entity.num -= num;
				} else {
					if (this.entity.num != 0) {
						this.entity.num += config_step - (num === 0 ? config_step : num);
					}
				}
				console.log(`${value},${type}`);
			},
			//下单
			async order() {
				if (this.code == '1006' || this.code == '21006') {
					this.checkTiered();
				} else {
					this.orderBtn();
				}
			},
			async orderBtn() {
				//校验
				let s = this.getCommetnsCount();
				if (s.length == 0) {
					this.$message.error('请输入评论内容');
					return;
				}
				// if (!this.isCheckTiered && this.code == 21006) {
				//   this.$message.error("请先点击【阶梯计价检测】按钮");
				//   return;
				// }
				let param = {};
				param.order_links = this.successLinks.join(',');
				param.config_code = this.code;
				let robotCount = s.length;
				param.order_robot_count = robotCount;
				param.succ_comment_list = s;
				if (!this.isFull) {
					param.order_robot_count /= this.config_info.config_unit;
				}

				param.checkInfo = this.checkInfo;

				let that = this;
				if (that.config_info.config_unit > 1 && this.isFull) {
					that.$modal.confirm({
						getContainer: () => this.$refs.warp,
						onOk: () => {
							this.onSubmit(param);
						},
						title: h(
							'div',
							{
								style: {
									color: '#0086f5',
									'font-size': '18px',
									'font-weight': '600',
								},
							},
							'提示'
						),
						content: h(
							'div',
							{
								style: {
									color: '#0086f5',
									'font-size': '14px',
									'font-weight': '600',
								},
							},
							[
								h(
									'p',
									`每个账户 [ ${that.entity.num * that.config_info?.config_unit || 0} ] 数量的 ${
										that.title
									}`
								),
								h('p', { style: 'color:red' }, '确认下单吗？'),
							]
						),
					});
				} else {
					this.onSubmit(param);
				}
			},
			copy() {
				if (!this.failLinksProp || this.failLinksProp.length === 0) {
					this.$message.error('没有数据可以复制');
					return;
				}
				let text = this.failLinksProp.join('\n');
				// 创建输入框元素
				let oInput = document.createElement('textarea');
				// 将想要复制的值
				oInput.value = text;
				// 页面底部追加输入框
				document.body.appendChild(oInput);
				// 选中输入框
				oInput.select();
				// 执行浏览器复制命令
				document.execCommand('Copy');
				// 弹出复制成功信息
				this.$message.success('复制成功');
				// 复制后移除输入框
				oInput.remove();
			},
		},

		setup() {},
		watch: {
			visible: {
				immediate: true,
				handler(a) {
					this.isShow = a;
				},
			},
			isShow: {
				handler(a) {
					this.$emit('changeVisible', a);
				},
			},
			successLinksProp: {
				handler(value) {
					this.successLinks = value;
				},
			},
			failLinksProp: {
				handler(value) {
					this.failLinks = value;
				},
			},
			code: {
				async handler(value) {
					console.log('当前批量窗口code：' + value);
					var res = await orderApi.OrderInfo({ config_code: value });
					this.config_info = res.ret_obj;
					this.config_step = res.ret_obj.config_step;
					this.isFull =
						this.config_info.config_code == 21007 || this.config_info.config_code == 21003;
					console.log('加载数据..' + res);
				},
			},
		},
	};
</script>
<style lang="scss" scoped>
	.buttonGroup {
		padding-top: 25px;
		text-align: center;
	}

	.batchOrderConfirmModal_warp {
		.batchOrderConfirmModal_warp-body {
			width: 70%;
			margin: 0px auto;
		}

		:deep(.ant-input-number-handler) {
			border-left: none;
		}

		:deep(.ant-input-number-handler-up) {
			background-image: url(/src/assets/icon/icon-add.png);
			background-size: cover;
			background-color: white;
			width: 48%;
			height: 100% !important;
		}

		:deep(.ant-input-number-handler-down) {
			background-image: url(/src/assets/icon/icon-minus.png);
			background-size: cover;
			background-color: white;
			width: 48%;
			margin-left: 2%;
			height: 100% !important;
			border-top: none;
		}

		:deep(.ant-input-number-handler-wrap) {
			display: flex;
			position: absolute;
			right: -4.6rem;
			width: 4.4rem !important;
			height: 1.4rem;
			top: 0.2rem;
		}

		:deep(.anticon-up > svg) {
			display: none;
		}

		:deep(.anticon-down > svg) {
			display: none;
		}

		::v-deep {
			.ant-modal-content {
				//   min-width: 449px;
				//   min-height: 597px;
				background: #e5f3ff;
				box-shadow: 4px 13px 20px 0px rgba(146, 146, 146, 0.49);
				border-radius: 12px;
			}

			.batchOrderConfirmModal_warp_header {
				padding-left: 10%;
				padding-right: 10%;

				.batchOrderConfirmModal_warp_header_hint {
					font-size: 12px;
					font-family: PingFangSC-Semibold, PingFang SC;
					text-align: left;
					background: #cde8ff;
					box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07),
						inset 0px 1px 4px 0px rgba(0, 80, 157, 0.77);
					border-radius: 8px;
					border: 1px solid #0075f3;
					text-align: center;
					line-height: 38px;
					font-family: PingFangSC-Semibold, PingFang SC;
					font-weight: 600;
					color: #0086f5;
				}
			}

			.batchOrderConfirmModal_warp_databody {
				.batchOrderConfirmModal_warp_databody_tips {
					padding-top: 0px;
					padding-left: 50%;
					font-size: 12px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #0091ff;

					.exceed {
						color: red;
					}
				}
			}

			.ant-input-group.ant-input-group-compact {
				.ant-select:last-child {
					.ant-select-selector {
						border-radius: 15px;
					}
				}
			}

			.ant-modal-header {
				background: #e5f3ff;
				border-radius: 12px;
				text-align: center;
				border-bottom: 0px;
			}

			.ant-modal-title {
				font-size: 14px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #0086f5;
				line-height: 20px;
			}
		}

		.batchOrderConfirmModal_warp_successbox {
			width: 322px;
			height: 219px;
			background: #ffffff;
			border-radius: 15px;
			opacity: 0.8;
			margin: 0px auto;
			overflow-y: auto;

			.title {
				font-size: 14px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #0091ff;
				line-height: 30px;
				padding-left: 1rem;
			}

			.url {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #0091ff;
				line-height: 20px;
				padding-left: 1rem;
			}
		}

		.batchOrderConfirmModal_warp_failbox {
			width: 322px;
			height: 127px;
			background: #ebfffa;
			border-radius: 15px;
			opacity: 0.8;
			margin: 1rem auto 0px;
			overflow-y: auto;

			.header {
				display: flex;
				position: relative;

				.title {
					font-size: 14px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #44d7b6;
					line-height: 30px;
					padding-left: 1rem;
				}

				.copy {
					line-height: 30px;
					position: absolute;
					right: 1rem;
				}
			}

			.url {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #44d7b6;
				line-height: 20px;
				padding-left: 1rem;
			}
		}
	}

	@media only screen and (max-width: 720px) {
		.batchOrderConfirmModal_warp {
			.batchOrderConfirmModal_warp-body {
				width: 90%;
				margin: 0px auto;
			}
		}
	}
</style>
