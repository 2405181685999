<template>
  <div class="contentWarpModal" ref="warp">
    <a-modal
      v-model:visible="isShow"
      :title="title"
      :footer="null"
      :getContainer="() => this.$refs.warp"
      :afterClose="reset"
    >
      <div class="content_tips">
        <p class="contentText" v-html="text"></p>
      </div>

      <div>
        <a-input-group compact class="buttonGroup" style="">
          <div style="display: flex; align-items: center">
            <div style="margin-left: auto; margin-right: 1rem">
              <a-image :src="zhifubaoLogo" :preview="false"></a-image>
            </div>
            <div class="content_account">
              <div class="content_account_label">银行卡账号：{{ bank_no }}</div>
              <div class="content_account_label">银行名称：{{ bank_name }}</div>
              <div class="content_account_label">
                开户行名称：{{ bank_branch }}
              </div>
              <div class="content_account_label">
                持卡人姓名：{{ bank_username }}
              </div>
            </div>
          </div>
        </a-input-group>
        <a-input-group compact class="buttonGroup" style="">
          <span class="contentLabel" style="width: 30%"> 可提现金额：</span>
          <span
            class="contentLabel"
            style="
              background: white;
              border-radius: 16px;
              text-align: center;
              width: 35%;
            "
            >{{ TotalAmount }}￥</span
          >
          <!-- <a-input-number
            v-model:value="entity.Amount"
            style="
              width: calc(100% - 200px);
              border-radius: 15px;
              text-align: left;
            "
            oninput="value=value.replace(/[^\d]/g, '')"
            placeholder="请输入提现金额"
            min="0"
            disabled="disabled"
          /> -->

          <p class="contentTip">
            15天内只能进行一次提现，收益仅支持提现三天前的收益
          </p>
        </a-input-group>
        <a-input-group compact class="buttonGroup" style="">
          <button style="border-radius: 8px" class="submitBtn" @click="onClick">
            确认提现
          </button>
        </a-input-group>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: {
    title: { type: String, default: "提现确认" },
    visible: { type: Boolean, default: false },
    bank_no: { type: String, default: "" },
    bank_name: { type: String, default: "" },
    bank_branch: { type: String, default: "" },
    bank_username: { type: String, default: "" },
    TotalAmount: { type: Number, default: 0 },
    onSubmit: { type: Function, default: null },
    text: {
      type: String,
      default: `说明：为了您的利益安全，请确保金额和账户无误后点击确认提交`,
    },
  },
  data() {
    return {
      isShow: false,
      zhifubaoLogo: require("@/assets/withdraw/zhifubaoLogo.png"),
      entity: {
        Amount: 0,
      },
    };
  },
  mounted() {},
  methods: {
    reset() {},
    onClick() {
      this.onSubmit(this.entity);
    },
  },
  setup() {},
  watch: {
    visible: {
      handler(a) {
        this.isShow = a;
      },
    },
    isShow: {
      handler(a) {
        this.$emit("changeVisible", a);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.contentTip {
  margin-top: 5px;
  width: 100%;
  /* text-align: revert; */
  text-align: right;
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0086f5;
  line-height: 14px;
}

.buttonGroup {
  padding-top: 25px;
  text-align: center;
}

.buttonGroup span {
  width: 20%;
}

.contentText {
  width: 100%;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0086f5;
  line-height: 20px;
  padding: 11% 25px;
}

.submitBtn {
  cursor: pointer;
  width: 142px;
  height: 36px;
  background: #0086f5;
  border: 0px;
  color: white;
}

.contentWarpModal {
  .content_tips {
    width: 400px;
    height: 115px;
    background: #ffffff;
    border-radius: 15px;
    opacity: 0.8;
    text-align: center;
    margin: 0px auto;
  }

  .content_account {
    margin-right: auto;
    width: 300px;
    min-height: 58px;
    background: #ffffff;
    border-radius: 12px;
    opacity: 0.8;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #0086f5;

    .content_account_label {
      text-align: left;
      padding-left: 1rem;
      line-height: 29px;
      font-weight: 500;
    }
  }

  ::v-deep {
    .ant-modal-content {
      //   min-width: 449px;
      //   min-height: 597px;
      background: #e5f3ff;
      box-shadow: 4px 13px 20px 0px rgba(146, 146, 146, 0.49);
      border-radius: 12px;
    }

    .ant-input-group.ant-input-group-compact {
      .ant-select:last-child {
        .ant-select-selector {
          border-radius: 15px;
        }
      }
    }

    .ant-modal-header {
      background: #e5f3ff;
      border-radius: 12px;
      text-align: center;
      border-bottom: 0px;
    }

    .ant-modal-title {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0086f5;
      line-height: 20px;
    }

    .contentLabel {
      line-height: 32px;
      padding-right: 0.5rem;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0086f5;
    }
  }
}
</style>
