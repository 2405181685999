<template>
  <div>
    <a-layout>
      <a-layout-sider class="sider" width="280px" ref="leftSider">
        <div class="sider_wrap">
          <div class="sider_wrap_block"></div>
          <div class="sider_wrap_logo">
            <div class="sider_wrap_logo_div">
              <a-image
                :src="image.logo"
                :preview="false"
                class="sider_wrap_logo_div_text"
                :width="100"
              />
            </div>
          </div>
          <div class="menu">
            <div
              class="menu_item"
              v-for="(menu, index) in menus"
              :key="index"
              v-show="menu.show == true"
              :style="{
                margin:
                  menus.filter((t) => t.show == true).length > 5
                    ? '20px 0px'
                    : '30px 0px',
              }"
            >
              <div
                @click="ChangeMenu(menu.id)"
                class="menu_item_title"
                :class="
                  menu.isSelected
                    ? `menu_item_title_checked`
                    : `menu_item_title_unchecked`
                "
                :style="
                  menu.id == 3
                    ? 'color:red;background-color: white; box-shadow: 0px 2px 4px 0px gray;'
                    : ''
                "
              >
                <Icon :v-if="menu.icon != null" :icon="menu.icon"></Icon>
                &nbsp;{{ menu.title }}
              </div>
            </div>
            <!-- <div class="menu_item">
              <div class="menu_item_title"></div>
            </div> -->
          </div>
        </div>
      </a-layout-sider>
      <a-layout>
        <a-layout-header class="header">
          <div class="header_left">
            <div class="header_search">
              <!-- <a-input
              @pressEnter="Search"
              v-model:value="searchModel"
              placeholder="Input your search word"
            >
              <template #prefix>
                <search-outlined class="header_search_icon" />
              </template>
            </a-input> -->
              <div
                style="
                  height: 50px;
                  min-width: 60px;
                  display: flex;
                  align-items: center;
                  margin: 0px 0.5rem 0px 1rem;
                "
              >
                <i class="icon icon-trumpet"></i>
                <span> 公告 </span>
              </div>
              <div class="scroll-div">
                <div>
                  {{ headNoticeInfo.notice_remark }}
                </div>
              </div>
            </div>
          </div>
          <div class="header_right">
            <div class="header_notice">
              <a-badge
                :dot="true"
                class="header_notice_icon"
                :showZero="false"
                :count="0"
              >
                <bell-outlined style="font-size: 26px; color: white" />
              </a-badge>
            </div>
            <div class="header_notice" @click="showmodifyPwdModal(true)">
              <key-outlined style="font-size: 26px; color: white" />
            </div>
            <div class="header_avater">
              <a-image
                src="https://www.antdv.com/#error"
                :fallback="avaterError"
                class="header_avater_img"
              />
              <span>{{ userName }}</span>
            </div>
          </div>
        </a-layout-header>
        <a-layout-content class="content">
          <div class="content_body" style="">
            <RouterView></RouterView>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
    <modify-pwd-modal
      v-model:visible="modifyPwdModalData.visible"
      @changeVisible="showmodifyPwdModal"
      title="修改密码"
      :on-submit="modifyPwd"
    >
    </modify-pwd-modal>
    <div @click="showOnlineService" class="online-service">
      <icon
        icon="CustomerServiceOutlined"
        style="font-size: 1.8rem; margin-right: 0.5rem"
      ></icon>
      联系在线客服
    </div>
    <service
      v-model:visible="onlineServiceModal.isShow"
      @changeVisible="showOnlineService"
    ></service>
  </div>
</template>

<script>
import userApi from "@/http/apiUtil/userApi";
import noticeApi from "@/http/apiUtil/noticeApi";
import lang from "@/lang/index";
import { defineComponent, reactive, ref } from "vue";
import cookies from "js-cookie";
import { Icon } from "@/components/util/iconUtil.js";
import { BellOutlined, KeyOutlined } from "@ant-design/icons-vue";
import modifyPwdModal from "@/components/user/modifyPwdModal.vue";
import service from "@/components/service/onlineServiceModel.vue";
import "@/css/icon.scss";
export default defineComponent({
  //SearchOutlined,
  components: { BellOutlined, Icon, KeyOutlined, modifyPwdModal, service },
  setup() {
    const menus = reactive([
      {
        id: 0,
        title: lang.GetLangByKey("HomePage"),
        isSelected: true,
        url: "/Index/HomePage",
        icon: null,
        show: true,
      },
      {
        id: 8,
        title: "购买账号",
        isSelected: false,
        url: "/Index/shoppingIndex",
        icon: null,
        show: true,
      },
      {
        id: 2,
        title: lang.GetLangByKey("OrderRecord"),
        isSelected: false,
        url: "/Index/OrderRecord",
        icon: null,
        show: true,
      },
      {
        id: 1,
        title: lang.GetLangByKey("Transactions"),
        isSelected: false,
        url: "/Index/Transactions",
        icon: null,
        show: true,
      },
      {
        id: 5,
        title: "代理管理",
        isSelected: false,
        url: "/Index/earnings",
        icon: null,
        show: false,
      },
      {
        id: 4,
        title: "用户列表",
        isSelected: false,
        url: "/Index/invateIndex",
        icon: null,
        show: false,
      },
      {
        id: 3,
        title: lang.GetLangByKey("Quit"),
        isSelected: false,
        url: "/login",
        icon: "LogoutOutlined",
        show: true,
      },
    ]);
    const ChangeMenu = function (id) {
      menus.forEach((item) => {
        if (item.id === id) {
          item.isSelected = true;
        } else {
          item.isSelected = false;
        }
      });
      menus.forEach((item) => {
        if (item.id === id) {
          this.$router.push({ path: item.url });
        }
      });
    };
    const userName = ref(cookies.get("username"));
    const searchModel = ref(``);
    const Search = function () {
      console.log(searchModel.value);
    };
    const avaterError = ref(require(`@/assets/indexLayer/avater_err.png`));
    return { menus, ChangeMenu, searchModel, Search, avaterError, userName };
  },
  data() {
    return {
      onlineServiceModal: {
        isShow: false,
      },
      image: {
        logo: require(`@/assets/account/logo.png`),
      },
      modifyPwdModalData: {
        visible: false,
      },
      headNoticeInfo: {},
    };
  },
  methods: {
    //展示在线客服弹窗
    showOnlineService(value) {
      this.onlineServiceModal.isShow = value;
    },
    resetBtn() {
      this.menus.forEach((item) => {
        let list = window.location.hash.split("/");
        if (list.length >= 2) {
          if ("#" + item.url === `${list[0]}/${list[1]}/${list[2]}`) {
            item.isSelected = true;
          } else {
            item.isSelected = false;
          }
        }
      });
    },
    async getUserIsAgent() {
      var res = await userApi.UserIsAgent();
      if (res.ret_code == 200) {
        this.menus.find((t) => t.id == 4).show = res.ret_obj > 0;
        this.menus.find((t) => t.id == 5).show = res.ret_obj > 0;
      }
    },
    showmodifyPwdModal(flag) {
      this.modifyPwdModalData.visible = flag;
    },
    //获取公告
    async getNotice() {
      let res = await noticeApi.NoticeList({});
      if (res.ret_code === 200) {
        this.headNoticeInfo = res.ret_obj.find((t) => t.notice_type == 0);
      }
    },
    //修改密码
    async modifyPwd(param) {
      console.log(param);
      let res = await userApi.ModifPwd(param);
      if (res.ret_code == 200) {
        this.$message.success("修改成功");
        this.showmodifyPwdModal(false);
      }
    },
  },
  mounted() {
    setInterval(() => {
      this.resetBtn();
    }, 1000);
    this.getUserIsAgent();
    this.getNotice();
  },
});
</script>

<style lang="scss" scoped>
.sider {
  background: #daf1ff;
  padding: 20px;

  .sider_wrap {
    .sider_wrap_block {
      height: 94px;
      border-bottom: 1px solid rgba(52, 154, 255, 0.41);
    }

    .sider_wrap_logo {
      margin-top: 16px;
      display: flex;
      justify-content: center;

      .sider_wrap_logo_div {
        width: 76px;
        height: 76px;
        border-radius: 28px 28px 28px 29px;
        display: flex;
        justify-content: center;
        align-items: center;

        .sider_wrap_logo_div_text {
          width: 76px;
          height: 76px;
          font-weight: 600;
          color: #ffffff;
        }
      }
    }
  }

  .menu {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .menu_item {
      margin: 30px 0px;

      :hover {
        cursor: pointer;
      }

      .menu_item_title {
        width: 180px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .menu_item_title_checked {
        color: #ffffff;
        background: #0086f5;
        box-shadow: 0px 2px 4px 0px #0086f5, 0px 2px 4px 0px #0086f5;
        border-radius: 11px;
      }

      .menu_item_title_unchecked {
        background: #96d0ff;
        box-shadow: 0px 2px 4px 0px #0086f5;
        border-radius: 11px;
        color: #0086f5;
      }
    }
  }
}

.header {
  background: #f8f9fa;
  height: 78px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.header_left {
  //flex: 1;
  width: calc(100% - 380px);

  .header_search {
    box-shadow: inset 0px 0px 3px 0px rgba(0, 150, 246, 0.54);
    border-radius: 13px;
    border: 1px solid #dcdcdc;
    color: red;
    background: #ffffff;
    .header_search_icon {
      color: #0086f5;
      font-size: 18px;
    }
    .scroll-div {
      overflow: hidden; /* 让超出部分隐藏 */
      height: 50px; /* 设置高度 */

      display: flex;
      align-items: center;

      min-width: 100px;
    }
    .scroll-div div {
      animation: scroll 30s linear infinite; /* 指定动画属性 */
      white-space: nowrap; /* 让文字不换行 */
      margin: 0; /* 清除默认外边距 */
      display: flex;
      align-items: center;
      span {
        margin-right: 1rem;
      }
    }
    @keyframes scroll {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(-100%);
      }
    }

    ::v-deep {
      .ant-input {
        background: #f4f4f4;
      }
    }

    > span {
      border-radius: 20px;
      background: #f4f4f4;
      min-width: 300px;
      max-width: 450px;
      height: 40px;
    }

    display: flex;
  }
}

.header_right {
  min-width: 380px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .header_notice {
    background: #9cd2ff;
    padding: 8px;
    border-radius: 11px;
    display: flex;
    align-items: center;
    margin-right: 1rem;
    cursor: pointer;
  }

  .header_avater {
    margin-left: 50px;
    display: flex;

    ::v-deep {
      .header_avater_img {
        width: 46px;
        height: 46px;
        border-radius: 23px;
      }
    }

    span {
      margin-left: 25px;
      font-size: 18px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

.content {
  height: calc(100vh - 78px);

  .content_body {
    height: 100%;
    min-width: 1000px;
    overflow-y: auto;
    background-color: white;
  }
}
.online-service {
  cursor: pointer;
  background: linear-gradient(270deg, #ff91b4, #ff3b63);
  border-radius: 0px 8px 8px 0px;
  position: fixed;
  left: 0;
  top: 1%;
  min-width: 150px;
  padding: 1rem;
  text-align: left;
  color: #fff;
  font-size: 1.2rem;
  display: flex;
}
</style>
