import loadingComponent from "@/components/loading/loading.vue";
import { createApp } from "vue";
let instance;
let flag = [];
const show = function(text) {
    flag.push(true);
    if (instance) {
        instance.text = text;
        instance.show();
        return;
    }
    instance = createApp(loadingComponent).mount(document.createElement("div"));
    instance.text = text;
    if (text) {
        instance.$el.innerHTML = text;
    }
    document.body.appendChild(instance.$el);
};
const hide = function() {
    if (flag.length > 0) {
        flag.splice(0, 1);
    }
    if (instance && flag.length == 0) {
        instance.hide();
    }
};
export default {
    show,
    hide,
};