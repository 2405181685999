<template>
    <div class="modifyCommentModal-wrap" ref="modifyCommentModal_warp">
        <a-modal v-model:visible="isShow" :title="title" :footer="null" :confirm-loading="confirmLoading"
            :getContainer="() => this.$refs.modifyCommentModal_warp" :afterClose="reset">
            <div class="wrap-content">
                <div class="wrap-content-item">
                    <a-textarea v-model:value="entity.comment" :rows="8" style="
              width: calc(100% - 200px);
              border-radius: 15px;
              text-align: left;
            " placeholder="评论内容，换行代表一条评论" @blur="focus" @input="focus" />
                    <span>您输入了 {{ lines }} 行</span>
                </div>
                <div class="wrap-content-item">
                    <a-button class="ant-button" type="primary" @click="confirm">确认更换</a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
export default {
    props: {
        title: { type: String, default: "更换话术" },
        visible: { type: Boolean, default: false },
        orderNo: { type: String, default: null },
        submit: { type: Function, default: null },
    },
    data() {
        return {
            isShow: false,
            entity: {
                comment: null,
            },
            lines: 0,
        };
    },
    mounted() { },
    methods: {
        reset() {
        },
        confirm() {
            let param = {};
            param.order_no = this.orderNo;
            param.succ_comment_list = this.focus();
            this.submit(param);
        },
        focus() {
            let list = [];
            if (this.entity.comment) {
                this.entity.comment.split("\n").forEach((item) => {
                    if (item) {
                        list.push(item);
                    }
                });
                this.lines = list.length;
            } else {
                this.lines = 0;
            }
            return list;
        },
    },

    setup() { },
    watch: {
        visible: {
            immediate: true,
            handler(a) {
                this.isShow = a;
            },
        },
        isShow: {
            handler(a) {
                this.$emit("changeVisible", a, this.orderNo);
            },
        },
        orderNo: {
            immediate: true,
            async handler(value) {
                console.log(value);
                if (!value) {
                    return;
                }
            },
        },
    },
};
</script>
<style lang="scss" scoped>
.ant-button {
    background: #0086F5;
    border-radius: 14px;
    color: rgba(255, 255, 255, 0.85);
}

.modifyCommentModal-wrap {
    ::v-deep {
        .ant-modal-content {
            //   min-width: 449px;
            //   min-height: 597px;
            background: #e5f3ff;
            box-shadow: 4px 13px 20px 0px rgba(146, 146, 146, 0.49);
            border-radius: 12px;
        }

        .modifyCommentModal-wrap_header {
            padding-left: 10%;
            padding-right: 10%;

            .modifyCommentModal-wrap_header_hint {
                font-size: 12px;
                font-family: PingFangSC-Semibold, PingFang SC;
                text-align: left;
                background: #cde8ff;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07),
                    inset 0px 1px 4px 0px rgba(0, 80, 157, 0.77);
                border-radius: 8px;
                border: 1px solid #0075f3;
                text-align: center;
                line-height: 38px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #0086f5;
            }
        }

        .modifyCommentModal-wrap_databody {
            .modifyCommentModal-wrap_databody_tips {
                padding-top: 0px;
                padding-left: 50%;
                font-size: 12px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #0091ff;

                .exceed {
                    color: red;
                }
            }
        }

        .ant-input-group.ant-input-group-compact {
            .ant-select:last-child {
                .ant-select-selector {
                    border-radius: 15px;
                }
            }
        }

        .ant-modal-header {
            background: #e5f3ff;
            border-radius: 12px;
            text-align: center;
            border-bottom: 0px;
        }

        .ant-modal-title {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #0086f5;
            line-height: 20px;
        }
    }

    .wrap-content {
        width: 100%;


        .wrap-content-item {
            width: 100%;
            justify-items: center;
            display: grid;

            span {
                text-align: right;
                width: 60%;
            }
        }
    }

}
</style>
  