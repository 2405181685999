<template>
  <div class="promotionHome-wrap" v-if="list.length > 0">
    <div class="left_tk_title">
      <div class="left_tk_title_block"></div>
      <div class="left_tk_title_content">友情链接</div>
    </div>
    <div class="promotionHome-list">
      <a
        class="item"
        v-for="item in list"
        v-bind:key="item.promotion_id"
        @click="openWindow(item.promotion_link)"
        :title="item.promotion_name"
      >
        <div class="logo-div">
          <a-image :preview="false" :src="item.promotion_logo"></a-image>
        </div>
        <div class="info-div">
          <span>{{ item.promotion_name }}</span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import userApi from "@/http/apiUtil/userApi";
export default {
  name: "",
  mixins: [],
  components: {},
  props: {
    promotionType: { type: Number, default: null },
  },
  data() {
    return {
      list: [],
    };
  },
  computed: {},
  watch: {
    promotionType: {
      immediate: true,
      async handler(value) {
        if (value != null) {
          console.log(userApi);
          let param = {};
          param.type = value;
          let res = await userApi.PromotionList(param);
          if (res.ret_code === 200) {
            this.list = res.ret_obj;
          }
        }
      },
    },
  },
  created() {},
  mounted() {
    //this.init();
  },
  methods: {
    init() {
      for (let i = 0; i <= 10; i++) {
        this.list.push({
          promotion_name: "百度" + i,
          promotion_id: i,
          promotion_logo:
            "https://img0.baidu.com/it/u=1877340641,1001817141&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=643",
          promotion_link: "www.baidu.com",
        });
      }
    },
    openWindow(url) {
      if (url.indexOf("http") < 0) {
        url = "http://" + url;
      }
      window.open(url);
    },
  },
};
</script>

<style scoped lang="scss">
.promotionHome-wrap {
  margin: 15px 0px;
  .left_tk_title {
    display: flex;
    margin-bottom: 0.8rem;
    .left_tk_title_block {
      width: 4px;
      height: 22px;
      background: linear-gradient(180deg, #00bbfb 0%, #0086f5 100%);
      border-radius: 4px;
    }

    .left_tk_title_content {
      margin-left: 8px;
      font-size: 16px;
      font-weight: 500;
      color: #0086f5;
    }
  }

  .promotionHome-list {
    display: grid;
    grid-gap: 10px;
    flex-wrap: wrap;
    justify-content: space-around;
    grid-template-columns: repeat(auto-fill, 180px);
    .item {
      cursor: pointer;
      width: 190px;
      height: 55px;
      background: linear-gradient(270deg, #45abff 0%, #7bd5ff 100%);
      box-shadow: 1px 2px 0px 1px #867dff;
      border-radius: 28px;
      display: flex;
      .logo-div {
        width: 45px;
        margin: auto 0.5rem;
        :deep(.ant-image-img) {
          width: 38px;
          height: 38px;
          border-radius: 5rem;
        }
      }
      .info-div {
        display: grid;
        color: #ffffff;
        align-items: center;
        span {
          width: 120px;
          max-height: 48px;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }
    }
  }
}
</style>
