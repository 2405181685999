const cn = {
    Welcome: '欢迎回来！',
    SignInToContinue: '请登录',
    Email: '邮箱',
    Password: '密码',
    ForgotPassword: '忘记密码？',
    HomePage: '首页',
    OrderRecord: '订单记录',
    Transactions: '充值记录',
    Quit: '退出',
    Submit: '提交',
    HomeWelCome: '欢迎光临',
    HomeAbout: '欢迎您来到我们的业务页面，这里为您的商业策略提供 相关帮助，祝您拥有一个愉快的体验。',
    GuidanceContact: '下单须知',
    TikTok: '业务列表',
    PersonalAssets: '个人资产',
    DollerTHRU: '会员卡',
    Recharge: '充值',
    BusinessName: '业务名称',
    Payment: '金额',
    OrderCount: '订单数量',
    Time: '时间',
    Data: '数据',
    Total: '总计',
    Rows: '条',
    Name: '用户名',
    OrderNumber: '订单编号',
    OrderAmount: '订单金额',
    PayAmout: '充值金额',
    TotalRevenue: '总消费金额',
    TodayRevenue: '今日消费金额',
    TodayOrderCount: '今日订单数量',
    TotalRecharge: '总充值金额',
    TodayRecharge: '今日充值金额',
    TodayRechargeCount: '今日充值笔数',
    Lines: '行',
    CreateAccount: '创建账户',
    PriceUnit: '￥',
    Order: '下单',
    SignIn: '登录',
    AlreadyAccount: '已经有账户了',
    Amount: '金额',
    Mobile: '手机号码',
    Tips: {
        LoginEmail: '请输入邮箱',
        LoginPwd: '请输入密码',
        LoginName: '请输入用户名',
        LoginMobile: '请输入手机号码',
        ModalTkLink: '请输入TikTok号',
        ModalVideoLink: '请输入视频链接',
        ModalComment: '请输入评论',
        ModalOrderCount: '请输入订单数量',
        ModalOrderDuration: '请输入订单持续时间',
        ModalOrderMltiple: '请输入50的倍数',
        inputTips: '您输入了',
    },
};
const en = {
    Welcome: 'Welcome Back!',
    SignInToContinue: 'Sign In to Continue',
    Email: 'Email',
    Password: 'Password',
    ForgotPassword: 'Forgot Password?',
    Submit: 'submit',
    HomePage: 'Home Page',
    OrderRecord: 'Order Record',
    Transactions: 'Transactions',
    Quit: 'Quit',
    HomeWelCome: 'Hi',
    HomeAbout: 'Welcome Home! The air quality is good & Fresh you can go out today',
    GuidanceContact: 'Guidance and Contact',
    TikTok: 'TIKTOK',
    PersonalAssets: 'Personal assets',
    DollerTHRU: 'Doller THRU',
    Recharge: 'Recharge',
    BusinessName: 'Business name',
    Payment: 'Payment',
    OrderCount: 'Order Count',
    Time: 'Time',
    Data: 'Data',
    Total: 'total',
    Rows: 'rows',
    OrderNumber: 'Order Number',
    OrderAmount: 'Order Amount',
    PayAmout: 'Pay Amout',
    TotalRevenue: 'Total revenue',
    TodayRevenue: 'Today revenue',
    TodayOrderCount: 'Number of orders today',
    TotalRecharge: 'Total recharge',
    TodayRecharge: 'oday recharge',
    TodayRechargeCount: 'umber of recharge today',
    Lines: 'lines',
    CreateAccount: 'Create an account',
    PriceUnit: '$',
    Order: 'Order',
    Name: 'Name',
    SignIn: 'Sign in',
    AlreadyAccount: 'Already have an account',
    Amount: 'Amount',
    Mobile: '手机号码',
    Tips: {
        LoginEmail: 'Please input your eamil',
        LoginPwd: 'Please input your password',
        LoginMobile: 'Please input your mobile',
        LoginName: 'Please input your name',
        ModalTkLink: 'TikTok Homepage Link',
        ModalComment: 'Please enter a comment',
        ModalOrderCount: 'Please enter a order quantity',
        ModalOrderDuration: 'Please enter a order duration',
        ModalOrderMltiple: 'Please enter a multiple of 50',
        inputTips: 'You have entered',
    },
};
export default {
    cn,
    en,
};