<template>
	<div class="contentWarp">
		<div class="content_header">
			<div
				v-for="(item, index) in headers"
				v-bind:key="index"
				class="header"
				:style="{ width: 100 / headers.length + '%' }"
			>
				<div class="header_item" :style="{ background: item.background }"></div>
				<div style="display: inline-block">
					<div class="header_item_1" v-if="item.key === 'Total recharge'">
						{{ totalRecharge.toFixed(2) }}{{ item.unit }}
					</div>
					<div class="header_item_1" v-else-if="item.key === 'Today recharge'">
						{{ todayRecharge.toFixed(2) }}{{ item.unit }}
					</div>
					<div class="header_item_1" v-else-if="item.key === 'Number of recharge today'">
						{{ rechargeComment.toFixed(2) }}{{ item.unit }}
					</div>
					<div class="header_item_2">{{ item.title }}</div>
				</div>
			</div>
		</div>
		<div class="content_body">
			<a-table
				:dataSource="datatable.dataSource"
				:columns="datatable.columns"
				:pagination="datatable.pagination"
			/>
		</div>
	</div>
</template>

<script>
	import userApi from '@/http/apiUtil/userApi'
	import gsap from 'gsap'
	export default {
		components: {},
		props: {},
		data() {
			return {
				number: 0,
				tweened: 0,
				totalRecharge: 0,
				todayRecharge: 0,
				rechargeComment: 0,
				headers: [
					{
						title: this.$lang.GetLangByKey('TotalRecharge'),
						key: 'Total recharge',
						unit: this.$lang.GetLangByKey('PriceUnit'),
						background: 'linear-gradient(180deg, #FFBCB0 0%, #FF8778 100%)',
					},
					{
						title: this.$lang.GetLangByKey('TodayRecharge'),
						key: 'Today recharge',
						unit: this.$lang.GetLangByKey('PriceUnit'),
						background: 'linear-gradient(360deg, #44D7B6 0%, #C3E9D5 100%)',
					},
					{
						title: this.$lang.GetLangByKey('TodayRechargeCount'),
						key: 'Number of recharge today',
						background: 'linear-gradient(150deg, #83CBFF 0%, #007AFF 100%)',
					},
				],
				datatable: {
					dataSource: [],
					pagination: {
						pageNo: 1,
						pageSize: 10,
						current: 1,
						showSizeChanger: true,
						pageSizeOptions: ['5', '10', '20', '50', '100'],
						showTotal: (total) =>
							`${this.$lang.GetLangByKey('Total')} ${total} ${this.$lang.GetLangByKey('Rows')}`,
						onShowSizeChange: (current, pageSize) => this.onSizeChange(current, pageSize),
						onChange: (page, pageSize) => this.onPageChange(page, pageSize),
						total: 0, //总条数
					},
					columns: [
						{
							title: this.$lang.GetLangByKey('OrderNumber'),
							dataIndex: 'order_no',
							key: 'order_no',
						},
						{
							title: this.$lang.GetLangByKey('PayAmout'),
							dataIndex: 'pay_amout',
							key: 'pay_amout',
						},
						// {
						//   title: "payment method",
						//   dataIndex: "PaymentMethod",
						//   key: "PaymentMethod",
						// },
						{
							title: this.$lang.GetLangByKey('Time'),
							dataIndex: 'createtime',
							key: 'createtime',
						},
					],
				},
			}
		},
		watch: {},
		computed: {},
		methods: {
			onSizeChange(page, size) {
				this.datatable.pagination.current = page
				this.datatable.pagination.pageNo = page
				this.datatable.pagination.pageSize = size
				//this.getDetailList();
			},
			onPageChange(page, size) {
				this.datatable.pagination.current = page
				this.datatable.pagination.pageNo = page
				this.datatable.pagination.pageSize = size
				this.getDetailList()
			},
			async getDetailList() {
				let param = {
					page: this.datatable.pagination.pageNo,
					size: this.datatable.pagination.pageSize,
					createtime: this.$dayjs().format('YYYY-MM-DD'),
				}
				var detailRes = await userApi.Transactions(param)
				if (detailRes.ret_code == 200) {
					if (detailRes.ret_obj.details.length > 0) {
						detailRes.ret_obj.details.forEach((item) => {
							item.pay_amout =
								this.$util.formatPrice(item.pay_amout) + this.$lang.GetLangByKey('PriceUnit')
							item.createtime = this.$dayjs(item.createtime).format('YYYY-MM-DD HH:mm:ss')
						})

						this.datatable.dataSource = detailRes.ret_obj.details
					}
					this.datatable.pagination.total = detailRes.ret_count
					this.totalRecharge = detailRes.ret_obj.statistics.totalRecharge
					this.todayRecharge = detailRes.ret_obj.statistics.todayRecharge
					this.rechargeComment = detailRes.ret_obj.statistics.rechargeComment
				}
			},
		},
		created() {},
		async mounted() {
			await this.getDetailList()
			console.log(this.$util.formatPrice(this.totalRecharge))
			gsap.to(this, {
				duration: 1.0,
				totalRecharge: this.$util.formatPrice(this.totalRecharge),
			})
			gsap.to(this, {
				duration: 1.0,
				todayRecharge: this.$util.formatPrice(this.todayRecharge),
			})
			gsap.to(this, {
				duration: 1.0,
				rechargeComment: this.rechargeComment,
			})
		},
	}
</script>

<style lang="scss" scoped>
	.contentWarp {
		width: 100%;
		height: 100%;
		background-color: white;

		.content_header {
			padding: 2%;
			display: flex;

			.header {
				.header_item {
					width: 10px;
					height: 72px;
					border-radius: 4px;
					display: inline-block;
					background: linear-gradient(180deg, #ffbcb0 0%, #ff8778 100%);
				}

				.header_item_1 {
					width: 130px;
					height: 50px;
					font-size: 36px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #0086f5;
					line-height: 50px;
					padding-left: 1rem;
				}

				.header_item_2 {
					width: 174px;
					height: 33px;
					font-size: 24px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #0086f5;
					line-height: 33px;
					padding-left: 1rem;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
		}

		.content_body {
			::v-deep {
				.ant-table-cell {
					border-bottom: 1px solid rgba(0, 134, 245, 0.34);
					text-align: center;
					font-size: 14px;
					font-family: PingFangSC-Semibold, PingFang SC;
					font-weight: 600;
					color: #0086f5;
				}

				.ant-table-wrapper {
					clear: both;
					max-width: 100%;
					padding: 0px 2% 0px 2%;
				}
			}
		}
	}
</style>
