<template>
  <div class="m-orderlist-wrap">
    <div class="wrap-summary">
      <div class="wrap-summary-item">
        <div class="p-red p"></div>
        <div>
          <div>{{ util.formatPrice(orderStatistics.totalRevenue) }}￥</div>
          <div>总消费金额</div>
        </div>
      </div>
      <div class="wrap-summary-item">
        <div class="p-green p"></div>
        <div>
          <div>{{ util.formatPrice(orderStatistics.todayRevenue) }}￥</div>
          <div>今日消费金额</div>
        </div>
      </div>
    </div>
    <div class="wrap-body">
      <div class="wrap-body-search">
        <div>订单搜索:</div>
        <a-input
          style="width: 50%"
          placeholder="订单号"
          v-model:value="searchModel.orderNo"
        ></a-input>
        <a-button type="primary" @click="search">搜索</a-button>
      </div>
      <div class="wrap-body-list">
        <van-list
          v-model:loading="listConfig.loading"
          :finished="listConfig.finished"
          finished-text="没有更多了"
          @load="getOrderList"
          v-model:error="listConfig.error"
          error-text="请求失败，点击重新加载"
        >
          <div
            class="wrap-body-list-item"
            v-for="(item, index) in table.data"
            :key="item.order_no"
            :index="index"
          >
            <div class="item-rows">
              <div>
                <div>订单编号</div>
                <div>{{ item.order_no }}</div>
              </div>
              <div>
                <div>TK号或视频链接</div>
                <div>{{ item.video_link }}</div>
              </div>
              <div>
                <div>订单金额</div>
                <div>{{ item.order_amout }}</div>
              </div>
            </div>
            <div class="item-rows">
              <div>
                <div>业务名称</div>
                <div>{{ item.config_name }}</div>
              </div>
              <div>
                <div>订单数量</div>
                <div>{{ item.order_robot_count }}</div>
              </div>
              <div>
                <div>退费</div>
                <div>{{ item.refund_amout }}</div>
              </div>
            </div>
            <div class="item-rows">
              <div>
                <div>订单状态</div>
                <div>{{ item.order_state }}</div>
              </div>
              <div>
                <div>时间</div>
                <div>{{ item.order_time }}</div>
              </div>
            </div>
            <div class="item-rows">
              <div>
                <div>操作</div>
                <div>
                  <a
                    v-if="item.order_type === 1004 || item.order_type === 21004"
                    class="a-red"
                    type="primary"
                    @click="showOrderCommentInfoModal(true, item)"
                    >评论信息</a
                  >
                  <div
                    style="display: flex; color: red"
                    v-if="item.order_type == 1002"
                  >
                    <a
                      title="暂停订单"
                      class="a-red"
                      v-if="item.order_state == '执行中'"
                      @click="OrderPause(item.order_no)"
                      >暂停订单</a
                    >
                    <a
                      title="开启订单"
                      class="a-red"
                      v-if="item.order_state == '暂停'"
                      @click="OrderUnPause(item.order_no)"
                      >开启订单</a
                    >
                    <a
                      class="a-red"
                      @click="delayTimeModalShow(true, item.order_no)"
                      title="订单延长"
                      v-if="item.order_state == '执行中'"
                      >订单延长</a
                    >
                    <a
                      class="a-red"
                      @click="modifyCommentModalShow(true, item.order_no)"
                      title="更换话术"
                      v-if="item.order_state == '执行中'"
                      >更换话术</a
                    >
                    <a
                      class="a-red"
                      @click="commentFrequencyModalShow(true, item.order_no)"
                      title="发言频率"
                      v-if="item.order_state == '执行中'"
                      >发言频率</a
                    >
                  </div>
                  <span v-if="item.order_type == 1001">
                    <a
                      v-if="item.order_state == '执行中'"
                      class="a-red"
                      @click="delayTimeModalShow(true, item.order_no)"
                      title="订单延长"
                      >订单延长</a
                    >
                  </span>
                  <span
                    v-if="
                      item.order_type == 1001 ||
                      item.order_type == 1005 ||
                      item.order_type == 1006 ||
                      item.order_type == 1007 ||
                      item.order_type == 21005 ||
                      item.order_type == 21006 ||
                      item.order_type == 21007
                    "
                  >
                    <a
                      class="a-red"
                      @click="orderInitAndEndNoModalShow(true, item.order_no)"
                      type="primary"
                      v-if="item.order_state == '已完成'"
                      >查看数据</a
                    >
                    <a
                      class="a-red"
                      @click="orderAbort(item.order_no)"
                      type="primary"
                      v-if="
                        (item.order_state == '执行中' ||
                          item.order_state == '排队中') &&
                        (item.order_type == 1006 || item.order_type == 21006)
                      "
                      danger
                      >终止订单</a
                    >
                  </span>
                  <span
                    v-if="item.business_type == 3"
                    class="a-red"
                    @click="showAccountInfoModal(true, item)"
                    >账号信息</span
                  >
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
    <order-init-and-end-no-modal
      v-model:visible="orderInitAndEndNoModalEntity.isShow"
      @changeVisible="orderInitAndEndNoModalShow"
      :orderNo="orderNo"
    ></order-init-and-end-no-modal>
    <account-info-modal
      v-model:visible="accountInfoModalEntity.isShow"
      :order_no="accountInfoModalEntity.orderNo"
      @changeVisible="showAccountInfoModal"
    >
    </account-info-modal>
    <order-comment-info-modal
      v-model:visible="orderCommentInfoModalData.isShow"
      @changeVisible="showOrderCommentInfoModal"
      :orderNo="orderCommentInfoModalData.orderNo"
    >
    </order-comment-info-modal>
    <delay-time-modal
      v-model:visible="delayTimeModalEntity.isShow"
      @changeVisible="delayTimeModalShow"
      :submit="OrderDelayTime"
      :orderNo="orderNo"
    ></delay-time-modal>
    <modify-comment-modal
      v-model:visible="modifyCommentModalEntity.isShow"
      @changeVisible="modifyCommentModalShow"
      :submit="OrderModifyComments"
      :orderNo="orderNo"
    ></modify-comment-modal>
    <comment-frequency-modal
      v-model:visible="commentFrequencyModalEntity.isShow"
      @changeVisible="commentFrequencyModalShow"
      :submit="OrderCommentsFrequency"
      :orderNo="orderNo"
    ></comment-frequency-modal>
  </div>
</template>

<script>
import orderApi from "@/http/apiUtil/orderApi";

import util from "@/common/util";
import { List } from "vant";
import { h } from "vue";
import orderInitAndEndNoModal from "@/components/order/orderInitAndEndNoModal.vue";
import AccountInfoModal from "@/components/shopping/AccountInfoModal.vue";
import orderCommentInfoModal from "@/components/order/orderCommentInfoModal.vue";
import delayTimeModal from "@/components/order/delayTimeModal.vue";
import modifyCommentModal from "@/components/order/modifyCommentModal.vue";
import commentFrequencyModal from "@/components/order/commentFrequencyModal.vue";
export default {
  name: "",
  mixins: [],
  components: {
    [List.name]: List,
    orderInitAndEndNoModal,
    AccountInfoModal,
    orderCommentInfoModal,
    delayTimeModal,
    modifyCommentModal,
    commentFrequencyModal,
  },
  props: {},
  data() {
    return {
      table: {
        page: 0,
        size: 20,
        data: [],
      },
      util: util,
      searchModel: {
        orderNo: null,
      },
      orderStatistics: {},
      listConfig: {
        error: false,
        loading: false,
        finished: false,
      },
      delayTimeModalEntity: {
        isShow: false,
      },
      orderInitAndEndNoModalEntity: {
        isShow: false,
      },
      accountInfoModalEntity: {
        isShow: false,
        orderNo: null,
      },
      orderCommentInfoModalData: {
        isShow: false,
        orderNo: null,
      },
      modifyCommentModalEntity: {
        isShow: false,
      },
      commentFrequencyModalEntity: {
        isShow: false,
      },
    };
  },
  computed: {},
  watch: {},
  created() {},
  async mounted() {
    this.$emit("onClickMenu", 1);
    this.getOrderStatistics();
  },
  methods: {
    search() {
      this.table.page = 0;
      this.table.data = [];
      this.getOrderList();
    },
    //获取订单列表
    async getOrderList() {
      let that = this.searchModel;
      console.log(that);
      this.table.page = this.table.page + 1;
      let param = {
        order_userid: this.$cookies.get("userid"),
        page: this.table.page,
        size: this.table.size,
      };
      param.order_no = this.searchModel.orderNo;
      var orderRes = await orderApi.OrderList(param);
      if (orderRes.ret_code == 200) {
        this.listConfig.loading = false;
        if (orderRes.ret_obj.length > 0) {
          orderRes.ret_obj.forEach((item) => {
            item.order_amout =
              util.formatPrice(item.order_amout) +
              this.$lang.GetLangByKey("PriceUnit");
            item.refund_amout =
              util.formatPrice(item.refund_amout) +
              this.$lang.GetLangByKey("PriceUnit");
            item.order_time = this.$dayjs(item.order_time).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            item.order_state = util.getOrderState(
              item.order_state,
              item.refund_amout
            );
            this.table.data.push(item);
          });
        } else {
          this.listConfig.finished = true;
        }
      }
    },
    async getOrderStatistics() {
      let param = {
        order_userid: this.$cookies.get("userid"),
      };
      param.order_time = this.$dayjs().format("YYYY-MM-DD");
      var statisticsRes = await orderApi.OrderStatistics(param);
      if (statisticsRes.ret_code == 200) {
        this.orderStatistics = statisticsRes.ret_obj;
      }
    },
    //初始数据和结束数据
    orderInitAndEndNoModalShow(flag, orderNo) {
      console.log(orderNo);
      this.orderNo = orderNo;
      this.orderInitAndEndNoModalEntity.isShow = flag;
    },
    /**
     *终止订单
     */
    async orderAbort(order_no) {
      let that = this;
      this.$modal.confirm({
        content: h(
          "div",
          {
            style: {
              "font-size": "14px",
              "font-weight": "600",
            },
          },
          [
            h("div", "订单终止，已完成数量收费，未完成数量将于凌晨自动退费"),
            h(
              "div",
              { style: "margin-left:calc(1rem + 22px);" },
              "是否确认 终止 订单？"
            ),
          ]
        ),
        async onOk() {
          let res = await orderApi.OrderAbort({ order_no: order_no });
          if (res.ret_code == 200) {
            that.$message.success("操作成功");
            that.getOrderList();
          }
        },
        cancelText: "取消",
        onCancel() {},
      });
    },
    /**
     *展示TK账号弹窗
     */
    showAccountInfoModal(flag, data) {
      this.accountInfoModalEntity.isShow = flag;
      if (data) {
        this.accountInfoModalEntity.orderNo = data.order_no;
        console.log(data);
      }
    },
    //订单暂停
    OrderPause(order_no) {
      console.log(order_no);
      let that = this;
      this.$modal.confirm({
        content: h(
          "div",
          {
            style: {
              "font-size": "14px",
              "font-weight": "600",
            },
          },
          [
            h("div", "订单暂停发言，但会持续扣费哦"),
            h(
              "div",
              { style: "margin-left:calc(1rem + 22px);" },
              "是否确认 暂停 订单？"
            ),
          ]
        ),
        async onOk() {
          let param = {};
          param.order_no = order_no;
          let res = await orderApi.OrderPause(param);
          if (res.ret_code == 200) {
            that.$message.success("操作成功");
            that.getOrderList();
          }
        },
        cancelText: "取消",
        onCancel() {},
      });
    },
    //订单重启
    OrderUnPause(order_no) {
      let that = this;
      console.log(order_no);
      this.$modal.confirm({
        content: "是否确认 开启 订单？",
        async onOk() {
          let param = {};
          param.order_no = order_no;
          let res = await orderApi.OrderUnpause(param);
          if (res.ret_code == 200) {
            that.$message.success("操作成功");
            that.getOrderList();
          }
        },
        cancelText: "取消",
        onCancel() {},
      });
    },
    //发言频率弹窗
    commentFrequencyModalShow(flag, orderNo) {
      console.log(orderNo);
      this.orderNo = orderNo;
      this.commentFrequencyModalEntity.isShow = flag;
    },
    //订单延时
    async OrderDelayTime(param = {}) {
      console.log(param);
      let res = await orderApi.OrderDelayTime(param);
      if (res.ret_code == 200) {
        this.$message.success("操作成功");
        this.getOrderList();
        this.delayTimeModalShow(false, "");
      }
    },
    //订单延长弹窗
    delayTimeModalShow(flag, orderNo) {
      this.orderNo = orderNo;
      this.delayTimeModalEntity.isShow = flag;
    },
    //评论更换
    modifyCommentModalShow(flag, orderNo) {
      this.orderNo = orderNo;
      this.modifyCommentModalEntity.isShow = flag;
    },
    //更换弹幕内容
    async OrderModifyComments(param = {}) {
      console.log(param);
      let res = await orderApi.OrderModifyComments(param);
      if (res.ret_code == 200) {
        this.$message.success("操作成功");
        this.getOrderList();
        this.modifyCommentModalShow(false, "");
      }
    },
    //评论频率更改
    async OrderCommentsFrequency(param = {}) {
      console.log(param);
      let res = await orderApi.OrderCommentsFrequency(param);
      if (res.ret_code == 200) {
        this.$message.success("操作成功");
        this.getOrderList();
        this.commentFrequencyModalShow(false, "");
      }
    },
    showOrderCommentInfoModal(flag, data) {
      this.orderCommentInfoModalData.isShow = flag;
      if (data) {
        this.orderCommentInfoModalData.orderNo = data.order_no;
        console.log(data);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.m-orderlist-wrap {
  margin: 0px 0.5rem 0px 0.5rem;

  .wrap-summary {
    color: #0086f5;
    display: flex;
    justify-content: space-around;
    background: #e4f3ff;
    box-shadow: inset 0px 1px 3px 0px rgba(0, 134, 245, 0.51);
    border-radius: 8px;
    padding: 0.5rem 0px 0.5rem 0px;

    .wrap-summary-item {
      display: flex;

      .p {
        width: 5px;
        border-radius: 3px;
      }

      .p-red {
        background: linear-gradient(180deg, #ffb2b2 0%, #ff7a7a 100%);
      }

      .p-green {
        background: linear-gradient(
          360deg,
          rgb(68, 215, 182) 0%,
          rgb(195, 233, 213) 100%
        );
      }

      .p-blur {
        background: linear-gradient(
          150deg,
          rgb(131, 203, 255) 0%,
          rgb(0, 122, 255) 100%
        );
      }
    }

    .wrap-summary-item > div:last-child {
      margin-left: 0.5rem;
    }
  }

  .wrap-body {
    .wrap-body-search {
      display: flex;
      justify-content: space-around;
      padding: 0.5rem 0px;
      align-items: center;
    }

    .wrap-body-list {
      .wrap-body-list-item {
        margin-top: 1rem;
        color: #0091ffff;
        background: #e4f3ff;
        border-radius: 8px;
        padding: 0.5rem;

        .item-rows {
          display: flex;
          justify-content: space-around;
        }

        .item-rows > div:not(:first-child) {
          padding-left: 0.5rem;
        }

        .item-rows > div {
          min-width: 33.3%;
          flex-grow: 1;
          word-break: break-all;
          white-space: normal;

          button {
            margin-right: 0.5rem;
            margin-bottom: 0.5rem;
            border-radius: 7px;
          }
        }
      }

      .wrap-body-list-item > div:not(:first-child) {
        margin-top: 1rem;
      }
    }
  }
}

.a-red {
  margin-right: 1rem;
  color: red;
  text-decoration: underline;
}
</style>
