<template>
  <div class="modifyPwdModal_warp" ref="warp">
    <a-modal v-model:visible="isShow" :title="title" :footer="null" :confirm-loading="confirmLoading"
      :getContainer="() => this.$refs.warp" :afterClose="reset">
      <div style="padding-left: 10%; padding-right: 10%">
        <p class="contentText" v-html="text"></p>
      </div>

      <div class="modifyPwdModal_warp_databody">
        <a-input-group compact class="buttonGroup">
          <a-input-password v-model:value="entity.userpwd" style="
              width: calc(100% );
              border-radius: 15px;
              background-color: white;
              color: #0086f5ff;
            " placeholder="请输入密码" />
        </a-input-group>
        <a-input-group compact class="buttonGroup">
          <a-input-password v-model:value="entity.newuserpwd" style="
              width: calc(100% );
              border-radius: 15px;
              background-color: white;
              color: #0086f5ff;
            " placeholder="请输入新密码" />
        </a-input-group>
        <a-input-group compact class="buttonGroup">
          <a-input-password v-model:value="entity.confirmNewuserpwd" style="
              width: calc(100% );
              border-radius: 15px;
              background-color: white;
              color: #0086f5ff;
            " placeholder="请再次输入新密码" />
        </a-input-group>
        <a-input-group compact class="buttonGroup">
          <a-button type="primary" style="border-radius: 15px; z-index: 9999" @click="submit()">确认修改</a-button>
        </a-input-group>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: {
    title: { type: String, default: "" },
    visible: { type: Boolean, default: false },
    onSubmit: { type: Function, default: null },
  },
  data() {
    return {
      isShow: false,
      confirmLoading: false,
      compact: false,
      entity: {
        confirmNewuserpwd: "",
        userpwd: "",
        newuserpwd: "",
      },
    };
  },
  mounted() { },
  methods: {
    reset() {
      this.entity = {
        confirmNewuserpwd: "",
        userpwd: "",
        newuserpwd: "",
      };
    },
    submit() {
      let param = this.entity;
      this.onSubmit(param);
    },
  },

  setup() { },
  watch: {
    visible: {
      handler(a) {
        this.isShow = a;
      },
    },
    isShow: {
      handler(a) {
        this.$emit("changeVisible", a);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.buttonGroup {
  padding-top: 25px;
  text-align: center;
}

.contentText {
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0086f5;
  line-height: 18px;
  text-align: left;
}

.modifyPwdModal_warp {
  ::v-deep {
    .ant-modal-content {
      //   min-width: 449px;
      //   min-height: 597px;
      background: #e5f3ff;
      box-shadow: 4px 13px 20px 0px rgba(146, 146, 146, 0.49);
      border-radius: 12px;
    }

    .modifyPwdModal_warp_databody {
      width: 80%;
      margin: 0px auto;

      .modifyPwdModal_warp_databody_tips {
        padding-top: 0px;
        padding-left: 50%;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #0091ff;

        .exceed {
          color: red;
        }
      }
    }

    .ant-input-group.ant-input-group-compact {
      .ant-select:last-child {
        .ant-select-selector {
          border-radius: 15px;
        }
      }
    }

    .ant-modal-header {
      background: #e5f3ff;
      border-radius: 12px;
      text-align: center;
      border-bottom: 0px;
    }

    .ant-modal-title {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0086f5;
      line-height: 20px;
    }
  }
}
</style>
