<template>
  <div class="m-login-wrap">
    <div class="m-login-wrap-body">
      <van-cell-group inset>
        <div class="login-wrap-body-title">
          <h2>登录</h2>
        </div>
        <van-field v-model="model.email" label="邮箱" placeholder="请输入邮箱" />
        <van-field v-model="model.password" :type="!showPwd ? 'password' : 'text'" label="密码" placeholder="请输入密码"
          :right-icon="showPwd ? 'smile-o' : 'closed-eye'" @click-right-icon="showPwdClick()">
        </van-field>
        <div class="login-wrap-body-forget">
          <div class="left"><router-link to="/Mobile/Register">创建账户</router-link></div>
          <div class="right"> <a @click="resetPwd">忘记密码？</a></div>
        </div>
        <van-button type="primary" @click="OnSubmit" class="login-wrap-body-button">登录</van-button>
      </van-cell-group>

    </div>
  </div>
  <service-modal v-model:visible="serviceModel.visible" @changeVisible="changeVisible" :email="serviceModel.email"
    :title="serviceModel.title">
  </service-modal>
</template>

<script>
import serviceModal from "@/components/service/serviceModal.vue";
import cookies from "js-cookie";
import userApi from "@/http/apiUtil/userApi";
import { Button, Field, CellGroup } from "vant";
import "vant/lib/index.css";
export default {
  name: "",
  mixins: [],
  components: {
    [Button.name]: Button,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    serviceModal
  },
  props: {},
  data() {
    return {
      model: {
        email: null,
        password: null,
      },
      showPwd: false,
      serviceModel: {
        visible: false,
        email: null,
        title: null,
      }
    };
  },
  computed: {},
  watch: {},
  created() { },
  mounted() { },
  methods: {
    showPwdClick() {
      this.showPwd = !this.showPwd;
    },
    async OnSubmit() {
      let info = {
        email: this.model.email,
        userpwd: this.model.password,
      };
      let res = await userApi.Login(info);
      if (res.ret_code == 200) {
        cookies.set("userInfo", encodeURI(JSON.stringify(res.ret_obj)));
        //写入cookie
        cookies.set("token", res.ret_obj.token);
        cookies.set("userid", res.ret_obj.userid);
        cookies.set("userkey", res.ret_obj.user_key);
        cookies.set("username", res.ret_obj.username);
        this.$message.success(res.ret_msg);
        window.location.href = "/#/Mobile/Home";
      } else if (res.ret_msg == "您的账号已停用，请联系管理员！") {
        this.serviceModel.title = "请联系客服激活账户";
        this.serviceModel.email = info.email;
        this.changeVisible(true);
      }
    },
    changeVisible(flag) {
      this.serviceModel.visible = flag;
    },
    resetPwd() {
      this.serviceModel.title = "请联系客服重置密码";
      this.changeVisible(true);
    }
  },
};
</script>

<style scoped lang="scss">
.m-login-wrap {

  background: #EFF2F5;
  height: 100vh;
  display: grid;
  align-items: center;

  .m-login-wrap-body {
    text-align: center;

    .login-wrap-body-title {
      h2 {
        font-weight: 600;
      }
    }

    .login-wrap-body-forget {
      display: flex;

      .left {
        text-align: left;
        width: 50%;
        margin-left: 0.5rem;
      }

      .right {
        text-align: right;
        width: 50%;
        margin-right: 0.5rem;
      }

    }

    .login-wrap-body-button {
      margin: 1rem 0px 1rem 0px;
    }
  }
}
</style>
