<template>
	<div class="proxyConfig_warp" ref="proxyConfig_warp">
		<!-- 业务管理 -->
		<div class="proxyConfig_setting">
			<span>业务管理</span>
			<div>
				<comm-table
					ref="commTab"
					:columns="datatable.columns"
					:dataSource="datatable.dataSource"
					:tabDataBind="tabDataBind"
					:rowKey="(record) => record.id"
					:total="datatable.total"
				>
					<template #cell="{ column, record }">
						<template v-if="column.key === 'operate'">
							<a-button @click="showProxyInfo(record)" type="primary">详情</a-button>
						</template>
						<template v-if="column.key === 'add_price'">
							<a-input-number
								v-model:value="record.add_price"
								style="margin: -5px 0; border-radius: 15px; width: 80%; min-width: 20px; color: red"
								min="0"
							/>
						</template>
					</template>
				</comm-table>
				<div style="" class="proxyConfig_setting_divButton">
					<a-button type="primary" @click="save">保存</a-button>
				</div>
			</div>
		</div>
		<!-- 客服配置 -->
		<div class="proxyConfig_warp_service">
			<span>客服配置</span>
			<div>
				<div style="text-align: center; margin-bottom: 0.7rem">
					<p style="margin-bottom: 0; color: #0086f5">请选择300*320尺寸的图片上传</p>
					<a-image
						v-if="imageUrl"
						:src="imageUrl"
						alt="service"
						style="width: 150px; height: 160px"
					/>
				</div>
				<a-upload
					accept=".png,.jpg,.jpeg,.bmp"
					v-model:file-list="fileList"
					name="service"
					list-type="picture-card"
					class="avatar-uploader"
					:show-upload-list="false"
					:action="`${orgin}/api/user/UploadServiceImg`"
					:before-upload="beforeUpload"
					@change="handleChange"
				>
					<div>
						<loading-outlined v-if="loading"></loading-outlined>
						<plus-outlined v-else></plus-outlined>
						<div class="ant-upload-text">请选择上传</div>
					</div>
				</a-upload>
			</div>
		</div>
	</div>
</template>

<script>
	import commTable from '@/components/table/commTable.vue';
	import userApi from '@/http/apiUtil/userApi.js';
	import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';
	import { h } from 'vue';
	export default {
		components: { PlusOutlined, LoadingOutlined, commTable },
		name: '',
		mixins: [],
		props: {},
		data() {
			return {
				orgin: process.env.VUE_APP_BASE_URL,
				loading: false,
				fileList: [],
				imageUrl: '',
				datatable: {
					dataSource: [],
					columns: [
						{
							title: '序号',
							dataIndex: 'config_id',
							key: 'config_id',
						},
						{
							title: '业务名称',
							dataIndex: 'config_name',
							key: 'config_name',
						},
						{
							title: '业务价格',
							dataIndex: 'config_price',
							key: 'config_price',
							customRender: (obj) => {
								let value =
									this.$util.format1000Price(obj.text) + `（${obj.record.config_unit}个）`;
								return value || obj.text;
							},
						},
						{
							title: '价格上浮量',
							dataIndex: 'add_price',
							key: 'add_price',
							customRender: (obj) => {
								let value = this.$util.format1000Price(obj.text);
								return value || obj.text;
							},
						},
						{
							title: '实际价格',
							dataIndex: 'total_price',
							key: 'total_price',
							customRender: (obj) => {
								let value =
									this.$util.format1000Price(obj.text) + `（${obj.record.config_unit}个）`;
								return value || obj.text;
							},
						},
					],
					total: 0,
				},
			};
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {
			this.GetProxyService();
			this.searchBtn();
		},
		methods: {
			//查询
			async searchBtn(page) {
				this.$refs['commTab'].reset(page);
			},
			async tabDataBind(page = 1, size = 10, field, order) {
				console.log(`${page} ,${size},${field},${order}`);
				let res = await userApi.GetProxyConfigList();
				if (res.ret_code === 200) {
					res.ret_obj.forEach((item) => {
						item.add_price = this.$util.format1000Price(item.add_price);
					});
					this.datatable.dataSource = res.ret_obj;
				}
			},
			async save() {
				let res = await userApi.CreateProxyConfig(this.datatable.dataSource);
				if (res.ret_code === 200) {
					this.$message.success('保存成功');
					this.searchBtn(1);
				} else {
					this.$message.destroy();
					let errorList = res.ret_msg.split('<br/>');
					errorList = errorList.filter((t) => t != null && t != '');
					let doms = [];
					errorList.forEach((item) => {
						doms.push(h('p', item));
					});
					console.log(errorList);
					this.$modal.error({
						getContainer: () => this.$refs.proxyConfig_warp,
						title: '提示',
						content: h('div', {}, [doms]),
					});
				}
			},
			async bindProxyConfigList() {
				let res = await userApi.GetProxyConfigList();
				if (res.ret_code === 200) {
					this.datatable.dataSource = res.ret_obj;
				}
			},
			//获取客服图片
			async GetProxyService() {
				let res = await userApi.GetProxyService();
				if (res.ret_code === 200) {
					if (res.ret_obj) {
						this.imageUrl = res.ret_obj.cust_img;
					}
				}
			},
			//添加客服图片
			async AddCoustomService(cust_img) {
				let param = {};
				param.cust_img = cust_img;
				let res = await userApi.AddCoustomService(param);
				if (res.ret_code === 200) {
					this.$message.success('添加成功');
				}
			},
			beforeUpload(file) {
				console.log(file);
			},
			async handleChange(info) {
				console.log(info);
				if (info.file.status === 'uploading') {
					this.loading = true;
					return;
				}
				if (info.file.status === 'done') {
					if (info.file.response.ret_code === 200) {
						this.imageUrl = info.file.response.ret_obj;
						this.loading = false;
						await this.AddCoustomService(this.imageUrl);
					}
				}
				if (info.file.status === 'error') {
					this.loading = false;
					this.$message.error('upload error');
				}
			},
			getBase64(img, callback) {
				const reader = new FileReader();
				reader.addEventListener('load', () => callback(reader.result));
				reader.readAsDataURL(img);
			},
		},
	};
</script>

<style scoped lang="scss">
	.proxyConfig_warp {
		padding-bottom: 3%;

		:deep(.ant-input-number-handler-wrap) {
			display: none;
		}

		:deep(.ant-input-number-input) {
			text-align: center;
		}

		:deep(.ant-modal-content) {
			border-radius: 12px;
		}

		.proxyConfig_setting {
			margin: 2% 15%;
			width: 70%;
			min-height: 400px;
			border: 3px solid #0086f5;
			border-radius: 8px;

			.proxyConfig_setting_divButton {
				text-align: center;
				margin-bottom: 1rem;

				button {
					font-size: 18px;
					width: 6rem;
					height: 3rem;
					font-weight: 600;
				}
			}

			span {
				display: flow-root;
				text-align: center;
				line-height: 36px;
				color: #0086f5;
				font-size: 16px;
				font-weight: 600;
			}
		}

		.proxyConfig_warp_service {
			margin: 2% 15%;
			width: 70%;
			height: 30%;
			min-height: 300px;
			border: 3px solid #0086f5;
			border-radius: 8px;

			span {
				display: flow-root;
				text-align: center;
				line-height: 36px;
				color: #0086f5;
				font-size: 16px;
				font-weight: 600;
			}
		}
	}
</style>
