<template>
  <div class="contentWarp" ref="invateIndex_warp">
    <div class="content_header">
      <div v-for="(item, index) in headers" v-bind:key="index" class="header" :style="{
        width: 100 / headers.filter((t) => t.show == true).length + '%',
      }" v-show="item.show">
        <div class="header_item" :style="{ background: item.background }"></div>
        <div style="display: inline-block">
          <div class="header_item_1" v-if="item.key === 'TotalChildrenUserCount'">
            {{ TotalChildrenUserCount.toFixed(2) }}
          </div>
          <div class="header_item_1" v-else-if="item.key === `TodayChildrenUserCount`">
            {{ TodayChildrenUserCount.toFixed(2) }}
          </div>
          <div class="header_item_1" v-else-if="item.key === 'AgenCount'">
            {{ AgenCount.toFixed(2) }}
          </div>
          <div class="header_item_1" v-else-if="item.key === 'Subordinate account'">
            {{ SubordinateNum.toFixed(2) }}{{ item.unit }}
          </div>
          <div class="header_item_2">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <!-- 搜索模块 -->
    <div class="search_bar">
      <div class="search_bar_item">
        <div class="search_bar_item_label">手机号码：</div>
        <div class="">
          <a-input v-model:value="searchModel.username" placeholder="手机号码" />
        </div>
      </div>
      <div class="search_bar_item">
        <div class="search_bar_item_label">邮箱：</div>
        <div class="">
          <a-input v-model:value="searchModel.email" placeholder="邮箱" />
        </div>
      </div>
      <div class="search_bar_item_button">
        <button class="button_blue" @click="searchBtn(1)">搜索</button>
        <button class="button_red button_right" @click="showCreateUserModal(true)">
          添加用户
        </button>
      </div>
    </div>
    <div class="content_body">
      <comm-table ref="commTab" :columns="datatable.columns" :dataSource="datatable.dataSource" :tabDataBind="tabDataBind"
        :rowKey="(record) => record.id" :total="datatable.total">
        <template #cell="{ column, record }">
          <template v-if="column.key === 'operate'">
            <a-button style="border-radius: 15px; margin-right: 0.7rem" type="primary"
              @click="showAmountTransferModal(record.username)">代充余额</a-button>
            <a-button v-if="record.user_status === 1" style="border-radius: 15px; margin-right: 0.7rem" type="primary"
              @click="UpdateState(0, record.userid)">停用</a-button>
            <a-button v-else style="border-radius: 15px; margin-right: 0.7rem" type="primary"
              @click="UpdateState(1, record.userid)">启用</a-button>
            <a-button v-if="record.is_agent !== '战略代理' && (IsAgent === 1 || IsAgent === 2)"
              style="border-radius: 15px; margin-right: 0.7rem" type="primary" @click="
                showAgentProportionModal(
                  record.username,
                  record.share_proportion
                )
                ">返点比例</a-button>
          </template>
        </template>
      </comm-table>
    </div>
    <amount-transfer-modal v-model:visible="amountTransferMData.visible" @changeVisible="changeVisible"
      :mobile="amountTransferMData.mobile" @submit="onSubmitAmountTransfer">
    </amount-transfer-modal>
    <agent-proportion-modal v-model:visible="agentProportionData.visible" @changeVisible="agentProportionChangeVisible"
      :mobile="agentProportionData.mobile" :shareProportion="agentProportionData.shareProportion"
      @submit="onSubmitAgentProportion">
    </agent-proportion-modal>
  </div>
  <create-user-modal v-model:visible="createUserModalData.isShow" @changeVisible="showCreateUserModal">
  </create-user-modal>
</template>

<script>
import "@/css/searchBar.css";
import commTable from "@/components/table/commTable.vue";
import gsap from "gsap";
import menuUtil from "@/common/menuUtil";
import userApi from "@/http/apiUtil/userApi";
import amountTransferModal from "@/components/invate/amountTransferModal.vue";
import agentProportionModal from "@/components/invate/agentProportionModal.vue";
import createUserModal from "@/components/user/createUserModal.vue";

export default {
  components: {
    amountTransferModal,
    commTable,
    agentProportionModal,
    createUserModal,
  },
  props: {},
  data() {
    return {
      searchModel: {
        username: "",
        email: "",
      },
      amountTransferMData: {
        mobile: "",
        visible: false,
      },
      createUserModalData: {
        isShow: false,
      },
      agentProportionData: {
        mobile: "",
        visible: false,
        shareProportion: 0,
      },
      TotalChildrenUserCount: 0,
      TodayChildrenUserCount: 0,
      AgenCount: 0,
      IsAgent: 0,
      headers: [
        {
          title: "总账户数",
          key: "TotalChildrenUserCount",
          background: "linear-gradient(180deg, #FFBCB0 0%, #FF8778 100%)",
          show: true,
        },
        {
          title: "今日新增账户数",
          key: "TodayChildrenUserCount",
          background: "linear-gradient(360deg, #44D7B6 0%, #C3E9D5 100%)",
          show: true,
        },
        {
          title: "分销代理数",
          key: "AgenCount",
          background: "linear-gradient(150deg, #83CBFF 0%, #007AFF 100%)",
          show: false,
        },
      ],
      datatable: {
        total: 0,
        dataSource: [],
        columns: [
          {
            title: "用户编号",
            dataIndex: "user_key",
            key: "user_key",
          },
          {
            title: "手机号码",
            dataIndex: "username",
            key: "username",
          },
          {
            title: "账户等级",
            dataIndex: "is_agent",
            key: "is_agent",
          },

          {
            title: "账户金额",
            dataIndex: "user_amout",
            key: "user_amout",
          },
          {
            title: "用户状态",
            dataIndex: "user_status",
            key: "user_status",
            customRender: (obj) => {
              let value = menuUtil.getMenuDesc("UserState", obj.text);
              return value || obj.text;
            },
          },
          {
            title: "注册时间",
            dataIndex: "createtime",
            key: "createtime",
          },
          {
            title: "操作",
            dataIndex: "operate",
            key: "operate",
            width: "400px",
          },
        ],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    //查询
    async searchBtn(page) {
      this.$refs["commTab"].reset(page);
    },
    async tabDataBind(page = 1, size = 10, field, order) {
      console.log(`page:${page} ,size:${size},field:${field},order:${order}`);
      let param = {
        page: page,
        size: size,
      };
      param.SearchUserName = this.searchModel.username;
      param.SearchEmail = this.searchModel.email;
      var orderRes = await userApi.UserAgentList(param);
      if (orderRes.ret_code == 200) {
        gsap.to(this, {
          duration: 1.0,
          TotalChildrenUserCount: orderRes.ret_obj.TotalChildrenUserCount,
        });
        gsap.to(this, {
          duration: 1.0,
          TodayChildrenUserCount: orderRes.ret_obj.TodayChildrenUserCount,
        });
        gsap.to(this, {
          duration: 1.0,
          AgenCount: orderRes.ret_obj.AgenCount,
        });
        if (orderRes.ret_obj.IsAgent == 2) {
          //  this.headers.find((t) => t.key == "AgenCount").show = true;
        }
        if (orderRes.ret_obj.list.length > 0) {
          orderRes.ret_obj.list.forEach((item) => {
            item.user_amout = this.$util.formatPrice(item.user_amout);
            item.is_agent =
              item.is_agent == 0
                ? "普通用户"
                : item.is_agent == 1
                  ? "战略代理"
                  : (item.is_agent == 2 ? "分销代理" : "普通代理");
            item.createtime = this.$dayjs(item.createtime).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          });
        }
        this.datatable.dataSource = orderRes.ret_obj.list;
        this.datatable.total = orderRes.ret_count;
      }
    },
    showCreateUserModal(flag) {
      this.createUserModalData.isShow = flag;
      if (!flag) {
        this.searchBtn(1);
      }
    },
    //修改用户状态
    UpdateState(userState, userId) {
      this.$modal.confirm({
        content: `是否【${userState ? "启用" : "停用"}】该用户？`,
        getContainer: () => this.$refs.invateIndex_warp,
        // icon: createVNode(ExclamationCircleOutlined),
        onOk: async () => {
          let param = {};
          param.UserState = userState;
          param.UserId = userId;
          let res = await userApi.UpdateState(param);
          if (res.ret_code === 200) {
            this.$message.success("修改成功");
            this.searchBtn(1);
          }
        },
        cancelText: "取消",
        onCancel() { },
      });
    },
    async onSubmitAmountTransfer(model) {
      let res = await userApi.AmountTransfer(model);
      if (res.ret_code == 200) {
        this.$message.success(res.ret_msg);
        this.amountTransferMData.visible = false;
        this.searchBtn(1);
      }
    },
    async onSubmitAgentProportion(model) {
      let res = await userApi.AgentProportionSettings(model);
      if (res.ret_code == 200) {
        this.$message.success(res.ret_msg);
        this.agentProportionChangeVisible(false);
        this.searchBtn(1);
      }
      console.log(model);
    },
    showAmountTransferModal(mobile) {
      this.amountTransferMData.visible = true;
      this.amountTransferMData.mobile = mobile;
    },
    showAgentProportionModal(mobile, shareProportion) {
      this.agentProportionData.visible = true;
      this.agentProportionData.mobile = mobile;
      this.agentProportionData.shareProportion = shareProportion;
    },
    changeVisible(flag) {
      this.amountTransferMData.visible = flag;
    },
    agentProportionChangeVisible(flag) {
      this.agentProportionData.visible = flag;
    },
    async getUserIsAgent() {
      var res = await userApi.UserIsAgent();
      if (res.ret_code == 200) {
        this.IsAgent = res.ret_obj;
      }
    },
  },
  created() { },
  async mounted() {
    this.searchBtn(1);
    await this.getUserIsAgent();
  },
};
</script>

<style lang="scss" scoped>
.contentWarp {
  width: 100%;
  height: 100%;
  background-color: white;

  :deep(.ant-modal-content) {
    border-radius: 12px;
  }

  :deep(.ant-input) {
    min-width: 150px;
    min-height: 32px;
    border-radius: 6px;
  }

  :deep(.ant-modal-confirm-btns) {
    button {
      border-radius: 7px;
    }
  }

  .content_header {
    padding: 2%;
    display: flex;

    .header {
      .header_item {
        width: 10px;
        height: 72px;
        background: linear-gradient(180deg, #ffbcb0 0%, #ff8778 100%);
        border-radius: 4px;
        display: inline-block;
      }

      .header_item_1 {
        width: 130px;
        height: 50px;
        font-size: 36px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #0086f5;
        line-height: 50px;
        padding-left: 1rem;
      }

      .header_item_2 {
        width: 174px;
        height: 33px;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #0086f5;
        line-height: 33px;
        padding-left: 1rem;
        white-space: nowrap;
        /* overflow: hidden; */
        text-overflow: ellipsis;
      }
    }
  }

  .content_body {
    :deep(.ant-table-cell) {
      border-bottom: 1px solid rgba(0, 134, 245, 0.34);
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0086f5;
    }

    :deep(.ant-table-wrapper) {
      clear: both;
      max-width: 100%;
      padding: 0px 2% 0px 2%;
    }
  }
}

.button_right {
  position: absolute;
  right: 2rem;
}
</style>
