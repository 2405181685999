<template>
  <div class="contentWarp">
    <!-- 顶部页签模块 -->
    <div class="content_titleMenus">
      <router-link
        v-for="item in titleMenus"
        v-bind:key="item.key"
        :class="
          item.isSelected
            ? 'content_titleMenus_item_seleted'
            : 'content_titleMenus_item_unseleted'
        "
        :to="item.routerLink"
        @click="titleMenusOnClick(item.key)"
        >{{ item.title }}
      </router-link>
    </div>
    <!-- 数据统计模块 -->
    <div
      class="content_header"
      v-if="headers.show"
      v-show="titleMenus.find((t) => t.isSelected === true).key != 4"
    >
      <div
        v-for="(item, index) in headers.list"
        v-bind:key="index"
        class="header"
        :style="{
          width: 100 / headers.list.filter((t) => t.show == true).length + '%',
        }"
        v-show="item.show"
      >
        <div class="header_item" :style="{ background: item.background }"></div>
        <div style="display: inline-block">
          <div class="header_item_1">
            {{ item.value }}
          </div>
          <div class="header_item_2">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div
      class="content_header"
      v-else
      v-show="titleMenus.find((t) => t.isSelected === true).key != 4"
    >
      <div
        style="padding: 0px 100px; cursor: pointer"
        v-for="item in headers_tips.list"
        v-bind:key="item.key"
        @click="HeadTipsOnClick(item.key)"
        v-show="false"
      >
        <div
          :class="
            item.isSelected
              ? 'content_header_tips_seleted'
              : 'content_header_tips_unseleted'
          "
        >
          {{ item.title }}
        </div>
        <div :class="item.isSelected ? 'content_header_tips' : ''"></div>
      </div>
    </div>
    <!-- 搜索模块 -->
    <div
      class="search_bar"
      v-show="titleMenus.find((t) => t.isSelected === true).key != 4"
    >
      <div class="search_bar_item">
        <div class="search_bar_item_label">时间：</div>
        <div class="">
          <a-range-picker
            v-model:value="searchModel.time"
            format="YYYY-MM-DD"
          />
        </div>
      </div>
      <div class="search_bar_item_button">
        <button class="button_blue" @click="searchBtn(1)">搜索</button>
      </div>
      <div
        class="search_bar_item_button"
        v-show="titleMenus.find((t) => t.key == 1).isSelected"
        style="position: absolute; right: 5rem"
      >
        <button class="button_red" @click="showWithdraw()">提现</button>
      </div>
    </div>
    <!-- 数据表单模块 -->
    <div
      class="content_body"
      v-if="titleMenus.find((t) => t.isSelected === true).key != 4"
    >
      <comm-table
        ref="commTab"
        :columns="datatable.columns"
        :dataSource="datatable.dataSource"
        :tabDataBind="tabDataBind"
        :rowKey="(record) => record.id"
        :total="datatable.total"
      >
        <template #cell="{ column, record }">
          <template v-if="column.key === 'operate'">
            <a-button @click="showProxyInfo(record)" type="primary"
              >详情</a-button
            >
          </template>
        </template>
      </comm-table>
    </div>
    <div class="content_body" v-else>
      <router-view></router-view>
    </div>
    <withdraw-modal
      v-model:visible="visible1"
      @changeVisible="changeVisible1"
      :onsubmit="CreateUserBank"
    >
    </withdraw-modal>
    <withdraw-confirm-modal
      v-model:visible="visible2"
      @changeVisible="changeVisible2"
      :bank_branch="bankInfo.bank_branch"
      :bank_name="bankInfo.bank_name"
      :bank_no="bankInfo.bank_no"
      :bank_username="bankInfo.bank_username"
      :TotalAmount="modalTotalAmount"
      :onSubmit="withdrawalSubmit"
    >
    </withdraw-confirm-modal>
    <withdraw-info-modal
      v-model:visible="visible3"
      @changeVisible="changeVisible3"
      :date="date"
    >
    </withdraw-info-modal>
  </div>
</template>

<script>
import menuUtil from "@/common/menuUtil";
import commTable from "@/components/table/commTable.vue";
import userApi from "@/http/apiUtil/userApi.js";
import WithdrawModal from "@/components/withdraw/WithdrawModal.vue";
import WithdrawInfoModal from "@/components/withdraw/WithdrawInfoModal.vue";
import WithdrawConfirmModal from "@/components/withdraw/WithdrawConfirmModal.vue";
import "@/css/searchBar.css";
export default {
  components: {
    commTable,
    WithdrawModal,
    WithdrawConfirmModal,
    WithdrawInfoModal,
  },
  props: {},
  data() {
    return {
      visible1: false,
      visible2: false,
      visible3: false,
      date: "",
      aliPayAccount: "",
      aliPayName: "",
      bankInfo: {},
      modalTotalAmount: 0,
      searchModel: {
        time: null,
      },
      titleMenus: [
        {
          title: "返佣收益",
          key: 1,
          isSelected: true,
          routerLink: "/Index/earnings",
        },
        {
          title: "支出管理",
          key: 2,
          isSelected: false,
          routerLink: "/Index/earnings",
        },
        {
          title: "提现记录",
          key: 3,
          isSelected: false,
          routerLink: "/Index/earnings",
        },
        {
          title: "代理配置",
          key: 4,
          isSelected: false,
          routerLink: "/Index/earnings/proxyConfig",
        },
      ],
      headers: {
        show: true,
        list: [],
      },
      headers_tips: {
        list: [
          {
            title: "自主提现",
            key: 1,
            isSelected: true,
          },
          {
            title: "代理提现",
            key: 2,
            isSelected: false,
          },
        ],
      },
      datatable: {
        dataSource: [],
        columns: [],
        total: 0,
      },
    };
  },
  watch: {},
  computed: {},
  async mounted() {
    this.titleMenusOnClick(1);
    // this.searchBtn(1);
  },
  methods: {
    showProxyInfo(obj) {
      console.log(obj);
      this.date = obj.createtime;
      this.changeVisible3(true);
    },
    //创建提现资料
    async CreateUserBank(param) {
      let res = await userApi.CreateUserBank(param);
      if (res.ret_code === 200) {
        this.$message.success("新增成功");
        this.changeVisible1(false);
        this.showWithdraw();
      }
    },
    async withdrawalSubmit(param) {
      console.log(param);
      let res = await userApi.CreateWithdrawal();
      if (res.ret_code === 200) {
        this.$message.success("申请提现成功，请等待审核");
        this.changeVisible2(false);
      }
    },
    //提现按钮
    async showWithdraw() {
      let resBankInfo = await userApi.GetUserBankInfo();
      if (resBankInfo.ret_code === 200) {
        if (resBankInfo.ret_obj.exist) {
          this.changeVisible2(true);
          let res = await userApi.GetWithdrawTotalAmount();
          if (res.ret_code === 200) {
            this.modalTotalAmount = this.$util.formatPrice(
              res.ret_obj.total_amount
            );
          }

          this.bankInfo = resBankInfo.ret_obj.details;
        } else {
          this.changeVisible1(true);
        }
      }
    },
    changeVisible1(flag) {
      this.visible1 = flag;
    },
    changeVisible2(flag) {
      this.visible2 = flag;
    },
    changeVisible3(flag) {
      this.visible3 = flag;
    },
    HeadTipsOnClick(key) {
      this.headers_tips.list.forEach((item) => {
        item.isSelected = false;
        if (item.key === key) {
          item.isSelected = true;
        }
      });
    },
    //查询
    async searchBtn(page) {
      this.$refs["commTab"].reset(page);
    },
    async tabDataBind(page = 1, size = 10, field, order) {
      console.log(`${page} ,${size},${field},${order}`);
      let key = this.titleMenus.find((t) => t.isSelected === true).key;
      let param = {};
      param.page = page;
      param.size = size;
      param.begin_time = null;
      param.end_time = null;
      if (this.searchModel.time != null) {
        param.begin_time = this.$dayjs(this.searchModel.time[0]).format(
          "YYYY-MM-DD"
        );
        param.end_time = this.$dayjs(this.searchModel.time[1]).format(
          "YYYY-MM-DD"
        );
      }
      if (key === 1) {
        this.bindProxyBenefitData(param);
      } else if (key == 2) {
        this.bindConsumeData(param);
      } else if (key == 3) {
        this.bindUserWithdrawalData(param);
      }
    },
    //绑定返佣收益列表
    async bindProxyBenefitData(param) {
      let res = await userApi.GetProxyBenefit(param);
      if (res.ret_code == 200) {
        this.datatable.dataSource = res.ret_obj.details;
        this.headers.list.find((t) => t.key === "total_amount").value =
          res.ret_obj.statics.total_amount;
        this.headers.list.find((t) => t.key === "today_amount").value =
          res.ret_obj.statics.today_amount;
        this.datatable.total = res.ret_count;
      }
    },
    //绑定支出列表
    async bindConsumeData(param) {
      let res = await userApi.GetConsumeList(param);
      if (res.ret_code == 200) {
        res.ret_obj.details.forEach((item) => {
          item.pay_amout = this.$util.formatPrice(item.pay_amout);
          item.pay_type = menuUtil.getMenuDesc("PayTypeMenu", item.pay_type);
          item.createtime = this.$dayjs(item.createtime).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        });
        this.datatable.dataSource = res.ret_obj.details;
        this.headers.list.find((t) => t.key === "PayAmount").value =
          this.$util.formatPrice(res.ret_obj.statics.PayAmount);
        this.headers.list.find((t) => t.key === "TodayPayAmount").value =
          this.$util.formatPrice(res.ret_obj.statics.TodayPayAmount);
        this.datatable.total = res.ret_count;
      }
    },
    //绑定自主提现列表
    async bindUserWithdrawalData(param) {
      let res = await userApi.GetUserWithdrawal(param);
      if (res.ret_code == 200) {
        res.ret_obj.details.forEach((item) => {
          item.withdrawal_amout = this.$util.formatPrice(item.withdrawal_amout);
          item.createtime = this.$dayjs(item.createtime).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        });
        this.datatable.dataSource = res.ret_obj.details;
        this.datatable.total = res.ret_count;
      }
    },
    //页面顶部标签点击事件
    async titleMenusOnClick(key) {
      this.titleMenus.forEach((item) => {
        item.isSelected = false;
        if (item.key === key) {
          item.isSelected = true;
        }
      });
      if (key == 1) {
        await this.bindRebateConfig();
      } else if (key == 2) {
        await this.bindConsumeConfig();
      } else if (key == 3) {
        await this.bindWithdrawConfig();
      }

      //代理配置拆分了页面
      if (key != 4) {
        this.searchBtn(1);
      }
    },
    //返佣收益
    bindRebateConfig() {
      this.datatable.columns = [
        {
          title: "时间",
          dataIndex: "createtime",
          key: "createtime",
        },
        {
          title: "充值金额",
          dataIndex: "recharge_amout",
          key: "recharge_amout",
        },
        {
          title: "返佣金额",
          dataIndex: "agent_amout",
          key: "agent_amout",
        },
        {
          title: "差额收益",
          dataIndex: "margin_amout",
          key: "margin_amout",
        },

        {
          title: "操作",
          dataIndex: "operate",
          key: "operate",
        },
      ];
      this.headers.show = true;
      this.headers.list = [
        {
          title: "总收益",
          key: "total_amount",
          value: 0,
          background: "linear-gradient(180deg, #FFBCB0 0%, #FF8778 100%)",
          show: true,
        },
        {
          title: "今日收益",
          key: "today_amount",
          value: 0,
          background: "linear-gradient(360deg, #44D7B6 0%, #C3E9D5 100%)",
          show: true,
        },
      ];
    },
    //支出管理
    bindConsumeConfig() {
      this.datatable.columns = [
        {
          title: "支出时间",
          dataIndex: "createtime",
          key: "createtime",
        },
        {
          title: "支出金额",
          dataIndex: "pay_amout",
          key: "pay_amout",
        },
        {
          title: "支出类型",
          dataIndex: "pay_type",
          key: "pay_type",
        },
      ];
      this.headers.show = true;
      this.headers.list = [
        {
          title: "总支出",
          key: "PayAmount",
          value: 0,
          background: "linear-gradient(180deg, #FFBCB0 0%, #FF8778 100%)",
          show: true,
        },
        {
          title: "今日支出",
          key: "TodayPayAmount",
          value: 0,
          background: "linear-gradient(360deg, #44D7B6 0%, #C3E9D5 100%)",
          show: true,
        },
      ];
    },
    //提现
    bindWithdrawConfig() {
      this.datatable.columns = [
        {
          title: "提现账户",
          dataIndex: "withdrawal_account",
          key: "withdrawal_account",
        },
        {
          title: "提现金额",
          dataIndex: "withdrawal_amout",
          key: "withdrawal_amout",
        },
        {
          title: "提现状态",
          dataIndex: "state",
          key: "state",
          customRender: (obj) => {
            let value = menuUtil.getMenuDesc("WithdrawalState", obj.text);
            return value || obj.text;
          },
        },
        {
          title: "提现时间",
          dataIndex: "createtime",
          key: "createtime",
        },
      ];
      this.headers.list = [];
      this.headers.show = false;
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.contentWarp {
  width: 100%;
  height: 100%;
  background-color: white;
  :deep(.ant-picker) {
    min-width: 150px;
    min-height: 32px;
    border-radius: 6px;
  }
  :deep(.ant-btn-primary) {
    border-radius: 7px;
  }
  // :deep(.ant-input) {
  //   min-width: 150px;
  //   min-height: 32px;
  //   background: rgba(216, 216, 216, 0);
  //   border-radius: 6px;
  //   border: 1px solid #979797;
  // }
  // :deep(.ant-select) {
  //   min-width: 150px;
  //   min-height: 32px;
  //   background: rgba(216, 216, 216, 0);
  //   border-radius: 6px;
  //   border: 1px solid #979797;
  // }
  :deep(.ant-input-number) {
    min-width: 150px;
    min-height: 32px;
    background: rgba(216, 216, 216, 0);
    border-radius: 6px;
    border: 1px solid #979797;
  }
  .content_titleMenus {
    padding: 0px 2%;
    display: flex;
    min-height: 68px;
    background-color: #daf1ffff;
    margin: 0px 2%;
    border-radius: 8px;
    align-items: center;
    .content_titleMenus_item_seleted {
      cursor: pointer;
      width: 128px;
      min-height: 36px;
      background: #0086f5;
      box-shadow: 0px 2px 4px 0px #0084f5;
      border-radius: 9px;
      text-align: center;
      line-height: 36px;
      color: white;
      margin-right: 2%;
    }
    .content_titleMenus_item_unseleted {
      cursor: pointer;
      width: 128px;
      min-height: 36px;
      border-radius: 9px;
      text-align: center;
      line-height: 36px;
      color: #0084f5;
      margin-right: 2%;
    }
  }

  .content_header {
    padding: 2%;
    display: flex;
    .content_header_tips_unseleted {
      width: 57px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #949494;
      line-height: 20px;
    }
    .content_header_tips_seleted {
      width: 57px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #0086f5;
      line-height: 20px;
    }
    .content_header_tips {
      width: 57px;
      height: 3px;
      background: #0086f5;
      box-shadow: 0px 2px 4px 0px rgba(0, 134, 245, 0.31);
      border-radius: 3px;
    }
    .header {
      .header_item {
        width: 10px;
        height: 72px;
        background: linear-gradient(180deg, #ffbcb0 0%, #ff8778 100%);
        border-radius: 4px;
        display: inline-block;
      }
      .header_item_1 {
        width: 130px;
        height: 50px;
        font-size: 36px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #0086f5;
        line-height: 50px;
        padding-left: 1rem;
      }
      .header_item_2 {
        width: 174px;
        height: 33px;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #0086f5;
        line-height: 33px;
        padding-left: 1rem;
        white-space: nowrap;
        /* overflow: hidden; */
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
