<template>
    <div class="m-rebateincome-wrap">
        <div class="wrap-summary">
            <div class="wrap-summary-item">
                <div class="p-red p"></div>
                <div>
                    <div>{{ statistics.total_amount }}￥</div>
                    <div>总收益</div>
                </div>
            </div>
            <div class="wrap-summary-item">
                <div class="p-green p"></div>
                <div>
                    <div>{{ statistics.today_amount }}￥</div>
                    <div>今日收益</div>
                </div>
            </div>

        </div>
        <div class="wrap-body">
            <!-- <div class="wrap-body-search">
                <div>订单搜索:</div>
                <a-input style="width:50%" placeholder="订单号" v-model:value="searchModel.orderNo"></a-input>
                <a-button type="primary" @click="search">搜索</a-button>
            </div> -->
            <div class="wrap-body-operate">
                <button class="button_red" @click="showWithdraw">提现</button>
            </div>
            <div class="wrap-body-list">
                <van-list v-model:loading="listConfig.loading" :finished="listConfig.finished" finished-text="没有更多了"
                    @load="getOrderList" v-model:error="listConfig.error" error-text="请求失败，点击重新加载">
                    <div class="wrap-body-list-item" v-for="(item, index) in table.data" :key="item.order_no"
                        :index="index">
                        <div class="item-rows">
                            <div>
                                <div>时间</div>
                                <div>{{ item.createtime }}</div>
                            </div>
                            <div>
                                <div>充值金额</div>
                                <div>{{ item.recharge_amout }}￥</div>
                            </div>
                            <div>
                                <div>返佣金额</div>
                                <div>{{ item.agent_amout }}￥</div>
                            </div>
                        </div>
                        <div class="item-rows">
                            <div>
                                <div>差额收益</div>
                                <div>{{ item.margin_amout }}￥</div>
                            </div>

                        </div>
                        <div class="item-rows">
                            <div>
                                <div>
                                    操作
                                </div>
                                <div>
                                    <a class="a-red" @click="showProxyInfo(item)">详情</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </van-list>

            </div>
        </div>
        <withdraw-modal v-model:visible="visible1" @changeVisible="changeVisible1" :onsubmit="CreateUserBank">
        </withdraw-modal>
        <withdraw-confirm-modal v-model:visible="visible2" @changeVisible="changeVisible2" :Account="aliPayAccount"
            :Name="aliPayName" :TotalAmount="modalTotalAmount" :onSubmit="withdrawalSubmit">
        </withdraw-confirm-modal>
        <withdraw-info-modal v-model:visible="visible3" @changeVisible="changeVisible3" :date="date">
        </withdraw-info-modal>
    </div>
</template>

<script>
import userApi from "@/http/apiUtil/userApi";
import util from "@/common/util";
import { List } from "vant";
import WithdrawModal from "@/components/withdraw/WithdrawModal.vue";
import WithdrawConfirmModal from "@/components/withdraw/WithdrawConfirmModal.vue";
import WithdrawInfoModal from "@/components/withdraw/WithdrawInfoModal.vue";
export default {
    name: '',
    mixins: [],
    components: {
        [List.name]: List,
        WithdrawModal,
        WithdrawConfirmModal,
        WithdrawInfoModal
    },
    props: {},
    data() {
        return {
            table: {
                page: 0,
                size: 20,
                data: [],
            },
            util: util,
            searchModel: {
                orderNo: null,
            },
            statistics: {},
            listConfig: {
                error: false,
                loading: false,
                finished: false,
            },
            visible1: false,
            visible2: false,
            visible3: false,
            modalTotalAmount: 0,
            aliPayName: null,
            aliPayAccount: null,
            date: null,
        }
    },
    computed: {},
    watch: {},
    created() { },
    async mounted() {
        this.$emit('onClickMenu', 1)

    },
    methods: {
        search() {
            this.table.data = [];
            this.getOrderList();
        },
        //获取列表
        async getOrderList() {
            let that = this.searchModel;
            console.log(that);
            this.table.page = this.table.page + 1;
            let param = {
                order_userid: this.$cookies.get("userid"),
                page: this.table.page,
                size: this.table.size,
            };
            // param.order_no = this.searchModel.orderNo;
            var res = await userApi.GetProxyBenefit(param);
            if (res.ret_code == 200) {
                this.listConfig.loading = false;
                if (res.ret_obj.details.length > 0) {
                    res.ret_obj.details.forEach((item) => {
                        this.table.data.push(item);
                    });
                } else {
                    this.listConfig.finished = true;
                }
                this.statistics = res.ret_obj.statics;
            }
        },
        //提现按钮
        async showWithdraw() {
            let resBankInfo = await userApi.GetUserBankInfo();
            if (resBankInfo.ret_code === 200) {
                if (resBankInfo.ret_obj.exist) {
                    this.changeVisible2(true);
                    let res = await userApi.GetWithdrawTotalAmount();
                    if (res.ret_code === 200) {
                        this.modalTotalAmount = this.$util.formatPrice(
                            res.ret_obj.total_amount
                        );
                    }
                    this.aliPayAccount = resBankInfo.ret_obj.details.alipay_name;
                    this.aliPayName = resBankInfo.ret_obj.details.alipay_no;
                } else {
                    this.changeVisible1(true);
                }
            }
        },
        changeVisible1(flag) {
            this.visible1 = flag;
        },
        changeVisible2(flag) {
            this.visible2 = flag;
        },
        changeVisible3(flag) {
            this.visible3 = flag;
        },
        //创建提现资料
        async CreateUserBank(param) {
            let res = await userApi.CreateUserBank(param);
            if (res.ret_code === 200) {
                this.$message.success("新增成功");
                this.changeVisible1(false);
                this.showWithdraw();
            }
        },
        async withdrawalSubmit(param) {
            console.log(param);
            let res = await userApi.CreateWithdrawal();
            if (res.ret_code === 200) {
                this.$message.success("申请提现成功，请等待审核");
                this.changeVisible2(false);
            }
        },
        showProxyInfo(obj) {
            console.log(obj);
            this.date = obj.createtime;
            this.changeVisible3(true);
        },
    }
}
</script>

<style scoped lang="scss">
.m-rebateincome-wrap {
    margin: 0px 0.5rem 0px 0.5rem;

    .wrap-summary {
        color: #0086F5;
        display: flex;
        justify-content: space-around;
        background: #E4F3FF;
        box-shadow: inset 0px 1px 3px 0px rgba(0, 134, 245, 0.51);
        border-radius: 8px;
        padding: 0.5rem 0px 0.5rem 0px;

        .wrap-summary-item {
            display: flex;


            .p {
                width: 5px;
                border-radius: 3px;
            }

            .p-red {
                background: linear-gradient(180deg, #FFB2B2 0%, #FF7A7A 100%);
            }

            .p-green {
                background: linear-gradient(360deg, rgb(68, 215, 182) 0%, rgb(195, 233, 213) 100%);
            }

            .p-blur {
                background: linear-gradient(150deg, rgb(131, 203, 255) 0%, rgb(0, 122, 255) 100%);
            }
        }

        .wrap-summary-item>div:last-child {
            margin-left: 0.5rem;
        }
    }

    .wrap-body {
        .wrap-body-search {
            display: flex;
            justify-content: space-around;
            padding: 0.5rem 0px;
            align-items: center;
        }

        .wrap-body-operate {
            display: flex;
            padding: 0.5rem 0px;

            button {
                margin-right: 1rem;
            }
        }

        .wrap-body-list {


            .wrap-body-list-item {
                margin-bottom: 1rem;
                color: #0091FFFF;
                background: #E4F3FF;
                border-radius: 8px;
                padding: 0.5rem;

                .item-rows {
                    display: flex;
                    justify-content: space-around;
                }

                .item-rows>div:not(:first-child) {
                    padding-left: 0.5rem;
                }

                .item-rows>div {
                    min-width: 33.3%;
                    flex-grow: 1;
                    word-break: break-all;
                    white-space: normal;
                }


            }

            .wrap-body-list-item>div:not(:first-child) {
                margin-top: 1rem;
            }
        }
    }
}

.button_red {
    cursor: pointer;
    width: 96px;
    height: 32px;
    background: linear-gradient(180deg, #FFB2B2 0%, #FF7A7A 100%);
    border-radius: 7px;
    border: 2px solid #FFB2B2;
    color: white;
}

.a-red {
    margin-right: 1rem;
    color: red;
    text-decoration: underline;
}
</style>
