<template>
    <div class="m-title-wrap">
        <div class="title-wrap-body">
            <div style="justify-content: start;padding-left: 0.5rem;">
                <router-link to="/mobile/home"><span class="icon-mobile-back icon"></span></router-link>
            </div>
            <div>
                <span class="title">{{ title }}</span>
            </div>
            <div style="justify-content: end;"> <span @click="changeVisible3(true)" class="icon-mobile-service icon"></span>
            </div>
        </div>
        <div style="height:3rem"></div>
        <service-modal v-model:visible="visible3" @changeVisible="changeVisible3">
        </service-modal>
    </div>
</template>

<script>
import serviceModal from "@/components/service/serviceModal.vue";
export default {
    name: '',
    mixins: [],
    components: {
        serviceModal
    },
    props: { title: { type: String, default: "" } },
    data() {
        return {
            visible3: false,
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
        changeVisible3(flag) {
            this.visible3 = flag;
        }
    }
}
</script>

<style scoped lang="scss">
.m-title-wrap {
    position: relative;

    .title-wrap-body {
        position: fixed;
        width: 100%;
        height: 3rem;
        background: #E5F3FF;
        display: flex;
        z-index: 999;


        div {
            width: 33%;
            display: grid;
            align-items: center;
            text-align: center;
        }

        .title {
            font-weight: 600;
            color: #0086F5;
            font-size: 1.5rem;

        }
    }
}
</style>
