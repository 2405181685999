import { postDataJson } from "../api";

function OrderList(param) {
    return postDataJson("/api/Order/OrderList", { param: param });
}

function OrderStatistics(param) {
    return postDataJson("/api/Order/OrderStatistics", { param: param });
}

function OrderInfo(param) {
    return postDataJson("/api/Order/OrderInfo", { param: param });
}

function LiveOrderPlace(param) {
    return postDataJson("/api/Order/LiveOrderPlace", { param: param });
}

function LiveDetection(param) {
    return postDataJson("/api/Order/LiveDetection", { param: param });
}

function batchLiveOrderPlace(param) {
    return postDataJson("/api/Order/batchLiveOrderPlace", { param: param });
}

function TkOrderCommentInfo(param) {
    return postDataJson("/api/Order/TkOrderCommentInfo", { param: param });
}

function OrderUnpause(param) {
    return postDataJson("/api/Order/OrderUnpause", { param: param });
}

function OrderPause(param) {
    return postDataJson("/api/Order/OrderPause", { param: param });
}

function OrderDelayTime(param) {
    return postDataJson("/api/Order/OrderDelayTime", { param: param });
}

function OrderModifyComments(param) {
    return postDataJson("/api/Order/OrderModifyComments", { param: param });
}

function OrderCommentsFrequency(param) {
    return postDataJson("/api/Order/OrderCommentsFrequency", { param: param });
}

function GetCommentsFrequencyList() {
    return postDataJson("/api/Order/GetCommentsFrequencyList");
}

function GetOrderExecuteNumber(param) {
    return postDataJson("/api/Order/GetOrderExecuteNumber", { param: param });
}

function OrderAbort(param) {
    return postDataJson("/api/Order/OrderAbort", { param: param });
}

function GetCountryList(param) {
    return postDataJson("/api/Order/GetCountryList", { param: param });
}

function GetConfigListByCountry(param) {
    return postDataJson("/api/Order/GetConfigListByCountry", { param: param });
}

function BuyAccountOrder(param) {
    return postDataJson("/api/Order/BuyAccountOrder", { param: param });
}

function GetShoppingAccountList(param) {
    return postDataJson("/api/Order/GetShoppingAccountList", { param: param });
}

function GetTieredPrice(param) {
    return postDataJson("/api/Order/GetTieredPrice", { param: param });
}
export default {
    OrderList,
    OrderStatistics,
    OrderInfo,
    LiveOrderPlace,
    LiveDetection,
    batchLiveOrderPlace,
    TkOrderCommentInfo,
    OrderUnpause,
    OrderPause,
    OrderDelayTime,
    OrderModifyComments,
    OrderCommentsFrequency,
    GetCommentsFrequencyList,
    GetOrderExecuteNumber,
    OrderAbort,
    GetCountryList,
    GetConfigListByCountry,
    BuyAccountOrder,
    GetShoppingAccountList,
    GetTieredPrice,
};