<template>
  <div class="content">
    <div class="banner" :style="{ backgroundImage: `url(` + banner + `)` }">
      <a-button type="primary" class="banner_btn"
        ><RouterLink to="/Login">{{
          this.$lang.GetLangByKey("SignIn")
        }}</RouterLink></a-button
      >
      <span class="banner_subTitle">{{
        this.$lang.GetLangByKey("AlreadyAccount")
      }}</span>
    </div>
    <div class="form">
      <div class="form_return">
        <RouterLink to="/Login"> <arrow-left-outlined /></RouterLink>
      </div>
      <div class="form_wrap">
        <div class="form_title">
          <div class="form_title_logo">
            <img :src="formIcon" alt="log" />
            <div class="form_title_logo_text"></div>
          </div>
          <div class="form_title_wellcome">
            {{ this.$lang.GetLangByKey("CreateAccount") }}
          </div>
          <div class="form_title_continue"></div>
        </div>
        <div class="form_context">
          <a-form :hideRequiredMark="true" layout="vertical">
            <a-form-item
              :label="this.$lang.GetLangByKey('Email')"
              v-bind="validateInfos.email"
            >
              <a-input
                v-model:value="dataModel.email"
                :placeholder="this.$lang.GetLangTipsByKey('LoginEmail')"
                @blur="validate('email', { trigger: 'blur' }).catch(() => {})"
              />
            </a-form-item>
            <a-form-item
              :label="this.$lang.GetLangByKey('Mobile')"
              v-bind="validateInfos.name"
            >
              <a-input
                v-model:value="dataModel.name"
                :placeholder="this.$lang.GetLangTipsByKey('LoginMobile')"
                @blur="validate('name', { trigger: 'blur' }).catch(() => {})"
              />
            </a-form-item>
            <a-form-item
              :label="this.$lang.GetLangByKey('Password')"
              v-bind="validateInfos.password"
              class="form_context_pwd"
            >
              <a-input-password
                :placeholder="this.$lang.GetLangTipsByKey('LoginPwd')"
                v-model:value="dataModel.password"
                @blur="
                  validate('password', { trigger: 'blur' }).catch(() => {})
                "
              />
            </a-form-item>
          </a-form>
        </div>
        <div class="submit">
          <a-button type="primary" @click="onSubmit">{{
            this.$lang.GetLangByKey("Submit")
          }}</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive } from "vue";
import { Form, message } from "ant-design-vue";
import { ArrowLeftOutlined } from "@ant-design/icons-vue";
import lang from "@/lang/index";
import userApi from "@/http/apiUtil/userApi";
const useForm = Form.useForm;
export default defineComponent({
  setup() {
    const banner = ref(require("@/assets/account/RegisterBanner.png"));
    const formIcon = ref(require("@/assets/account/TitleIcon.png"));
    const googleAccountIcon = ref(
      require("@/assets/account/GoogleAccountLog.png")
    );
    const facebookAccountIcon = ref(
      require("@/assets/account/FacebookAccountLog.png")
    );
    const dataModel = reactive({
      email: "",
      name: "",
      password: "",
    });
    let validateEmail = async (_rule, value) => {
      let pattEmail =
        /^[A-Za-z0-9\u4e00-\u9fa5.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (value === "") {
        return Promise.reject(lang.GetLangTipsByKey("LoginEmail"));
      } else if (!pattEmail.test(value)) {
        return Promise.reject("请输入正确的邮箱格式");
      } else {
        return Promise.resolve();
      }
    };
    let validateMobile = async (_rule, value) => {
      let patt = /^1[0-9]{10}$/;
      if (value === "") {
        return Promise.reject(lang.GetLangTipsByKey("LoginMobile"));
      } else if (!patt.test(value)) {
        return Promise.reject("请输入正确的手机号码格式");
      } else {
        return Promise.resolve();
      }
    };

    const rulesRef = reactive({
      email: [
        {
          required: true,
          // message: lang.GetLangTipsByKey("LoginEmail"),
          trigger: "blur",
          validator: validateEmail,
        },
      ],
      password: [
        {
          required: true,
          message: lang.GetLangTipsByKey("LoginPwd"),
          trigger: "blur",
        },
      ],
      name: [
        {
          validator: validateMobile,
          required: true,
          // message: lang.GetLangTipsByKey("LoginMobile"),
          trigger: "blur",
        },
      ],
    });
    const { resetFields, validate, validateInfos } = useForm(
      dataModel,
      rulesRef
    );
    const onSubmit = () => {
      validate()
        .then(async () => {
          let param = {
            username: dataModel.name,
            userpwd: dataModel.password,
            email: dataModel.email,
            host: window.location.host,
          };
          console.log(param);

          let res = await userApi.RegisterUser(param);
          if (res.ret_code == 200) {
            message.success(res.ret_msg);
            window.location.href = "/#/Login";
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    };

    return {
      rulesRef,
      banner,
      formIcon,
      validate,
      validateInfos,
      resetFields,
      onSubmit,
      dataModel,
      googleAccountIcon,
      facebookAccountIcon,
    };
  },
  components: { ArrowLeftOutlined },
  beforeUnmount() {
    document.removeEventListener("keydown", this.handleWatchEnter);
  },
  created() {
    document.addEventListener("keydown", this.handleWatchEnter);
  },
  methods: {
    handleWatchEnter(e) {
      var key = window.event ? e.keyCode : e.which;
      let length = window.location.hash.indexOf("?");
      if (length == -1) {
        length = window.location.hash.length;
      }
      if (
        key === 13 &&
        window.location.hash.substring(0, length).toLowerCase() ===
          "#/Register".toLowerCase()
      ) {
        this.onSubmit();
      }
    },
  },
  data() {
    return {};
  },
  mounted() {},
});
</script>

<style lang="scss" scoped>
.content {
  height: 100vh;
  display: flex;
}
.banner {
  width: 50%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  .banner_btn {
    width: 260px;
    height: 55px;
    border-radius: 25px;
    position: absolute;
    line-height: 45px;
    bottom: 100px;
    background-color: #373948;
    border-color: #373948;
  }
  .banner_subTitle {
    position: absolute;
    bottom: 70px;
  }
}
.form {
  padding: 30px;
}
.form_return {
  font-size: 25px;
  a {
    color: #373948;
  }
}
.form_wrap {
  margin-top: 28px;
  padding: 0px 50px;
  .form_title {
    .form_title_logo {
      display: flex;
      img {
        height: 41px;
        width: 64px;
      }
      .form_title_logo_text {
        margin-left: 31px;
        font-size: 23px;
        color: #1a110f;
      }
    }
    .form_title_wellcome {
      margin-top: 45px;
      font-size: 28px;
      color: #1a110f;
    }
    .form_title_continue {
      font-size: 14px;
      color: #877d7a;
      line-height: 17px;
      margin-top: 21px;
    }
  }
  .form_context {
    margin-top: 50px;
    ::v-deep {
      .ant-form-item-label label {
        color: #1a110f;
        font-size: 14px;
        line-height: 17px;
      }
      .ant-form-item-control-input-content input {
        padding: 0px 30px;
        width: 512px;
        height: 65px;
        background: #ffffff;
        border-radius: 25px;
        border: 1px solid #dadada;
        font-size: 22px;
      }
    }
    .form_context_pwd {
      ::v-deep {
        .ant-input-affix-wrapper {
          border: 0px;
          padding: 0px;
          input {
            outline: invert;
          }
          .ant-input-suffix {
            position: absolute;
            top: 25px;
            right: 20px;
            font-size: 20px;
          }
        }
      }
    }
  }
  .submit {
    margin-top: 53px;
    ::v-deep {
      .ant-btn-primary {
        width: 512px;
        height: 65px;
        background: linear-gradient(180deg, #0086f5 0%, #46abff 100%);
        border-radius: 25px;
      }
    }
  }
}
</style>
