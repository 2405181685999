<template>
  <div class="m-login-wrap">
    <div class="m-login-wrap-body">
      <van-cell-group inset>
        <div class="login-wrap-body-title">
          <h2>注册</h2>
        </div>
        <van-field
          v-model="model.email"
          label="邮箱"
          placeholder="请输入邮箱"
          :error-message="errorMsg.emailMsg"
        />
        <van-field
          v-model="model.username"
          label="手机号码"
          placeholder="请输入手机号码"
        />
        <van-field
          v-model="model.password"
          :type="!showPwd ? 'password' : 'text'"
          label="密码"
          placeholder="请输入密码"
          :right-icon="showPwd ? 'smile-o' : 'closed-eye'"
          @click-right-icon="showPwdClick()"
          :error-message="errorMsg.pwdMsg"
        />
        <van-field
          v-model="model.confirmPwd"
          :type="!showConfirmPwd ? 'password' : 'text'"
          label="确认密码"
          placeholder="请再次输入密码"
          :right-icon="showConfirmPwd ? 'smile-o' : 'closed-eye'"
          @click-right-icon="showConfirmClick()"
          :error-message="errorMsg.confirmPwdMsg"
          :formatter="clearConfirmPwd"
        />
        <div class="login-wrap-body-forget">
          <div class="left"><a></a></div>
          <div class="right">
            <router-link to="/Mobile/Login">已有账户？登录</router-link>
          </div>
        </div>
        <van-button
          type="primary"
          @click="OnSubmit"
          class="login-wrap-body-button"
          >登录</van-button
        >
      </van-cell-group>
    </div>
  </div>
</template>

<script>
import userApi from "@/http/apiUtil/userApi";
import { Button, Field, CellGroup } from "vant";
import "vant/lib/index.css";
export default {
  name: "",
  mixins: [],
  components: {
    [Button.name]: Button,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
  },
  props: {},
  data() {
    return {
      model: {
        username: null,
        email: null,
        password: null,
        confirmPwd: null,
      },
      showPwd: false,
      showConfirmPwd: false,
      errorMsg: {
        emailMsg: null,
        pwdMsg: null,
        confirmPwdMsg: null,
      },
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    showPwdClick() {
      this.showPwd = !this.showPwd;
    },
    showConfirmClick() {
      this.showConfirmPwd = !this.showConfirmPwd;
    },
    verify() {
      let flag = false;
      return flag;
    },
    clearConfirmPwd(value) {
      this.errorMsg.confirmPwdMsg = null;
      return value;
    },
    async OnSubmit() {
      if (
        !/^[A-Za-z0-9\u4e00-\u9fa5.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
          this.model.email
        )
      ) {
        this.$message.error("邮箱格式不正确");
        return;
      }
      if (!/^1[0-9]{10}$/.test(this.model.username)) {
        this.$message.error("手机号码格式不正确");
        return;
      }
      if (!this.model.password || !this.model.confirmPwd) {
        this.$message.error("请输入密码");
        return;
      }
      if (this.model.password != this.model.confirmPwd) {
        this.$message.error("俩次密码输入不一致");
        return;
      }
      let param = {
        username: this.model.username,
        userpwd: this.model.password,
        email: this.model.email,
        host: window.location.host,
      };
      console.log(param);
      let res = await userApi.RegisterUser(param);
      if (res.ret_code == 200) {
        this.$message.success(res.ret_msg);
        window.location.href = "/#/mobile/Login";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.m-login-wrap {
  background: #eff2f5;
  height: 100vh;
  display: grid;
  align-items: center;

  .m-login-wrap-body {
    text-align: center;

    .login-wrap-body-title {
      h2 {
        font-weight: 600;
      }
    }

    .login-wrap-body-forget {
      display: flex;

      .left {
        text-align: left;
        width: 50%;
        margin-left: 0.5rem;
      }

      .right {
        text-align: right;
        width: 50%;
        margin-right: 0.5rem;
      }
    }

    .login-wrap-body-button {
      margin: 1rem 0px 1rem 0px;
    }
  }
}
</style>
