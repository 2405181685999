<template>
    <div class="m-shoppingIndex-wrap" ref="m_shoppingIndex_wrap">
        <mobile-title title="购买账号"></mobile-title>
        <div class="shoppingIndex-wrap-menu">
            <div class="menu" @click="showCountry(true)">
                <span>国家选择 -> 商品选择</span>
                <span>{{ `〉` }}</span>
            </div>
            <!-- <div class="menu disable" @click="showGoods(true)">
                <span>商品选择</span>
                <span>{{ `〉` }}</span>
            </div> -->
            <div class="title">
                <span>账号购买</span>
                <span></span>
            </div>
            <div class="shoppingIndex-wrap-order">
                <div class="order-account" v-for="item in orderList" v-bind:key="item.config_id">
                    <div class="tips">{{ item.country_name }}</div>
                    <div class="order-account-top">
                        <div>
                            {{ item.config_name.substr(0, item.config_name.indexOf('[')) }}
                            <span :style="`${item.config_name.indexOf('邮箱') > 0 ? 'color:#43CF7C' : 'color:red'}`">{{
                                item.config_name.substr(item.config_name.indexOf('[')) }}</span>
                        </div>
                    </div>
                    <div class="order-account-bottom">
                        <div>单价：{{ util.format1000Price(item.config_price) }}￥</div>
                        <div style="display: flex">
                            <i class="icon icon-down" style="height: 32px;width:32px;margin-right: 0rem;"
                                @click="down(item.config_code)"></i>
                            <a-input-number style="border-radius: 2px;" @change="staticAmount"
                                v-model:value="item.order_number"></a-input-number>
                            <i class="icon icon-up" style="height: 32px;width:32px;margin-right: 0rem;"
                                @click="up(item.config_code)"></i>
                        </div>
                        <div><i class="icon icon-delete-red" @click="onClickDeleteGoods(item.config_code)"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shoppingIndex-wrap-info">
            <div class="info">
                <span>总金额：{{ totalAmount }}￥</span>
                <a-button type="primary" style="background: linear-gradient(39deg, #00A4FA 0%, #006BF2 100%);"
                    @click="Order">下单</a-button>
            </div>

        </div>
        <common-popup :is_show="countryPopupModel.showCountryPopup" @changeVisible="showCountry" @closed="resetCountryModel"
            :style="{ height: '95%', 'overflow-x': 'auto', }" :title="countryPopupModel.title">
            <template #modalBody>
                <div class="country-popup-wrap">
                    <div class="search">
                        <div>国家：</div>
                        <div><a-input placeholder="请输入国家名称" style="border-radius: 6px;"
                                v-model:value="countrySearch.country_name"></a-input></div>
                        <div><a-button type="primary" style="border-radius: 6px;" @click="GetCountryList">搜索</a-button>
                        </div>
                    </div>
                    <div class="country-list">
                        <div class="country" v-for="item in countryList" v-bind:key="item.country_code"
                            @click="GetConfigListByCountry(item.country_code)">
                            <a-image style="width:67px;height:51px" :src="item.country_logo"></a-image>
                            <div class="country-name">{{ item.country_name }}（+{{ item.country_code }}）</div>
                        </div>
                    </div>
                </div>
            </template>
        </common-popup>
        <common-popup :is_show="goodsPopupModel.showGoodsPopup" @changeVisible="showGoods" @closed="resetGoodsModel"
            :style="{ height: '95%', 'overflow-x': 'auto', }" :title="goodsPopupModel.title">
            <template #modalBody>
                <div class="goods-popup-wrap">
                    <div class="goods-list">
                        <div class="goods" v-for="item in goodsList" v-bind:key="item.config_id"
                            @click="onClickGoods(item.config_code)">
                            <div>
                                {{ item.config_name.substr(0, item.config_name.indexOf('[')) }}
                                <span :style="`${item.config_name.indexOf('邮箱') > 0 ? 'color:#43CF7C' : 'color:red'}`">{{
                                    item.config_name.substr(item.config_name.indexOf('[')) }}</span>
                            </div>
                            <div>库存：<span :style="`${item.account_count > 0 ? 'font-size:1rem;color:red' : ''}`">{{
                                item.account_count }}</span>件</div>
                            <div>单价：{{ util.format1000Price(item.config_price) }}￥</div>
                        </div>
                    </div>
                </div>
            </template>
        </common-popup>
    </div>
</template>

<script>

import orderApi from "@/http/apiUtil/orderApi";
import mobileTitle from "@/components/mobile/title.vue";
import commonPopup from "@/components/mobile/commonPopup.vue";
import util from '@/common/util';
export default {
    name: '',
    mixins: [],
    components: {
        mobileTitle,
        commonPopup
    },
    props: {},
    data() {
        return {
            util: util,
            countryPopupModel: {
                showCountryPopup: false,
                title: '国家列表'
            },
            goodsPopupModel: {
                showGoodsPopup: false,
                title: '商品列表'
            },
            countryList: [],
            goodsList: [],
            orderList: [],
            countrySearch: {
                country_code: null,
                country_name: null,
            },
            totalAmount: 0,
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() {
        this.GetCountryList();
    },
    methods: {
        //获取商品地区分类
        async GetCountryList() {
            this.shoppingMenus = [];
            let param = {};
            param.country_name = this.countrySearch.country_name;
            let res = await orderApi.GetCountryList(param);
            if (res.ret_code === 200) {
                this.countryList = res.ret_obj;
            }
        },
        //获取商品
        async GetConfigListByCountry(config_country_code) {
            //  this.showCountry(false);
            this.showGoods(true);
            let country = this.countryList.find(t => t.country_code == config_country_code);
            this.goodsPopupModel.title = `（${country.country_name}）商品列表`;
            this.goodsList = [];
            let param = {};
            param.country_code = config_country_code;
            let res = await orderApi.GetConfigListByCountry(param);
            if (res.ret_code === 200) {
                this.goodsList = res.ret_obj;
            }
        },
        /**
        *商品点击
        */
        onClickGoods(config_code) {
            if (this.orderList.find(t => t.config_code === config_code)) {
                this.showCountry(false);
                this.showGoods(false);
                this.$message.error("购买列表已存在该商品");
                return;
            }

            let goods = this.goodsList.find(t => t.config_code === config_code);
            let country = this.countryList.find(t => t.country_code === goods.config_country_code);
            goods.order_number = 0;
            goods.country_name = country.country_name;
            this.orderList.push(goods);
            this.showCountry(false);
            this.showGoods(false);
        },
        /**
        *删除购买列表的商品
        */
        onClickDeleteGoods(config_code) {
            this.orderList = this.orderList.filter(item => item.config_code != config_code);
            this.staticAmount();
        },
        up(config_code) {
            let order = this.orderList.find(item => item.config_code == config_code);
            order.order_number += 1;
            this.staticAmount();
        },
        down(config_code) {
            let order = this.orderList.find(item => item.config_code == config_code);
            order.order_number -= 1;
            if (order.order_number < 0) {
                order.order_number = 0;
            }
            this.staticAmount();
        },
        /**
        *统计金额
        */
        staticAmount() {
            this.totalAmount = 0;
            this.orderList.forEach(item => {
                this.totalAmount += (item.order_number * item.config_price) / 1000;

            })
        },
        /**
      *下单
      */
        Order() {
            if (this.orderList.length == 0) {
                this.$message.error("请选择业务");
                return;
            }
            if (this.totalAmount == 0) {
                this.$message.error("请选择购买数量");
                return;
            }
            this.$modal.confirm({
                content: `本次下单金额：${this.totalAmount}￥。是否确认下单？`,
                getContainer: () => this.$refs.m_shoppingIndex_wrap,
                // icon: createVNode(ExclamationCircleOutlined),
                onOk: async () => {
                    let param = [];
                    this.orderList.forEach(item => {
                        param.push({
                            config_code: item.config_code,
                            robot_total_count: item.order_number
                        })
                    })
                    let res = await orderApi.BuyAccountOrder(param);
                    if (res.ret_code === 200) {
                        this.orderList = [];
                        this.totalAmount = 0;
                        this.$modal.confirm({
                            content: `下单成功，账号详细信息请前往【订单记录】查看账号信息`,
                            getContainer: () => this.$refs.m_shoppingIndex_wrap,
                            onOk: async () => {
                                window.location.href = "/#/Mobile/Order/OrderList";
                            },
                            cancelText: "关闭",
                            okText: "立即前往【订单记录】",
                            onCancel() { },
                        });

                    }
                },
                cancelText: "取消",
                onCancel() { },
            });
        },
        showCountry(flag) {
            this.countryPopupModel.showCountryPopup = flag;
        },
        showGoods(flag) {
            this.goodsPopupModel.showGoodsPopup = flag;
        },
        resetGoodsModel() {
        },
        resetCountryModel() { },
    }
}
</script>

<style scoped lang="scss">
.m-shoppingIndex-wrap {
    .shoppingIndex-wrap-menu {
        .menu {
            height: 4rem;
            display: flex;
            justify-content: space-around;
            align-items: center;
            border-bottom: 1px solid #d6d6d6FF;
            font-size: 1rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
        }

        .title {
            color: #808080;
            padding: 0.5rem 1rem;
        }

        .disable {
            background: #F8F8F8;

            color: rgba(0, 0, 0, 0.25);
        }
    }

    .shoppingIndex-wrap-info {
        position: absolute;
        bottom: 2rem;
        left: 10vw;

        .info {
            background: rgb(255, 255, 255);
            box-shadow: rgba(0, 130, 245, 0.19) 2px 2px 11px 0px;
            border-radius: 7px;
            border: 2px solid rgb(0, 129, 244);
            width: 80vw;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                margin-left: 2rem;
            }
        }
    }

    .shoppingIndex-wrap-order {
        height: calc(100vh - 18rem);
        overflow-y: auto;

        .order-account {
            position: relative;
            background: #E4F3FF;
            width: 90%;
            margin: 0px auto;
            padding: 1rem 0px;
            color: #0091FF;
            box-shadow: inset 0px 1px 8px 0px rgba(75, 174, 255, 0.5);
            border-radius: 8px;
            margin-bottom: 1rem;

            .tips {
                position: absolute;
                background: linear-gradient(180deg, #FFBEA7 0%, #FD896E 100%);
                border-radius: 0px 8px 0px 8px;
                margin-top: -1rem;
                right: 0;
                padding: 0.3rem 1rem;
                color: #FFFFFF;
            }

            .order-account-bottom {
                display: flex;
                justify-content: space-between;
                padding: 0px 1rem;
                align-items: center;
            }

            .order-account-top {
                padding: 0px 1rem;
                margin-bottom: 1rem;
            }
        }
    }

    .country-popup-wrap {
        // overflow-y: scroll;

        .search {
            color: #808080;
            display: flex;
            height: 4rem;
            justify-content: space-around;
            align-items: center;
            border-bottom: 1px solid #E1E1E1;
        }

        .country-list {
            // height: calc(100vh - 5vh - 3rem - 4rem);
            // overflow-y: auto;

            .country {
                height: 4.4rem;
                align-items: center;
                border-bottom: 1px solid #E1E1E1;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                display: flex;

                :deep(.ant-image) {
                    margin-left: 1rem;
                }

                .country-name {
                    margin: 0px auto;
                    align-items: center;
                    display: grid;
                }
            }
        }

    }

    .goods-popup-wrap {
        .goods-list {
            .goods {
                padding: 1rem 0px;
                border-bottom: 1px solid #E1E1E1;
                display: flex;
                justify-content: space-around;
            }
        }
    }
}
</style>
