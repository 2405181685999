<template>
  <div class="contentWarpModal" ref="agentProportionModal_warp">
    <a-modal v-model:visible="isShow" :title="title" :footer="null" :confirm-loading="false"
      :getContainer="() => this.$refs.agentProportionModal_warp" :afterClose="reset">
      <div id="button">
        <a-input-group compact class="contentGroup" style="">
          <span class="contentLabel" style="30%"> 手机号码</span>
          <a-input v-model:value="entity.mobile" style="
              width: 70%;
              border-radius: 15px;
              text-align: left;
              background-color: white;
              color: black;
            " placeholder="" disabled="disabled" />
        </a-input-group>
        <a-input-group compact class="contentGroup">
          <span class="contentLabel" style="30%">返点比例</span>
          <a-input-number v-model:value="entity.proportionNum" min="1" max="99"
            style="width: 150px; border-radius: 15px; text-align: left" placeholder="请输入返点比例"
            oninput="value=value.replace(/[^\d]/g, '')" />

          <span class="contentLabel"> %</span>
          <div style="display: block; font-size: 8px">
            下级比例不能设置比自己高
          </div>
        </a-input-group>
        <a-input-group compact class="buttonGroup" style="">
          <button style="border-radius: 8px" class="submitBtn" @click="onSubmit">
            确认
          </button>
        </a-input-group>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: {
    title: { type: String, default: "返点比例" },
    visible: { type: Boolean, default: false },
    shareProportion: { type: Number, default: 0 },
    mobile: {
      type: String,
      default: ``,
    },
    text: {
      type: String,
      default: ``,
    },
  },
  data() {
    return {
      isShow: false,
      entity: {
        mobile: "",
        proportionNum: null,
      },
    };
  },
  mounted() { },
  methods: {
    //利用afterClose将属性恢复到默认值（慎用，如果用户第二次点击同一个数据，将不会触发watch监听）
    reset() {
      //this.entity.proportionNum = null;
    },
    onSubmit() {
      this.$emit("submit", this.entity);
    },
  },
  setup() { },
  watch: {
    visible: {
      handler(a) {
        this.isShow = a;
      },
    },
    isShow: {
      handler(a) {
        this.$emit("changeVisible", a);
      },
    },
    mobile: {
      immediate: true,
      handler(val) {
        this.entity.mobile = val;
      },
    },
    shareProportion: {
      immediate: true,
      handler(val) {
        this.entity.proportionNum = val;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.contentGroup {
  padding-bottom: 25px;
  text-align: center;
}

.buttonGroup {
  text-align: center;
}

.contentText {
  width: 100%;
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0086f5;
  line-height: 20px;
  padding: 11% 25px;
}

.contentTip {
  width: 100%;
  /* text-align: revert; */
  text-align: right;
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0086f5;
  line-height: 14px;
}

.submitBtn {
  cursor: pointer;
  width: 142px;
  height: 36px;
  background: #0086f5;
  border: 0px;
  color: white;
}

.contentWarpModal {
  ::v-deep {
    .ant-modal-content {
      //   min-width: 449px;
      //   min-height: 597px;
      background: #e5f3ff;
      box-shadow: 4px 13px 20px 0px rgba(146, 146, 146, 0.49);
      border-radius: 12px;
    }

    .ant-input-group.ant-input-group-compact {
      .ant-select:last-child {
        .ant-select-selector {
          border-radius: 15px;
        }
      }
    }

    .ant-modal-header {
      background: #e5f3ff;
      border-radius: 12px;
      text-align: center;
      border-bottom: 0px;
    }

    .ant-modal-title {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0086f5;
      line-height: 20px;
    }

    .contentLabel {
      line-height: 32px;
      padding-right: 0.5rem;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0086f5;
    }
  }
}
</style>
