<template>
  <a-button type="primary" @click="showModal">模态框一</a-button>
  <a-button type="primary" @click="showModal2">模态框二</a-button>
  <a-button type="primary" @click="showModal3">模态框三</a-button>
  <a-button type="primary" @click="showModal4">支付框</a-button>
  <a-button type="primary" @click="showModal5">支付确认框</a-button>
  <a-button type="primary" @click="showModal6">金额代充</a-button>
  <a-button type="primary" @click="showModal7">登录客服弹窗</a-button>
  <a-button type="primary" @click="showModal8(true)">批量订单弹窗</a-button>
  <a-button type="primary" @click="showModal9(true)">批量关注确认弹窗</a-button>
  <a-button type="primary" @click="showModal10(true)">修改密码弹窗</a-button>
  <a-button type="primary" @click="showModal11(true)">更换话术</a-button>
  <a-button type="primary" @click="showModal12(true)">延长时间</a-button>
  <a-button type="primary" @click="showModal13(true)">发言频率</a-button>
  <a-button type="primary" @click="showModal14(true)">下单成功</a-button>
  <div>
    <div class="Qrcode" ref="qrcode"></div>
  </div>
  <transactions-modal v-model:visible="visible" @changeVisible="changeVisible">
  </transactions-modal>
  <transactions-modal2
    v-model:visible="visible2"
    @changeVisible="changeVisible2"
  >
  </transactions-modal2>
  <service-modal
    v-model:visible="visible3"
    @changeVisible="changeVisible3"
  ></service-modal>
  <withdraw-modal v-model:visible="visible4" @changeVisible="changeVisible4">
  </withdraw-modal>
  <withdraw-confirm-modal
    v-model:visible="visible5"
    @changeVisible="changeVisible5"
  >
  </withdraw-confirm-modal>
  <amount-transfer-modal
    v-model:visible="visible6"
    @changeVisible="changeVisible6"
    :mobile="test"
  >
  </amount-transfer-modal>
  <batch-order-modal
    v-model:visible="visible8"
    @changeVisible="changeVisible8"
    title="批量关注"
    text="asdasdasdasdasddasdasdasdasd"
    code="21006"
    :on-submit="checked"
  >
  </batch-order-modal>
  <batch-order-confirm-modal
    v-model:visible="visible9"
    @changeVisible="changeVisible9"
    :on-submit="checkOut"
    title="批量关注"
    :price="batchOrderConfirmData.price"
    :code="batchOrderConfirmData.code"
    :check-info="batchOrderConfirmData.checkInfo"
    :linksProp="batchOrderConfirmData.linksProp"
    :successLinksProp="batchOrderConfirmData.successLinksProp"
    :failLinksProp="batchOrderConfirmData.failLinksProp"
  >
  </batch-order-confirm-modal>
  <modify-pwd-modal
    v-model:visible="visible10"
    @changeVisible="changeVisible10"
    title="修改密码"
  >
  </modify-pwd-modal>
  <modify-comment-modal
    v-model:visible="visible11"
    @changeVisible="changeVisible11"
  ></modify-comment-modal>
  <delay-time-modal
    v-model:visible="visible12"
    @changeVisible="changeVisible12"
  ></delay-time-modal>
  <comment-frequency-modal
    v-model:visible="visible13"
    @changeVisible="changeVisible13"
  ></comment-frequency-modal>
  <order-success-modal
    v-model:visible="visible14"
    @changeVisible="changeVisible14"
  ></order-success-modal>
</template>

<script>
import QRCode from "qrcodejs2";
import orderApi from "@/http/apiUtil/orderApi";
//import userApi from "@/http/apiUtil/userApi";
import WithdrawConfirmModal from "@/components/withdraw/WithdrawConfirmModal.vue";
import WithdrawModal from "@/components/withdraw/WithdrawModal.vue";
import TransactionsModal from "@/components/transactions/TransactionsModal.vue";
import TransactionsModal2 from "@/components/transactions/TransactionsModal_two.vue";
import serviceModal from "@/components/service/serviceModal.vue";
import amountTransferModal from "@/components/invate/amountTransferModal.vue";
import batchOrderModal from "@/components/transactions/batchOrderModal.vue";
import batchOrderConfirmModal from "@/components/transactions/batchOrderConfirmModal.vue";
import modifyPwdModal from "@/components/user/modifyPwdModal.vue";
import modifyCommentModal from "@/components/order/modifyCommentModal.vue";
import delayTimeModal from "@/components/order/delayTimeModal.vue";
import commentFrequencyModal from "@/components/order/commentFrequencyModal.vue";
import orderSuccessModal from "@/components/order/orderSuccessModal.vue";
export default {
  components: {
    TransactionsModal,
    TransactionsModal2,
    serviceModal,
    WithdrawModal,
    WithdrawConfirmModal,
    amountTransferModal,
    batchOrderModal,
    batchOrderConfirmModal,
    modifyPwdModal,
    modifyCommentModal,
    delayTimeModal,
    commentFrequencyModal,
    orderSuccessModal,
  },
  props: {},
  data() {
    return {
      test: "asdasdasda2113213",
      visible: false,
      visible2: false,
      visible3: false,
      visible4: false,
      visible5: false,
      visible6: false,
      visible7: false,
      visible8: false,
      visible9: false,
      visible10: false,
      visible11: false,
      visible12: false,
      visible13: false,
      visible14: false,
      batchOrderConfirmData: {
        price: 0,
        code: null,
        linksProp: null,
        checkInfo: [],
        successLinksProp: [],
        failLinksProp: [],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    /**
     *生成二维码
     */
    makeQrcode() {
      new QRCode(this.$refs.qrcode, {
        // text 需要转二维码的内容 可以是文本也可以是一个链接 是链接会直接跳走
        text: "weixin://wxpay/bizpayurl?pr=l2R1XYkzz",
        width: 200,
        height: 200,
        colorDark: "#333", //二维码颜色
        colorLight: "#fff", //二维码背景颜色
        correctLevel: QRCode.CorrectLevel.L, //容错率,L/M/H
      });
    },
    async checked(args) {
      let param = {};
      param.order_links = args.comment;
      param.config_code = args.code;
      let res = await orderApi.LiveDetection(param);
      if (res.ret_code == 200) {
        this.batchOrderConfirmData.linksProp = param.order_links;
        this.batchOrderConfirmData.successLinksProp = res.ret_obj.successData;
        this.batchOrderConfirmData.failLinksProp = res.ret_obj.failData;
        this.batchOrderConfirmData.code = args.code;
        this.batchOrderConfirmData.price = args.price;
        this.batchOrderConfirmData.checkInfo = res.ret_obj.checkInfo;
        this.showModal9(true);
      }

      console.log(args);
    },
    async checkOut(args) {
      if (args.order_robot_count == null || args.order_robot_count == 0) {
        this.$message.error("请输入订单数量");
        return;
      }
      console.log(args);
      let res = await orderApi.batchLiveOrderPlace(args);
      if (res.ret_code == 200) {
        console.log(res);
      }
    },
    showModal() {
      this.visible = true;
    },
    showModal2() {
      this.visible2 = true;
    },
    showModal3() {
      this.visible3 = true;
    },
    showModal4() {
      this.visible4 = true;
    },
    showModal5() {
      this.visible5 = true;
    },
    showModal6() {
      this.visible6 = true;
    },
    showModal7() {
      this.visible7 = true;
    },
    showModal8(flag) {
      this.visible8 = flag;
    },
    showModal9(flag) {
      this.visible9 = flag;
    },
    showModal10(flag) {
      this.visible10 = flag;
    },
    showModal11(flag) {
      this.visible11 = flag;
    },
    showModal12(flag) {
      this.visible12 = flag;
    },
    changeVisible12(flag) {
      this.visible12 = flag;
    },
    showModal13(flag) {
      this.visible13 = flag;
    },
    showModal14(flag) {
      this.visible14 = flag;
    },
    changeVisible13(flag) {
      this.visible13 = flag;
    },
    changeVisible14(flag) {
      this.visible14 = flag;
    },
    changeVisible(flag) {
      this.visible = flag;
    },
    changeVisible2(flag) {
      this.visible2 = flag;
    },
    changeVisible3(flag) {
      this.visible3 = flag;
    },
    changeVisible4(flag) {
      this.visible4 = flag;
    },
    changeVisible5(flag) {
      this.visible5 = flag;
    },
    changeVisible6(flag) {
      this.visible6 = flag;
    },
    changeVisible7(flag) {
      this.visible7 = flag;
    },
    changeVisible8(flag) {
      this.showModal8(flag);
    },
    changeVisible9(flag) {
      this.showModal9(flag);
    },
    changeVisible10(flag) {
      this.showModal10(flag);
    },
    changeVisible11(flag) {
      this.showModal11(flag);
    },
  },
  created() {},
  async mounted() {
    this.makeQrcode();
    //await userApi.TestAuth();
  },
};
</script>

<style scoped></style>
