<template>
  <div class="content_table">
    <a-table
      :id="id"
      :columns="tableData.columns"
      :data-source="tableData.dataSource"
      :pagination="tableData.pagination"
      :row-selection="isRowSeleted === true ? rowSelection : null"
      @change="handleTableChange"
      @resizeColumn="handleResizeColumn"
      :scroll="{ y: tableHeight }"
    >
      <template #bodyCell="{ column, record }">
        <slot name="cell" v-bind="{ column: column, record: record }"></slot>
      </template>
    </a-table>
  </div>
</template>

<script>
import { ref } from "vue";

import { nanoid } from "nanoid";
export default {
  name: "",
  mixins: [],
  components: {},
  props: {
    isRowSeleted: { type: Boolean, default: false },
    columns: { type: Object, default: ref({}) },
    dataSource: { type: Object, default: ref([]) },
    total: { type: Number, default: 0 },
    tabDataBind: { type: Function, default: null },
  },
  data() {
    return {
      id: `tab_${nanoid()}`,
      tableHeight: 0,
      selectedKeys: [],
      tableData: {
        dataSource: this.dataSource,
        columns: this.columns,
        pagination: {
          field: "",
          order: "",
          pageNo: 1,
          pageSize: 10,
          current: 1,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20", "50", "100"],
          showTotal: (total) => `共 ${total} 条`,
          onShowSizeChange: (current, pageSize) =>
            this.onSizeChange(current, pageSize),
          onChange: (page, pageSize) => this.onPageChange(page, pageSize),
          total: this.total, //总条数
        },
      },
      rowSelection: {
        checkStrictly: true,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedKeys = selectedRowKeys;
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
        },
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
        },
      },
    };
  },
  computed: {},
  watch: {
    dataSource: {
      handler(a) {
        this.tableData.dataSource = a;
      },
    },
    columns: {
      handler(a) {
        this.tableData.columns = a;
      },
    },
    total: {
      handler(a) {
        this.tableData.pagination.total = a;
      },
    },
  },
  created() {},
  mounted() {
    /*窗口自适应，关键代码*/
    window.onresize = () => {
      this.tableHeight = this.getTableHeight();
    };
  },
  methods: {
    reset(page) {
      if (page) {
        this.tableData.pagination.current = page;
        this.tableData.pagination.pageNo = page;
      }
      this.handleTableChange({}, {}, {});
    },
    getTableHeight() {
      let id = this.id;
      let doc = document.getElementById(id);
      let height = 800;
      if (doc) {
        height =
          window.innerHeight -
          document.getElementById(id).getBoundingClientRect().top -
          125;
      }
      return height;
    },
    handleResizeColumn: (w, col) => {
      col.width = w;
    },
    //分页切换事件
    onPageChange(page, size) {
      this.tableData.pagination.current = page;
      this.tableData.pagination.pageNo = page;
      this.tableData.pagination.pageSize = size;
    },
    //页显示行数切换事件
    onSizeChange(page, size) {
      this.tableData.pagination.current = page;
      this.tableData.pagination.pageNo = page;
      this.tableData.pagination.pageSize = size;
    },
    async handleTableChange(pag, filters, sorter) {
      let order = sorter.order === "ascend" ? "asc" : "desc";
      this.tableData.pagination.field = sorter.field;
      this.tableData.pagination.order = order;
      if (!sorter.order) {
        this.tableData.pagination.field = "";
        this.tableData.pagination.order = "";
      }
      await this.tabDataBind(
        this.tableData.pagination.pageNo,
        this.tableData.pagination.pageSize,
        this.tableData.pagination.field,
        this.tableData.pagination.order
      );
      this.tableHeight = this.getTableHeight();
    },
  },
};
</script>

<style scoped lang="scss">
.content_table {
  :deep(.ant-table-wrapper) {
    clear: both;
    max-width: 100%;
    padding: 0px 2% 0px 2%;
  }
  :deep(.ant-table-cell) {
    border-bottom: 1px solid rgba(0, 134, 245, 0.34);
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #0086f5;
  }

  :deep(.ant-pagination-options) {
    margin-right: 20px;
  }
}
</style>
