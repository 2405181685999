<template>
    <div class="m-userindex-wrap">
        <mobile-title title="用户中心"></mobile-title>
        <div class="userindex-wrap-header">
            <div class="header-menu">
                <div v-for="item in menuList" :key="item.key" :class="item.selected ? 'active' : ''">
                    <router-link :to="item.to" @click="onClickMenu(item.key)">
                        <span>{{ item.title }}</span>
                        <p></p>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="userindex-wrap-body">
            <router-view @onClickMenu="onClickMenu"></router-view>
        </div>
    </div>
</template>

<script>
import mobileTitle from "@/components/mobile/title.vue";
export default {
    name: '',
    mixins: [],
    components: {
        mobileTitle
    },
    props: {},
    data() {
        return {
            menuList: [
                {
                    key: 1,
                    title: "用户中心",
                    selected: false,
                    to: '/Mobile/User/UserCenter',
                },
                {
                    key: 2,
                    title: "充值记录",
                    selected: false,
                    to: '/Mobile/User/PaymentList',
                },
            ],
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() {

    },
    methods: {
        onClickMenu(key) {
            this.menuList.forEach(element => {
                if (element.key === key) {
                    element.selected = true;
                } else {
                    element.selected = false;
                }
            });
        },
    }
}
</script>

<style scoped lang="scss">
.m-userindex-wrap {
    .userindex-wrap-header {
        padding: 0px 1rem 0px 1rem;

        .header-menu {
            padding: 1rem 0px 1rem 0px;
            display: flex;
            justify-content: space-around;
            color: #0086F5;
            font-size: 1rem;

            .active {
                p {
                    height: 3px;
                    background: #0086F5;
                    border-radius: 8px;
                }
            }

        }
    }
}
</style>
