<template>
  <div class="contentWarpModal" ref="warp">
    <a-modal v-model:visible="isShow" :title="title" :footer="null" :getContainer="() => this.$refs.warp"
      :afterClose="reset" style="min-width:70%">
      <div class="contentWarpModal_header">
        <div style="padding: 0px 50px; cursor: pointer" v-for="item in headers_tips.list" v-bind:key="item.key"
          @click="HeadTipsOnClick(item.key)">
          <div :class="item.isSelected
            ? 'contentWarpModal_header_tips_seleted'
            : 'contentWarpModal_header_tips_unseleted'
            ">
            {{ item.title }}
          </div>
          <div :class="item.isSelected ? 'contentWarpModal_header_foot' : ''"></div>
        </div>
      </div>
      <div class="contentWarpModal_body">
        <comm-table ref="withdrawInfoTable" :columns="datatable.columns" :dataSource="datatable.dataSource"
          :tabDataBind="tabDataBind" :rowKey="(record) => record.id" :total="datatable.total">
        </comm-table>
      </div>
    </a-modal>
  </div>
</template>
<script>
import commTable from "@/components/table/commTable.vue";
import userApi from "@/http/apiUtil/userApi.js";

export default {
  props: {
    title: { type: String, default: "详情" },
    visible: { type: Boolean, default: false },
    date: { type: String, default: "" },
  },
  components: {
    commTable,
  },
  data() {
    return {
      isShow: false,
      headers_tips: {
        list: [
          {
            title: "充值明细",
            key: 1,
            isSelected: true,
          },
          {
            title: "差额明细",
            key: 2,
            isSelected: false,
          },
        ],
      },
      datatable: {
        columns: [],
        dataSource: [],
        total: 0,
      },
    };
  },
  mounted() { },
  methods: {
    reset() { },
    //查询列表
    async searchBtn(page) {
      this.$refs["withdrawInfoTable"].reset(page);
    },
    tabDataBind(page = 1, size = 10, field, order) {
      console.log(`page:${page} ,size:${size},field:${field},order：${order}`);
      let key = this.headers_tips.list.find((t) => t.isSelected === true).key;
      switch (key) {
        case 1:
          this.bindWithdrawData();
          break;
        case 2:
          this.bindMarginData();
          break;
        default:
          break;
      }
    },
    async HeadTipsOnClick(key) {
      this.headers_tips.list.forEach((item) => {
        item.isSelected = false;
        if (item.key === key) {
          item.isSelected = true;
        }
      });
      switch (key) {
        case 1:
          await this.withdrawConfig();
          break;
        case 2:
          await this.marginConfig();
          break;
        default:
          break;
      }
      this.searchBtn(1);
    },
    withdrawConfig() {
      this.datatable.columns = [
        {
          title: "充值时间",
          dataIndex: "createtime",
          key: "createtime",
          customRender: (obj) => {
            return this.$dayjs(obj.text).format("YYYY-MM-DD HH:mm:ss");
          },
        },
        {
          title: "充值金额",
          dataIndex: "recharge_amout",
          key: "recharge_amout",
          customRender: (obj) => {
            if (obj.column.key === "recharge_amout") {
              return this.$util.formatPrice(obj.text);
            }
          },
        },
        {
          title: "返佣金额",
          dataIndex: "agent_amout",
          key: "agent_amout",
          customRender: (obj) => {
            if (obj.column.key === "agent_amout") {
              return this.$util.formatPrice(obj.text);
            }
          },
        },
        {
          title: "充值用户",
          dataIndex: "username",
          key: "username",
        },
      ];
    },

    marginConfig() {
      this.datatable.columns = [
        {
          title: "业务时间",
          dataIndex: "summarydate",
          key: "summarydate",
          customRender: (obj) => {
            return this.$dayjs(obj.text).format("YYYY-MM-DD");
          },
        },
        {
          title: "订单号",
          dataIndex: "order_no",
          key: "order_no",
        },
        {
          title: "差额收益",
          dataIndex: "margin_amout",
          key: "margin_amout",
          customRender: (obj) => {
            if (obj.column.key === "margin_amout") {
              return this.$util.formatPrice(obj.text);
            }
          },
        },
      ];
    },
    async bindWithdrawData(page, size) {
      let param = {
        page: page,
        size: size,
        date: this.date,
      };
      let res = await userApi.GetProxyBenefitInfo(param);
      if (res.ret_code === 200) {
        this.datatable.dataSource = res.ret_obj.details;
        this.datatable.total = res.ret_count;
      }
    },
    async bindMarginData(page, size) {
      let param = {
        page: page,
        size: size,
        date: this.date,
      };
      let res = await userApi.GetMarginBenfitInfo(param);
      if (res.ret_code === 200) {
        this.datatable.dataSource = res.ret_obj.details;
        this.datatable.total = res.ret_count;
      }
    },
  },
  setup() { },
  watch: {
    visible: {
      handler(a) {
        this.isShow = a;
      },
    },
    isShow: {
      handler(a) {
        this.$emit("changeVisible", a);
      },
    },
    date: {
      immediate: true,
      handler(value) {
        if (value) {
          this.HeadTipsOnClick(1);
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.contentWarpModal {
  :deep(.ant-modal-content) {
    border-radius: 12px;
    height: 100%;
  }

  :deep(.ant-modal-header) {
    text-align: center;
    background-color: #daf1ff;
    border-radius: 12px 12px 0px 0px;
  }

  :deep(.ant-modal-title) {
    color: #0086f5;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
  }

  :deep(.ant-modal) {
    height: auto;
  }

  .contentWarpModal_header {
    padding-bottom: 0.7rem;
    display: flex;

    .contentWarpModal_header_tips_seleted {
      width: 57px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #0086f5;
      line-height: 20px;
    }

    .contentWarpModal_header_tips_unseleted {
      width: 57px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #949494;
      line-height: 20px;
    }

    .contentWarpModal_header_foot {
      width: 57px;
      height: 3px;
      background: #0086f5;
      box-shadow: 0px 2px 4px 0px rgba(0, 134, 245, 0.31);
      border-radius: 3px;
    }
  }
}
</style>
