<template>
	<div class="ProxyConfigList-wrap" ref="ProxyConfigList_wrap">
		<div class="wrap-table">
			<div class="table-header">
				<div>业务名称</div>
				<div>价格</div>
				<div>价格上浮量</div>
				<div>实际价格</div>
			</div>
			<div class="table-body">
				<div class="table-body-rows" v-for="item in table.data" v-bind:key="item.config_id">
					<div>{{ item.config_name }}</div>
					<div>{{ `${util.format1000Price(item.config_price)}（${item.config_unit}个）` }}</div>
					<div><a-input-number v-model:value="item.add_price" min="0"></a-input-number></div>
					<div>{{ `${util.format1000Price(item.total_price)}（${item.config_unit}个）` }}</div>
				</div>
			</div>
			<div class="table-footer">
				<a-button type="primary" @click="save">保存</a-button>
			</div>
		</div>
		<div class="wrap-service">
			<span>客服配置</span>
			<div class="service-update">
				<div style="text-align: center; margin-bottom: 0.7rem">
					<p style="margin-bottom: 0; color: #0086f5">请选择300*320尺寸的图片上传</p>
					<a-image
						v-if="imageUrl"
						:src="imageUrl"
						alt="service"
						style="width: 150px; height: 160px"
					/>
				</div>
				<a-upload
					style=""
					accept=".png,.jpg,.jpeg,.bmp"
					v-model:file-list="fileList"
					name="service"
					list-type="picture-card"
					class="avatar-uploader"
					:show-upload-list="false"
					:action="`${orgin}/api/user/UploadServiceImg`"
					:before-upload="beforeUpload"
					@change="handleChange"
				>
					<div>
						<loading-outlined v-if="loading"></loading-outlined>
						<plus-outlined v-else></plus-outlined>
						<div class="ant-upload-text">请选择上传</div>
					</div>
				</a-upload>
			</div>
		</div>
	</div>
</template>

<script>
	import util from '@/common/util';
	import userApi from '@/http/apiUtil/userApi';
	import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';
	import { h } from 'vue';
	export default {
		name: '',
		mixins: [],
		components: { PlusOutlined, LoadingOutlined },
		props: {},
		data() {
			return {
				util: util,
				table: {
					data: [],
				},
				imageUrl: null,
				orgin: process.env.VUE_APP_BASE_URL,
				fileList: null,
				loading: false,
			};
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {
			this.$emit('onClickMenu', 5);
			this.getProxyList();
			this.GetProxyService();
		},
		methods: {
			async getProxyList() {
				let res = await userApi.GetProxyConfigList();
				if (res.ret_code === 200) {
					res.ret_obj.forEach((item) => {
						item.add_price = this.$util.format1000Price(item.add_price);
					});
					this.table.data = res.ret_obj;
				}
			},
			async save() {
				let res = await userApi.CreateProxyConfig(this.table.data);
				if (res.ret_code === 200) {
					this.$message.success('保存成功');
					this.getProxyList();
				} else {
					this.$message.destroy();
					let errorList = res.ret_msg.split('<br/>');
					errorList = errorList.filter((t) => t != null && t != '');
					let doms = [];
					errorList.forEach((item) => {
						doms.push(h('p', item));
					});
					console.log(errorList);
					this.$modal.error({
						getContainer: () => this.$refs.ProxyConfigList_wrap,
						title: '提示',
						content: h('div', {}, [doms]),
					});
				}
			},
			//获取客服图片
			async GetProxyService() {
				let res = await userApi.GetProxyService();
				if (res.ret_code === 200) {
					if (res.ret_obj) {
						this.imageUrl = res.ret_obj.cust_img;
					}
				}
			},
			//添加客服图片
			async AddCoustomService(cust_img) {
				let param = {};
				param.cust_img = cust_img;
				let res = await userApi.AddCoustomService(param);
				if (res.ret_code === 200) {
					this.$message.success('添加成功');
				}
			},
			beforeUpload(file) {
				console.log(file);
			},
			async handleChange(info) {
				console.log(info);
				if (info.file.status === 'uploading') {
					this.loading = true;
					return;
				}
				if (info.file.status === 'done') {
					this.fileList = null;
					if (info.file.response.ret_code === 200) {
						this.imageUrl = info.file.response.ret_obj;
						this.loading = false;
						await this.AddCoustomService(this.imageUrl);
					}
				}
				if (info.file.status === 'error') {
					this.loading = false;
					this.$message.error('upload error');
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.ProxyConfigList-wrap {
		overflow-y: auto;

		.wrap-table {
			.table-header {
				display: flex;
				height: 40px;
				background: #e4f3ff;
				border-radius: 8px;
				align-items: center;
				color: #0091ff;

				div {
					width: 25%;
					text-align: center;
				}
			}

			.table-body {
				.table-body-rows {
					display: flex;
					height: 40px;
					border-radius: 8px;
					align-items: center;
					color: #0091ff;

					div {
						width: 25%;
						text-align: center;

						:deep(.ant-input-number) {
							border-radius: 15px !important;

							color: red;
							width: 80%;

							input {
								text-align: center;
							}
						}
					}
				}
			}

			.table-footer {
				text-align: center;
				margin-bottom: 1rem;

				button {
					font-size: 18px;
					width: 6rem;
					height: 2.5rem;
					font-weight: 600;
				}
			}
		}

		.wrap-service {
			padding: 1rem;

			.service-update {
				display: flex;
			}

			:deep(.avatar-uploader) {
				display: grid;
				align-items: center;
				justify-content: center;
			}
		}
	}
</style>
