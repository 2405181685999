<template>
    <div class="m-paymentlist-wrap">
        <div class="wrap-summary">
            <div class="wrap-summary-item">
                <div class="p-red p"></div>
                <div>
                    <div>{{ util.formatPrice(orderStatistics.totalRecharge) }}￥</div>
                    <div>总充值金额</div>
                </div>
            </div>
            <div class="wrap-summary-item">
                <div class="p-green p"></div>
                <div>
                    <div>{{ util.formatPrice(orderStatistics.todayRecharge) }}￥</div>
                    <div>今日充值金额</div>
                </div>
            </div>

        </div>
        <div class="wrap-body">
            <!-- <div class="wrap-body-search">
                <div>订单搜索:</div>
                <a-input style="width:50%" placeholder="订单号" v-model:value="searchModel.orderNo"></a-input>
                <a-button type="primary" @click="search">搜索</a-button>
            </div> -->
            <div class="wrap-body-list">
                <van-list v-model:loading="listConfig.loading" :finished="listConfig.finished" finished-text="没有更多了"
                    @load="getPaymentList" v-model:error="listConfig.error" error-text="请求失败，点击重新加载">
                    <div class="wrap-body-list-item" v-for="(item, index) in table.data" :key="item.order_no"
                        :index="index">
                        <div class="item-rows">
                            <div>
                                <div>订单编号</div>
                                <div>{{ item.order_no }}</div>
                            </div>
                            <div>
                                <div>充值金额</div>
                                <div>{{ item.pay_amout }}</div>
                            </div>
                            <div>
                                <div>时间</div>
                                <div>{{ item.createtime }}</div>
                            </div>
                        </div>

                    </div>
                </van-list>

            </div>
        </div>
    </div>
</template>

<script>
import userApi from "@/http/apiUtil/userApi";
import util from "@/common/util";
import { List } from "vant";
export default {
    name: '',
    mixins: [],
    components: {
        [List.name]: List,
    },
    props: {},
    data() {
        return {
            table: {
                page: 0,
                size: 20,
                data: [],
            },
            util: util,
            searchModel: {
                orderNo: null,
            },
            orderStatistics: {},
            listConfig: {
                error: false,
                loading: false,
                finished: false,
            }
        }
    },
    computed: {},
    watch: {},
    created() { },
    async mounted() {
        this.$emit('onClickMenu', 2)
        await this.$emit("onClickMenu", 2);
    },
    methods: {
        search() {
            this.table.data = [];
            this.getPaymentList();
        },
        //获取列表
        async getPaymentList() {
            this.table.page = this.table.page + 1;
            let param = {
                order_userid: this.$cookies.get("userid"),
                page: this.table.page,
                size: this.table.size,
            };
            param.order_no = this.searchModel.orderNo;
            var res = await userApi.Transactions(param);
            if (res.ret_code == 200) {
                this.listConfig.loading = false;
                if (res.ret_obj.details.length > 0) {

                    res.ret_obj.details.forEach((item) => {
                        item.pay_amout =
                            util.formatPrice(item.pay_amout) +
                            this.$lang.GetLangByKey("PriceUnit");
                        item.createtime = this.$dayjs(item.createtime).format(
                            "YYYY-MM-DD HH:mm:ss"
                        );
                        this.table.data.push(item);
                    });
                } else {
                    this.listConfig.finished = true;
                }
                this.orderStatistics = res.ret_obj.statistics;
            }
        },
    }
}
</script>

<style scoped lang="scss">
.m-paymentlist-wrap {
    margin: 0px 0.5rem 0px 0.5rem;

    .wrap-summary {
        color: #0086F5;
        display: flex;
        justify-content: space-around;
        background: #E4F3FF;
        box-shadow: inset 0px 1px 3px 0px rgba(0, 134, 245, 0.51);
        border-radius: 8px;
        padding: 0.5rem 0px 0.5rem 0px;

        .wrap-summary-item {
            display: flex;


            .p {
                width: 5px;
                border-radius: 3px;
            }

            .p-red {
                background: linear-gradient(180deg, #FFB2B2 0%, #FF7A7A 100%);
            }

            .p-green {
                background: linear-gradient(360deg, rgb(68, 215, 182) 0%, rgb(195, 233, 213) 100%);
            }

            .p-blur {
                background: linear-gradient(150deg, rgb(131, 203, 255) 0%, rgb(0, 122, 255) 100%);
            }
        }

        .wrap-summary-item>div:last-child {
            margin-left: 0.5rem;
        }
    }

    .wrap-body {
        .wrap-body-search {
            display: flex;
            justify-content: space-around;
            padding: 0.5rem 0px;
            align-items: center;
        }

        .wrap-body-list {


            .wrap-body-list-item {
                margin-top: 1rem;
                color: #0091FFFF;
                background: #E4F3FF;
                border-radius: 8px;
                padding: 0.5rem;

                .item-rows {
                    display: flex;
                    justify-content: space-around;
                }

                .item-rows>div:not(:first-child) {
                    padding-left: 0.5rem;
                }

                .item-rows>div {
                    min-width: 33.3%;
                    flex-grow: 1;
                    word-break: break-all;
                    white-space: normal;
                }
            }

            .wrap-body-list-item>div:not(:first-child) {
                margin-top: 1rem;
            }
        }
    }
}
</style>
