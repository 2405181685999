import { serviceCreate, merge } from '@/http/request';
import axios from 'axios';
//export const baseURL = " http://172.18.30.133:6002";
export const baseURL = process.env.VUE_APP_BASE_URL;
let defaultHttp = serviceCreate({
    baseURL: baseURL,
    timeout: 300000, //请求超时
    emulateJSON: true, //默认表单提交
});

//get请求
export function getData(url, params = {}, config) {
    return defaultHttp.get(url, merge(config, { params }));
}
//delete请求
export function deleteData(url, params = {}, config) {
    return defaultHttp.delete(url, merge(config, { params }));
}
//post请求（表单提交）
export function postDataFrom(url, params = {}, config) {
    return defaultHttp.post(url, params, config);
}
//post请求（json提交）
export function postDataJson(url, params = {}, config) {
    if (!config) {
        config = {};
    }

    if (typeof config.isloading == 'undefined') {
        config.isloading = true;
    }

    return defaultHttp.post(url, params, config);
}
//put请求（表单提交）
export function putDataFrom(url, params = {}, config) {
    return defaultHttp.put(url, params, config);
}
//put请求（json提交）
export function putDataJson(url, params = {}, config) {
    return defaultHttp.put(url, params, config);
}

//获取IP地址
export function getIp() {
    return axios.get('https://api.ipify.org/?format=json');
}