<template>
  <div class="orderSuccessModal_warp" ref="warp">
    <a-modal
      v-model:visible="isShow"
      :title="title"
      :footer="null"
      :confirm-loading="confirmLoading"
      :getContainer="() => this.$refs.warp"
      :afterClose="reset"
    >
      <div class="body">
        <div class="tips">
          恭喜您下单成功！<i class="icon-success icon"></i>
        </div>
        <a-button
          type="primary"
          style="
            border-radius: 15px;
            z-index: 9999;
            padding-left: 3rem;
            padding-right: 3rem;
          "
          @click="submit()"
          >确认</a-button
        >
        <promotion-home :promotionType="2"></promotion-home>
      </div>
    </a-modal>
  </div>
</template>
<script>
import "@/css/icon.scss";
import promotionHome from "@/components/promotion/promotionHome.vue";
export default {
  components: {
    promotionHome,
  },
  props: {
    title: { type: String, default: "下单成功" },
    visible: { type: Boolean, default: false },
  },
  data() {
    return {
      isShow: false,
      confirmLoading: false,
      compact: false,
      entity: {
        confirmNewuserpwd: "",
        userpwd: "",
        newuserpwd: "",
      },
    };
  },
  mounted() {},
  methods: {
    reset() {
      this.entity = {
        confirmNewuserpwd: "",
        userpwd: "",
        newuserpwd: "",
      };
    },
    submit() {
      this.$emit("changeVisible", false);
    },
  },

  setup() {},
  watch: {
    visible: {
      handler(a) {
        this.isShow = a;
      },
    },
    isShow: {
      handler(a) {
        this.$emit("changeVisible", a);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.buttonGroup {
  padding-top: 25px;
  text-align: center;
}

.contentText {
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0086f5;
  line-height: 18px;
  text-align: left;
}

.orderSuccessModal_warp {
  ::v-deep {
    .ant-modal-content {
      //   min-width: 449px;
      //   min-height: 597px;
      background: #e5f3ff;
      box-shadow: 4px 13px 20px 0px rgba(146, 146, 146, 0.49);
      border-radius: 12px;
    }

    .ant-modal-header {
      background: #e5f3ff;
      border-radius: 12px;
      text-align: center;
      border-bottom: 0px;
    }

    .ant-modal-title {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0086f5;
      line-height: 20px;
    }
  }
  .body {
    text-align: center;
    width: 80%;
    margin: auto auto;
    .tips {
      background-color: white;
      border-radius: 15px;
      padding: 2rem;
      margin-bottom: 1rem;
      color: #0086f5ff;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
