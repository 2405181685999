import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import cookies from "js-cookie";
import util from "@/common/util";
import dayjs from "dayjs";
import lang from "@/lang/index";
import { message, Modal } from "ant-design-vue";
import "dayjs/locale/zh-cn";
console.log("当前环境配置" + process.env.NODE_ENV);
console.log("当前环境" + process.env.VUE_APP_BASE_URL);
const app = createApp(App);
app.use(store);
app.use(Antd);
app.use(router).mount("#app");

app.config.globalProperties.$message = message;
app.config.globalProperties.$cookies = cookies;
app.config.globalProperties.$lang = lang;
app.config.globalProperties.$util = util;
app.config.globalProperties.$modal = Modal;
app.config.globalProperties.$dayjs = dayjs;