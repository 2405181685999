<template>
  <div class="contentWarp" ref="warp">
    <a-modal v-model:visible="isShow" :title="title" :footer="null" :confirm-loading="confirmLoading"
      :getContainer="() => this.$refs.warp">
      <div class="contentBody">
        <div class="bodydiv" :style="{
          backgroundImage: `url(` + serviceImage + `)`,
          'background-size': 'cover',
          width: '300px',
          height: '320px',
        }"></div>
      </div>

      <div id="button">
        <a-input-group compact class="buttonGroup">
          <a-button type="primary" style="
              border-radius: 15px;
              z-index: 9999;
              width: 142px;
              height: 33px;
              background: #0086f5;
            " @click="confirmBtn()">确定</a-button>
        </a-input-group>
      </div>
    </a-modal>
  </div>
</template>
<script>
import userApi from "@/http/apiUtil/userApi.js";
export default {
  props: {
    title: { type: String, default: "客服" },
    visible: { type: Boolean, default: false },
    email: { type: String, default: "" },
    text: {
      type: String,
      default: ``,
    },
  },
  mounted() {
    this.GetProxyService();
  },
  methods: {
    //获取客服图片
    async GetProxyService(email) {
      let param = {};
      param.email = email;
      let res = await userApi.GetCoustomService(param);
      if (res.ret_code === 200) {
        if (res.ret_obj && res.ret_obj.length > 0) {
          this.serviceImage = res.ret_obj[0].cust_img;
        } else {
          this.serviceImage = require(`@/assets/homepage/service.png`);
        }
      }
    },
    confirmBtn() {
      this.isShow = false;
    },
  },
  data() {
    return {
      isShow: false,
      confirmLoading: false,
      compact: false,
      entity: {},
      serviceImage: "",
    };
  },
  setup() { },
  watch: {
    visible: {
      immediate: true,
      handler(a) {
        this.isShow = a;
      },
    },
    isShow: {
      immediate: true,
      handler(a) {
        this.$emit("changeVisible", a);
      },
    },
    email: {
      immediate: true,
      handler(value) {
        if (value) {
          this.GetProxyService(value);
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.contentBody {
  align-items: center;
  width: 100%;
  text-align: center;

  .bodydiv {
    width: 128px;
    height: 128px;
    background: linear-gradient(180deg, #00bbfb 0%, #0086f5 100%);
    border-radius: 11px;
    margin: 0 auto;
  }
}

.buttonGroup {
  padding-top: 25px;
  text-align: center;
}

.contentText {
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0086f5;
  line-height: 18px;
  text-align: center;
}

.contentWarp {
  :deep(.ant-modal-content) {
    //   min-width: 449px;
    //   min-height: 597px;
    background: #e5f3ff;
    box-shadow: 4px 13px 20px 0px rgba(146, 146, 146, 0.49);
    border-radius: 12px;
  }

  :deep(.ant-modal-header) {
    background: #e5f3ff;
    border-radius: 12px;
    text-align: center;
    border-bottom: 0px;
  }

  :deep(.ant-modal-title) {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #0086f5;
    line-height: 20px;
  }
}
</style>
