<template>
  <div class=""></div>
</template>

<script>
import userApi from "@/http/apiUtil/userApi";
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  async mounted() {
    let url = encodeURIComponent(window.location.href);
    await userApi.paycallbackTest({ url: url });
  },
  methods: {},
};
</script>

<style scoped lang="scss"></style>
