<template>
    <div class="pageBottom-wrap">
        <RouterView></RouterView>
        <!-- <div style="padding-top:3rem">
            <van-tabbar v-model="active">
                <van-tabbar-item name="home" icon="home-o" to="/Mobile/Home">首页</van-tabbar-item>
                <van-tabbar-item name="search" icon="search">订单记录</van-tabbar-item>
                <van-tabbar-item name="manager" icon="manager-o">个人中心</van-tabbar-item>
                <van-tabbar-item name="cluster" icon="cluster-o">代理中心</van-tabbar-item>
                <van-tabbar-item name="service" icon="service-o">客服</van-tabbar-item>
                <van-tabbar-item name="close" icon="close">登出</van-tabbar-item>
            </van-tabbar>
        </div> -->
    </div>
</template>

<script>
// import home from "@/views/mobile/m_index/home.vue";
import { Tabbar, TabbarItem } from "vant";
import "vant/lib/index.css";
export default {
    name: '',
    mixins: [],
    components: {
        // home,
        [Tabbar.name]: Tabbar,
        [TabbarItem.name]: TabbarItem,
    },
    props: {},
    data() {
        return {
            active: "home",
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {}
}
</script>

<style scoped lang="scss"></style>
