<template>
  <div class="batchOrderConfirmModal_warp" ref="warp">
    <a-modal v-model:visible="isShow" :title="title" :footer="null" :confirm-loading="confirmLoading"
      :getContainer="() => this.$refs.warp" :afterClose="reset">
      <div class="batchOrderConfirmModal_warp_header">
        <p class="batchOrderConfirmModal_warp_header_hint">
          评论成功数{{ successLinks.length }}条，失败数{{ failLinks.length }}条
        </p>
      </div>

      <div class="batchOrderConfirmModal_warp_successbox">
        <div class="title">评论成功详情：{{ successLinks.length }}</div>
        <div class="url" v-for="(item, index) in successLinks" v-bind:key="item">
          {{ index + 1 }}、{{ item }}
        </div>
      </div>
      <div class="batchOrderConfirmModal_warp_failbox">
        <div class="header">
          <div class="title">评论失败详情：{{ failLinks.length }}</div>
          <div class="copy"><a @click="copy">一键复制</a></div>
        </div>
        <div class="url" v-for="(item, index) in failLinks" v-bind:key="item">
          {{ index + 1 }}、{{ item }}
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import orderApi from "@/http/apiUtil/orderApi";
export default {
  props: {
    title: { type: String, default: "评论详情" },
    visible: { type: Boolean, default: false },
    orderNo: { type: String, default: null },
  },
  data() {
    return {
      isShow: false,
      confirmLoading: false,
      compact: false,
      successLinks: [],
      failLinks: [],
    };
  },
  mounted() { },
  methods: {
    reset() { },
    copy() {
      if (!this.failLinks || this.failLinks.length === 0) {
        this.$message.error("没有数据可以复制");
        return;
      }
      let text = this.failLinks.join("\n");
      // 创建输入框元素
      let oInput = document.createElement("textarea");
      // 将想要复制的值
      oInput.value = text;
      // 页面底部追加输入框
      document.body.appendChild(oInput);
      // 选中输入框
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      // 弹出复制成功信息
      this.$message.success("复制成功");
      // 复制后移除输入框
      oInput.remove();
    },
  },

  setup() { },
  watch: {
    visible: {
      immediate: true,
      handler(a) {
        this.isShow = a;
      },
    },
    isShow: {
      handler(a) {
        this.$emit("changeVisible", a);
      },
    },
    orderNo: {
      immediate: true,
      async handler(value) {
        console.log(value);
        if (!value) {
          return;
        }
        this.successLinks = [];
        this.failLinks = [];
        let res = await orderApi.TkOrderCommentInfo({ OrderNo: value });
        if (res.ret_code === 200) {
          this.successLinks = res.ret_obj.success;
          this.failLinks = res.ret_obj.fail;
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.buttonGroup {
  padding-top: 25px;
  text-align: center;
}

.batchOrderConfirmModal_warp {
  ::v-deep {
    .ant-modal-content {
      //   min-width: 449px;
      //   min-height: 597px;
      background: #e5f3ff;
      box-shadow: 4px 13px 20px 0px rgba(146, 146, 146, 0.49);
      border-radius: 12px;
    }

    .batchOrderConfirmModal_warp_header {
      padding-left: 10%;
      padding-right: 10%;

      .batchOrderConfirmModal_warp_header_hint {
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        text-align: left;
        background: #cde8ff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07),
          inset 0px 1px 4px 0px rgba(0, 80, 157, 0.77);
        border-radius: 8px;
        border: 1px solid #0075f3;
        text-align: center;
        line-height: 38px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #0086f5;
      }
    }

    .batchOrderConfirmModal_warp_databody {
      .batchOrderConfirmModal_warp_databody_tips {
        padding-top: 0px;
        padding-left: 50%;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #0091ff;

        .exceed {
          color: red;
        }
      }
    }

    .ant-input-group.ant-input-group-compact {
      .ant-select:last-child {
        .ant-select-selector {
          border-radius: 15px;
        }
      }
    }

    .ant-modal-header {
      background: #e5f3ff;
      border-radius: 12px;
      text-align: center;
      border-bottom: 0px;
    }

    .ant-modal-title {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0086f5;
      line-height: 20px;
    }
  }

  .batchOrderConfirmModal_warp_successbox {
    width: 322px;
    height: 219px;
    background: #ffffff;
    border-radius: 15px;
    opacity: 0.8;
    margin: 0px auto;
    overflow-y: auto;

    .title {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #0091ff;
      line-height: 30px;
      padding-left: 1rem;
    }

    .url {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #0091ff;
      line-height: 20px;
      padding-left: 1rem;
    }
  }

  .batchOrderConfirmModal_warp_failbox {
    width: 322px;
    height: 127px;
    background: #ebfffa;
    border-radius: 15px;
    opacity: 0.8;
    margin: 1rem auto 0px;
    overflow-y: auto;

    .header {
      display: flex;
      position: relative;

      .title {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #44d7b6;
        line-height: 30px;
        padding-left: 1rem;
      }

      .copy {
        line-height: 30px;
        position: absolute;
        right: 1rem;
      }
    }

    .url {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #44d7b6;
      line-height: 20px;
      padding-left: 1rem;
    }
  }
}
</style>
