<template>
  <div class="contentWarp" ref="warp_ref">
    <div class="content_div1" @click="showServiceModal">
      <a-image :src="bodyImg" :preview="false"></a-image>
    </div>
    <!-- <div class="click-div1"></div> -->
    <a-modal v-model:visible="serviceModal.isShow" title="联系客服" :footer="null" :getContainer="() => this.$refs.warp_ref">
      <div class="contentBody">
        <a-image :src="serviceImg" :preview="false">

        </a-image>
      </div>
    </a-modal>
  </div>
</template>

<script>
import userApi from "@/http/apiUtil/userApi.js";
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      bodyImg: require("@/assets/advertising/put-cn.png"),
      serviceImg: require("@/assets/advertising/cn-wechat-service.png"),
      serviceModal: {
        isShow: false,
      }
    };
  },
  computed: {},
  watch: {},
  created() { },
  async mounted() {
    await userApi.RecordLog();
    console.log("访问了一次网站");
    // var Ip=returnCitySN['cip']
    //   var cityname=returnCitySN['cname']
  },
  methods: {
    showServiceModal() {
      this.serviceModal.isShow = true;
    },
  },
};
</script>

<style scoped lang="scss">
.contentWarp {
  .click-div1 {
    background-color: red;
    width: 200px;
    height: 100px;
  }

  :deep(.ant-modal-content) {
    border-radius: 27px;

    .ant-modal-header {
      border-radius: 27px;
      border-bottom: 0px;
      text-align: center;

      .ant-modal-title {
        font-size: 1.1rem !important;
        font-weight: 700 !important;
      }
    }
  }

}
</style>
