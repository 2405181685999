import cookies from "js-cookie";

import lang from "./lang.js";

function GetLangByKey(val) {
    let text = "";
    var language = cookies.get("lang") || "ch-zn";
    if (language == "ch-zn") {
        text = lang.cn[val];
    } else if (language == "en") {
        text = lang.en[val];
    }
    return text;
}

function GetLangTipsByKey(val) {
    let text = "";
    var language = cookies.get("lang") || "ch-zn";
    if (language == "ch-zn") {
        text = lang.cn.Tips[val];
    } else if (language == "en") {
        text = lang.en.Tips[val];
    }
    return text;
}

export default { GetLangByKey, GetLangTipsByKey };