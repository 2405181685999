<template>
	<div class="shopping-wrap" ref="shopping_wrap">
		<div class="wrap-country-list">
			<div class="wrap-country-list-body">
				<div class="wrap-country-title">
					<span class="icon-global icon"></span>
					<span>国家选择</span>
				</div>
				<div class="wrap-country-search">
					<a-select
						v-model:value="search.country_code"
						placeholder="输入国家进行查询"
						showSearch="true"
						style="width: calc(100% - 50px)"
						allowClear
						:filter-option="
							(input, option) => {
								return String(option.text).toLowerCase().indexOf(input.toLowerCase()) >= 0;
							}
						"
					>
						<a-select-option text="全部" key="-1" :value="null">全部</a-select-option>
						<a-select-option
							:text="`${item.country_name}（+${item.country_code}）`"
							:key="item.country_code"
							v-for="item in countryList"
							:value="item.country_code"
							>{{ item.country_name }}（+{{ item.country_code }}）</a-select-option
						>
					</a-select>
					<a-button type="primary" style="border-radius: 0px 7px 7px 0px" @click="GetCountryList"
						>查询</a-button
					>
				</div>
				<div class="wrap-country-list-items">
					<div
						v-for="item in shoppingMenus"
						:key="item.country_code"
						:class="`item ${item.seleted ? 'active' : ''}`"
						@click="onClickByShoppingMenus(item.key)"
					>
						<a-image :src="item.country_logo" width="44px" :preview="false" height="33px"></a-image>
						<div class="content">{{ item.title }}（+{{ item.country_code }}）</div>
					</div>
				</div>
			</div>
		</div>
		<div class="wrap-shopping-body">
			<div class="hit">
				<span class="icon icon-cart"></span>
				<p>商品描述</p>
			</div>
			<div class="wrap-shopping-description">
				<p>TikTok账号售后规则:</p>
				<span
					v-for="(item, index) in this.goodsTips"
					v-bind:key="index"
					v-show="index != this.goodsTips.length - 1"
				>
					<span v-html="item"></span
				></span>
				<!-- <span> 2--账号自购买24小时内包售后</span>
                <span> 3--包网页登录，手机客户端登录</span>
                <span> 4--邮箱可换绑</span>
                <span> 5--格式为邮箱账号密码，Tiktok账号密码</span> -->
				<p
					v-for="(item, index) in this.goodsTips"
					v-bind:key="index"
					v-show="index === this.goodsTips.length - 1"
				>
					<span v-html="item"></span>
				</p>
			</div>
			<div class="hit">
				<span class="icon icon-goods"></span>
				<p>账号选择</p>
			</div>
			<div class="wrap-shopping-goods">
				<div
					:class="`shop-list-item ${item.seleted ? 'active' : ''}`"
					v-for="item in list"
					v-bind:key="item.key"
					v-show="item.count > 0"
					@click="onClickByShopping(item.key)"
				>
					<div>
						{{ item.name.substr(0, item.name.indexOf('[')) }}
						<span :style="`${item.name.indexOf('邮箱') > 0 ? 'color:#43CF7C' : 'color:red'}`">{{
							item.name.substr(item.name.indexOf('['))
						}}</span>
					</div>
					<div class="item-bottom">
						<div class="bottom-cart">
							<span class="icon icon-cart"></span>
							<div>
								库存：<span :style="`${item.count > 0 ? 'color:#FF3636;font-size: 1rem;' : ''}`">{{
									item.count
								}}</span
								>件
							</div>
						</div>
						<div>
							<div style="color: #ff3636">
								单价：<span>{{ item.count > 0 ? item.amount : '待定' }}￥</span>
							</div>
						</div>
					</div>
					<Icon v-if="item.seleted" class="active-icon" icon="CheckOutlined"></Icon>
				</div>
			</div>

			<div class="wrap-shopping-order">
				<div class="buys-order-list">
					<div class="buys-order-item" v-for="item in orderList" v-bind:key="item.key">
						<div style="height: 100%">
							<span class="buys-order-item-tips">{{ item.country_name }}</span>
						</div>
						<div>
							{{ item.config_name.substr(0, item.config_name.indexOf('[')) }}
							<span
								:style="`${item.config_name.indexOf('邮箱') > 0 ? 'color:#43CF7C' : 'color:red'}`"
								>{{ item.config_name.substr(item.config_name.indexOf('[')) }}</span
							>
						</div>
						<div>单价：{{ item.config_price }}¥</div>
						<div style="display: flex; align-items: center">
							<span
								@click="Down(item.key)"
								class="icon-down icon"
								style="margin-right: 0rem; width: 32px; height: 32px; cursor: pointer"
							></span>
							<a-input-number
								v-model:value="item.account_total"
								min="0"
								oninput="value=value.replace(/[^\d]/g, '')"
								@change="staticAmount"
							></a-input-number>
							<span
								@click="Up(item.key)"
								class="icon-up icon"
								style="margin-right: 0rem; width: 32px; height: 32px; cursor: pointer"
							></span>
						</div>
						<div>
							<a-button type="primary" danger @click="deleteOrder(item.key)">删除</a-button>
						</div>
					</div>
				</div>

				<div class="summary">
					<!-- <div class="order-shopname">商品购买</div> -->
					<div class="order-summary">
						<div
							style="
								max-width: 300px;
								box-shadow: 2px 2px 11px 0px rgba(0, 130, 245, 0.17);
								border-radius: 16px;
								height: 2rem;
								line-height: 2rem;
								padding-left: 1rem;
								color: #0086f5;
								display: grid;
								font-size: 1.1rem;
								width: 100%;
								align-items: center;
								height: 3rem;
							"
						>
							总金额：{{ total_amount }}￥
						</div>
						<a-button
							type="primary"
							style="position: absolute; right: 0px; border-radius: 16px; height: 3rem"
							@click="Order"
							>下单</a-button
						>
					</div>
				</div>
			</div>
		</div>
	</div>
	<Icon v-if="false" class="active-icon" icon="CheckOutlined"></Icon>
</template>

<script>
	import orderApi from '@/http/apiUtil/orderApi';
	import { Icon } from '@/components/util/iconUtil.js';
	import util from '../../common/util';
	export default {
		name: '',
		mixins: [],
		components: { Icon },
		props: {},
		data() {
			return {
				search: {
					country_code: null,
				},
				countryList: [],
				shoppingMenus: [],
				goodsTips: null,
				list: [],
				orderList: [],
				total_amount: 0,
			};
		},
		computed: {},
		watch: {},
		created() {},
		async mounted() {
			await this.GetAllCountryList();
			await this.GetCountryList();

			//this.onClickByShoppingMenus(0);
		},
		methods: {
			//获取所有商品地区分类
			async GetAllCountryList() {
				this.shoppingMenus = [];
				let res = await orderApi.GetCountryList({});
				if (res.ret_code === 200) {
					this.countryList = res.ret_obj;
				}
			},
			//获取商品地区分类
			async GetCountryList() {
				this.shoppingMenus = [];
				let param = {};
				param.country_code = this.search.country_code;
				let res = await orderApi.GetCountryList(param);
				if (res.ret_code === 200) {
					res.ret_obj.forEach((item, index) => {
						this.shoppingMenus.push({
							key: index,
							country_code: item.country_code,
							title: item.country_name,
							seleted: false,
							country_logo: item.country_logo,
							country_remark: item.country_remark,
						});
					});
					//清空商品
					this.list = [];
				}
				//default option
				if (this.shoppingMenus.length > 0) {
					this.shoppingMenus[0].seleted = true;
					this.GetConfigListByCountry(this.shoppingMenus[0].country_code);
				}
			},
			//获取商品
			async GetConfigListByCountry(config_country_code) {
				this.goodsTips = this.shoppingMenus
					.find((t) => t.country_code === config_country_code)
					?.country_remark.split('\r\n');
				this.list = [];
				let param = {};
				param.country_code = config_country_code;
				let res = await orderApi.GetConfigListByCountry(param);
				if (res.ret_code === 200) {
					res.ret_obj.forEach((item) => {
						this.list.push({
							key: item.config_code,
							config_code: item.config_code,
							name: item.config_name,
							count: item.account_count,
							amount: util.format1000Price(item.config_price),
							seleted: false,
						});
					});

					this.resetConfig();
				}
			},
			//商品分类点击
			async onClickByShoppingMenus(key) {
				this.shoppingMenus.forEach((element) => {
					if (element.key === key) {
						element.seleted = true;
						this.GetConfigListByCountry(element.country_code);
					} else {
						element.seleted = false;
					}
				});
			},
			onClickByShopping(key) {
				let flag = this.list.find((t) => t.key === key);
				if (flag.count == 0) {
					this.$message.error('库存不足');
					return;
				}
				let country_name = this.shoppingMenus.find((t) => t.seleted === true).title;
				if (!flag.seleted) {
					this.orderList.push({
						key: flag.key,
						config_code: flag.config_code,
						country_name: country_name,
						config_name: flag.name,
						config_price: flag.amount,
						account_total: 1,
					});
				} else {
					this.orderList = this.orderList.filter((item) => item.key != key);
				}
				this.list.find((t) => t.key === key).seleted = !flag.seleted;
				this.staticAmount();
			},
			deleteOrder(key) {
				this.orderList = this.orderList.filter((item) => item.key != key);
				this.list.find((t) => t.key === key).seleted = !this.list.find((t) => t.key === key)
					.seleted;
				this.staticAmount();
			},
			Up(key) {
				this.orderList.find((t) => t.key === key).account_total =
					this.orderList.find((t) => t.key === key).account_total + 1;
				this.staticAmount();
			},
			Down(key) {
				this.orderList.find((t) => t.key === key).account_total =
					this.orderList.find((t) => t.key === key).account_total - 1;
				if (this.orderList.find((t) => t.key === key).account_total < 0) {
					this.orderList.find((t) => t.key === key).account_total = 0;
				}
				this.staticAmount();
			},
			staticAmount() {
				this.total_amount = 0;
				this.orderList.forEach((item) => {
					this.total_amount += item.account_total * item.config_price;
				});
			},
			//重置业务的选中状态
			resetConfig() {
				let that = this;
				this.orderList.forEach((item) => {
					if (that.list.find((t) => t.key === item.key)) {
						that.list.find((t) => t.key === item.key).seleted = true;
					}
				});
			},
			/**
			 *下单
			 */
			Order() {
				if (this.orderList.length == 0) {
					this.$message.error('请选择业务');
					return;
				}
				if (this.total_amount == 0) {
					this.$message.error('请选择购买数量');
					return;
				}
				this.$modal.confirm({
					content: `本次下单金额：${this.total_amount}￥。是否确认下单？`,
					getContainer: () => this.$refs.shopping_wrap,
					// icon: createVNode(ExclamationCircleOutlined),
					onOk: async () => {
						let param = [];
						this.orderList.forEach((item) => {
							param.push({
								config_code: item.config_code,
								robot_total_count: item.account_total,
							});
						});
						let res = await orderApi.BuyAccountOrder(param);
						if (res.ret_code === 200) {
							this.orderList = [];
							this.total_amount = 0;
							this.$modal.confirm({
								content: `购买成功，账号详细信息请前往【订单记录】查看账号信息`,
								getContainer: () => this.$refs.shopping_wrap,
								onOk: async () => {
									window.location.href = '/#/Index/OrderRecord';
								},
								cancelText: '关闭',
								okText: '立即前往【订单记录】',
								onCancel() {},
							});
							if (this.shoppingMenus.find((t) => t.seleted === true)) {
								this.onClickByShoppingMenus(this.shoppingMenus.find((t) => t.seleted === true).key);
							}
						}
					},
					cancelText: '取消',
					onCancel() {},
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	.shopping-wrap {
		// padding: 0px 1.5rem 1rem 1.5rem;
		display: flex;
		height: 100%;

		.wrap-country-list {
			width: 20%;
			border-right: 1px solid #e3e3e3;
			overflow: auto;

			.wrap-country-list-body {
				width: 80%;
				margin: 0px auto;

				.wrap-country-title {
					display: flex;
					margin: 0.5rem 0px 0.5rem 0px;
				}

				.wrap-country-search {
					margin-bottom: 0.5rem;
					display: flex;

					:deep(.ant-select-selector) {
						border-radius: 7px 0px 0px 7px;
					}

					:deep(.ant-select) {
						width: 100%;
					}
				}

				.wrap-country-list-items {
					.item {
						cursor: pointer;
						background: linear-gradient(0deg, #9da5b2 0%, #d3dded 100%);
						box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
						border-radius: 8px;
						height: 33px;
						display: flex;
						align-items: center;
						margin-bottom: 1rem;

						.content {
							text-align: center;
							width: calc(100% - 33px);
							color: white;
						}
					}

					.active {
						background: linear-gradient(0deg, #00a4fa 0%, #006bf2 100%);
						box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
					}
				}
			}
		}

		.wrap-shopping-body {
			width: 80%;
			padding-left: 1rem;
			overflow: auto;

			.hit {
				display: flex;
				margin: 0.5rem 0px 0.5rem 0px;
			}

			.wrap-shopping-description {
				color: rgba(0, 0, 0, 0.5);
				margin-left: 4rem;
				font-size: 1rem;

				span {
					margin-right: 2rem;
				}
			}

			.wrap-shopping-goods {
				display: flex;
				flex-wrap: wrap;
				.shop-list-item {
					position: relative;
					width: 300px;
					min-height: 110px;
					background: #ffffff;
					box-shadow: 0px 2px 10px 0px rgba(0, 116, 243, 0.27);
					border-radius: 22px;
					padding: 1rem;
					display: grid;
					align-content: space-around;
					cursor: pointer;
					margin-left: 1rem;
					margin-top: 1rem;
					margin-bottom: 1rem;

					.item-bottom {
						display: flex;
						justify-content: space-around;

						.bottom-cart {
							display: flex;
						}
					}
				}

				.active {
					border: 1px solid #0081f4ff;

					.active-icon {
						position: absolute;
						right: 0;
						bottom: 0;
						padding: 0.3rem;
						background-color: #0081f4ff;
						/* border-radius: 22px; */
						border-bottom-right-radius: 22px;
						color: white;
					}
				}
			}

			.wrap-shopping-order {
				width: 100%;
				padding: 2rem;

				.buys-order-list {
					color: #0081f4;

					.buys-order-item {
						height: 58px;
						background: #f0f8ff;
						width: 100%;
						border-radius: 7px;
						display: flex;
						// justify-content: space-around;
						position: relative;
						align-items: center;
						margin-bottom: 1rem;

						:deep(.ant-btn) {
							border-radius: 5px !important;
						}

						div {
							width: 20%;
						}

						.buys-order-item-tips {
							background: linear-gradient(130deg, #ffaf8f 0%, #ff623f 100%);
							position: absolute;
							left: 0;
							height: 100%;
							display: inline-block;
							white-space: nowrap;
							padding: 1rem 2.5rem 1rem 2.5rem;
							border-radius: 7px;
							opacity: 0.85;
							color: white;
						}
					}
				}

				.summary {
					display: grid;
					justify-content: end;

					.order-shopname {
					}

					.order-summary {
						width: 300px;
						display: flex;
						position: relative;
					}
				}
			}
		}
	}
</style>
