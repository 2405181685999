<template>
	<div class="contentWarp" ref="warp">
		<a-modal
			v-model:visible="isShow"
			:title="title"
			:footer="null"
			:confirm-loading="confirmLoading"
			:getContainer="() => this.$refs.warp"
			:afterClose="reset"
		>
			<div class="TransactionsModal-wrap-tips">
				<p class="contentText" v-html="text"></p>
			</div>

			<div id="button" class="TransactionsModal-wrap-body">
				<a-input-group compact class="buttonGroup" style="">
					<a-input
						v-model:value="entity.linkUrl"
						class="wrap-body-input"
						:placeholder="
							this.code == '1004' ||
							this.code == '1005' ||
							this.code == '1007' ||
							this.code == '1008' ||
							this.code == '1009'
								? this.$lang.GetLangTipsByKey('ModalVideoLink')
								: this.code == '1006'
								? '请输入Tiktok号或主页链接'
								: this.$lang.GetLangTipsByKey('ModalTkLink')
						"
					/>
					<!-- <a-button
            @click="confirmBtn()"
            type="primary"
            style="border-radius: 15px; margin-left: -50px; z-index: 9999"
            >Confirm</a-button
          > -->
				</a-input-group>
				<a-input-group compact class="buttonGroup">
					<a-input-number
						v-model:value="entity.comment"
						:onStep="onStep"
						min="0"
						:step="config_step"
						class="wrap-body-input"
						:style="{
							width: '70%',
						}"
						oninput="value=value.replace(/[^\d]/g, '')"
						@blur="blur"
						:placeholder="`请输入${config_step}的倍数`"
					/>
					<span
						v-if="config_info?.config_unit > 1 && isFull"
						style="line-height: 32px; font-weight: 600"
						>&nbsp;*&nbsp;{{ config_info?.config_unit }}</span
					>
					<div>
						下单[{{ entity.comment * (isFull ? config_info?.config_unit : 1) || 0 }}]数量的&nbsp;{{
							title
						}}
					</div>
				</a-input-group>
				<a-input-group compact class="buttonGroup" v-if="showTime">
					<a-select
						:placeholder="this.$lang.GetLangTipsByKey('ModalOrderDuration')"
						ref="select"
						v-model:value="entity.time"
						style="width: 100%; border-radius: 15px; text-align: left"
						@change="blur"
					>
						<a-select-option v-for="item in selectOptionTime" v-bind:key="item" :value="item"
							>{{ item }}H</a-select-option
						>
					</a-select>
				</a-input-group>
				<a-input-group
					compact
					class="buttonGroup"
					v-if="code == 1006 && false"
					style="display: flex; align-items: center"
				>
					<a-button type="primary" style="border-radius: 15px" @click="checkTiered()">
						阶梯计价检测</a-button
					>
					<a @click="showTieredDetail" style="margin-left: 0.5rem; text-decoration: underline"
						>查看详情</a
					>
				</a-input-group>
				<a-input-group compact class="buttonGroup">
					<a-input
						disabled="disabled"
						v-model:value="entity.orderNum"
						style="
							width: 80%;
							border-radius: 15px;
							background-color: white;
							color: #0086f5ff;
							text-align: center;
						"
						placeholder=""
					/>
					<a-button
						type="primary"
						style="border-radius: 15px; margin-left: -50px; z-index: 9999"
						@click="order()"
					>
						{{ this.$lang.GetLangByKey('Order') }}</a-button
					>
				</a-input-group>
			</div>
			<promotion-home :promotionType="1" />
			<order-success-modal
				v-model:visible="orderSuccessVisible"
				@changeVisible="orderSuccesschangeVisible"
			></order-success-modal>
		</a-modal>
	</div>
</template>
<script>
	import orderApi from '@/http/apiUtil/orderApi';
	import util from '@/common/util.js';
	import promotionHome from '@/components/promotion/promotionHome.vue';
	import orderSuccessModal from '@/components/order/orderSuccessModal.vue';
	import { h } from 'vue';
	export default {
		components: {
			promotionHome,
			orderSuccessModal,
		},
		props: {
			title: { type: String, default: '' },
			visible: { type: Boolean, default: false },
			price: { type: Number, default: 0 },
			code: { type: String, default: '' },
			showTime: { type: Boolean, default: false },
			step: { type: Number, default: 0 },
			text: {
				type: String,
				default: ``,
			},
		},
		mounted() {},
		methods: {
			onStep(value, type) {
				var config_step = this.config_step;
				console.log(this.entity.comment);
				let num = this.entity.comment % config_step;
				if (type.type == 'up') {
					this.entity.comment -= num;
				} else {
					if (this.entity.comment != 0) {
						this.entity.comment += config_step - (num === 0 ? config_step : num);
					}
				}
				console.log(`${value},${type}`);
			},
			blur() {
				this.isCheckTiered = false;
				console.log('price:' + this.price);
				if (this.entity.comment > 0) {
					let num = this.entity.comment % this.config_step;
					if (this.entity.comment < this.config_step) {
						this.entity.comment = this.config_step;
					} else {
						this.entity.comment -= num;
					}
				}
				let count = this.entity.comment;
				let price = 0;
				if (this.code == '1001') {
					price = this.$util.format1000Price(this.price * count * this.entity.time);
				} else {
					price = this.$util.format1000Price(this.price * count);
				}
				if (!this.isFull) {
					price = (price / this.config_info.config_unit).toFixed(2);
				}
				//增加阶梯增量价格
				price = Number(price) + Number(this.tiered_total_amount);
				console.log(price);
				this.entity.orderNum = `${this.$lang.GetLangByKey(
					'Amount'
				)} ${price}${this.$lang.GetLangByKey('PriceUnit')}`;
			},
			confirmBtn() {
				alert(
					`linkUrl:${this.entity.linkUrl};comment:${this.entity.comment};orderNum:${this.entity.orderNum}`
				);
			},
			reset() {
				this.entity = {
					linkUrl: '',
					comment: null,
					orderNum: `${this.$lang.GetLangByKey('Amount')} 0${this.$lang.GetLangByKey('PriceUnit')}`,
					time: null,
				};
			},
			//下单
			async order() {
				if (this.code == '1006' || this.code == '21006') {
					this.checkTiered();
				} else {
					this.orderBtn();
				}
			},
			async orderBtn() {
				console.log('窗口1当前配置' + this.code);
				if (!this.entity.linkUrl) {
					if (this.code == '1004' || this.code == '1005' || this.code == '1007') {
						this.$message.error(this.$lang.GetLangTipsByKey('ModalVideoLink'));
					} else {
						this.$message.error(this.$lang.GetLangTipsByKey('ModalTkLink'));
					}
					return;
				}

				let result = this.entity.comment % this.config_step;
				let err = `请输入${this.config_step}的倍数`;
				if (result != 0 || (this.entity.comment || 0) == 0) {
					this.entity.orderNum = `${this.$lang.GetLangByKey(
						'Amount'
					)} ${0}${this.$lang.GetLangByKey('PriceUnit')}`;
					this.$message.error(err);
					return;
				}
				if (this.showTime === true && this.entity.time === null) {
					this.$message.error(this.$lang.GetLangTipsByKey('ModalOrderDuration'));
					return;
				}
				let param = {};
				let count = this.entity.comment;
				count = count * this.config_info.config_unit;

				if (!this.isFull) {
					count /= this.config_info.config_unit;
				}

				param.config_code = this.code;
				param.order_robot_count = count;
				param.order_link = this.entity.linkUrl;
				param.live_time = this.entity.time;
				console.log(param);
				// if (!this.isCheckTiered && this.code == '1006') {
				//   this.$message.error("请先点击【阶梯计价检测】按钮");
				//   return;
				// }
				let that = this;
				if (that.config_info.config_unit > 1 && this.isFull) {
					that.$modal.confirm({
						getContainer: () => this.$refs.warp,
						onOk: () => {
							that.placeOrder(param);
						},
						title: h(
							'div',
							{
								style: {
									color: '#0086f5',
									'font-size': '18px',
									'font-weight': '600',
								},
							},
							'提示'
						),
						content: h(
							'div',
							{
								style: {
									color: '#0086f5',
									'font-size': '14px',
									'font-weight': '600',
								},
							},
							[
								h(
									'p',
									`下单 [ ${that.entity.comment * that.config_info?.config_unit || 0} ] 数量的 ${
										that.title
									}`
								),
								h('p', { style: 'color:red' }, '确认下单吗？'),
							]
						),
					});
				} else {
					this.placeOrder(param);
				}
			},
			/**
			 *阶梯计价检测
			 */
			async checkTiered() {
				let param = {};
				param.config_code = this.code;
				param.robot_total_count = this.entity.comment;
				param.is_batch = 0;
				param.links = this.entity.linkUrl;
				var res = await orderApi.GetTieredPrice(param);
				debugger;
				if (res.ret_code == 200) {
					//  this.$message.success("检测成功");
					this.tiered_total_amount = util.format1000Price(res.ret_obj.total_amount);
					this.tiered_detail = res.ret_obj.tiered_detail;
					this.blur();
					this.isCheckTiered = true;
					this.tiered_total_amount = 0;
					this.showTieredDetail();
				}
			},
			/**
			 *展示阶梯计价详情
			 */
			showTieredDetail() {
				if (this.tiered_detail.length == 0) {
					//下单
					this.orderBtn();
					return;
				}
				let list = [];
				let total_amount = 0;
				this.tiered_detail.forEach((item) => {
					total_amount += item.amount;
					list.push(
						h('p', [
							h('span', { style: { color: 'red' } }, `【${item.vcLink}】`),
							h('span', `总计订单数`),
							h('span', { style: { color: 'red' } }, ` ${item.total_count} `),
							h('span', `，有`),
							h('span', { style: { color: 'red' } }, ` ${item.interval_count} `),
							h('span', `订单在`),
							h('span', { style: { color: 'red' } }, ` ${this.title} `),
							h('span', `阶梯计价的`),
							h('span', { style: { color: 'red' } }, ` ${item.tiered_begin_number} `),
							h('span', `-`),
							h('span', { style: { color: 'red' } }, ` ${item.tiered_end_number} `),
							h('span', `区间，方式为：原有单价上 +`),
							h(
								'span',
								{ style: { color: 'red' } },
								` ${this.$util.format1000Price(item.tiered_add_price)} `
							),
							h('span', `(个)`),
						])
					);

					list.push(
						h('p', [h('span', `此区间计价： ${this.$util.format1000Price(item.amount)} ￥`)])
					);
				});
				list.push(h('p', [h('span', `总计价： ${this.$util.format1000Price(total_amount)} ￥`)]));
				this.$modal.confirm({
					getContainer: () => this.$refs.warp,
					cancelText: '取消下单',
					okText: '确认下单',
					onOk: () => {
						//下单
						this.orderBtn();
					},
					closable: true,
					title: h(
						'div',
						{
							style: {
								color: '#0086f5',
								'font-size': '18px',
								'font-weight': '600',
							},
						},
						'提示'
					),
					content: h(
						'div',
						{
							style: {
								color: '#0086f5',
								'font-size': '14px',
								'font-weight': '600',
								'max-height': '60vh',
								'overflow-y': 'auto',
							},
						},
						list
					),
				});
			},
			async placeOrder(param) {
				var res = await orderApi.LiveOrderPlace(param);
				if (res.ret_code == 200) {
					//this.$message.success(res.ret_msg);
					// 下单成功弹窗
					this.orderSuccesschangeVisible(true);
					this.isShow = false;
					this.$parent.getUserAmount();
					this.$parent.getOrderList();
				}
			},
			orderSuccesschangeVisible(flag) {
				this.orderSuccessVisible = flag;
			},
		},
		data() {
			return {
				config_step: 100,
				isShow: false,
				tiered_total_amount: 0, //阶梯计价总金额
				isCheckTiered: false, //是否已进行阶梯计价检测
				tiered_detail: [],
				isFull: false, //是否全量计算 false为单个计算价格
				confirmLoading: false,
				compact: false,
				config_info: null,
				orderSuccessVisible: false,
				entity: {
					linkUrl: '',
					comment: null,
					orderNum: `${this.$lang.GetLangByKey('Amount')} 0${this.$lang.GetLangByKey('PriceUnit')}`,
					time: null,
				},
				selectOptionTime: util.selectOptionTime(),
			};
		},
		setup() {},
		watch: {
			visible: {
				handler(a) {
					this.isShow = a;
				},
			},
			isShow: {
				async handler(a) {
					this.$emit('changeVisible', a);
				},
			},
			code: {
				async handler(value) {
					console.log('窗口1当前配置' + value);
					var res = await orderApi.OrderInfo({ config_code: value });
					this.config_info = res.ret_obj;
					this.isFull =
						this.config_info.config_code == 1007 || this.config_info.config_code == 1003;
					this.config_step = res.ret_obj.config_step;
					console.log('加载数据..' + res);
				},
			},
		},
	};
</script>
<style lang="scss" scoped>
	.buttonGroup {
		padding-top: 25px;
		text-align: left;
	}

	.contentText {
		font-size: 12px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #0086f5;
		line-height: 18px;
		text-align: left;
		width: -webkit-fit-content;
		margin: 0px auto;
		width: 70%;
	}

	.contentWarp {
		:deep(.ant-input-number-handler) {
			border-left: none;
		}

		:deep(.ant-input-number-handler-up) {
			background-image: url(/src/assets/icon/icon-add.png);
			background-size: cover;
			background-color: white;
			width: 48%;
			height: 100% !important;
		}

		:deep(.ant-input-number-handler-down) {
			background-image: url(/src/assets/icon/icon-minus.png);
			background-size: cover;
			background-color: white;
			width: 48%;
			margin-left: 2%;
			height: 100% !important;
			border-top: none;
		}

		:deep(.ant-input-number-handler-wrap) {
			display: flex;
			position: absolute;
			right: -4.6rem;
			width: 4.4rem !important;
			height: 1.4rem;
			top: 0.2rem;
		}

		:deep(.anticon-up > svg) {
			display: none;
		}

		:deep(.anticon-down > svg) {
			display: none;
		}

		.TransactionsModal-wrap-tips {
			padding-left: 10%;
			padding-right: 10%;
		}

		.TransactionsModal-wrap-body {
			width: 85%;
			margin: 0px auto;

			.wrap-body-input {
				width: 100%;
				border-radius: 15px;
				text-align: left;
			}
		}

		::v-deep {
			.ant-modal-content {
				//   min-width: 449px;
				//   min-height: 597px;
				background: #e5f3ff;
				box-shadow: 4px 13px 20px 0px rgba(146, 146, 146, 0.49);
				border-radius: 12px;
			}

			.ant-modal-header {
				background: #e5f3ff;
				border-radius: 12px;
				text-align: center;
				border-bottom: 0px;
			}

			.ant-modal-title {
				font-size: 14px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #0086f5;
				line-height: 20px;
			}

			.ant-input-group.ant-input-group-compact {
				.ant-select:last-child {
					.ant-select-selector {
						border-radius: 15px;
					}
				}
			}
		}
	}

	@media only screen and (max-width: 720px) {
		.contentWarp {
			.TransactionsModal-wrap-tips {
				padding-left: initial;
				padding-right: initial;
			}

			.TransactionsModal-wrap-body {
				width: 90%;
			}

			.contentText {
				width: 90%;
			}
		}
	}
</style>
