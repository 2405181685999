<template>
    <div class="AccountInfoModal-wrap" ref="AccountInfoModal_warp">
        <a-modal v-model:visible="isShow" :title="title" :footer="null"
            :getContainer="() => this.$refs.AccountInfoModal_warp" :afterClose="reset" style="min-width:70%">
            <div class="AccountInfoModal-wrap_header">
                <div style="padding: 0px 50px; cursor: pointer" v-for="item in headers_tips.list" v-bind:key="item.key"
                    @click="HeadTipsOnClick(item.key)">
                    <div :class="item.isSelected
                        ? 'AccountInfoModal-wrap_header_tips_seleted'
                        : 'AccountInfoModal-wrap_header_tips_unseleted'
                        ">
                        {{ item.title }}
                    </div>
                    <div :class="item.isSelected ? 'AccountInfoModal-wrap_header_foot' : ''"></div>
                </div>

            </div>
            <div class="AccountInfoModal-wrap_body">
                <div style="margin:0.5rem 0px 0.5rem 2rem"> <a-button type="primary" @click="downLoad">下载</a-button></div>
                <comm-table ref="withdrawInfoTable" :columns="datatable.columns" :dataSource="datatable.dataSource"
                    :tabDataBind="tabDataBind" :rowKey="(record) => record.id" :total="datatable.total">
                </comm-table>
            </div>
        </a-modal>
    </div>
</template>
<script>
import commTable from "@/components/table/commTable.vue";
import orderApi from '../../http/apiUtil/orderApi';
import { nanoid } from "nanoid";
import util from "@/common/util";
export default {
    props: {
        title: { type: String, default: "详情" },
        visible: { type: Boolean, default: false },
        order_no: { type: String, default: null },
    },
    components: {
        commTable,
    },
    data() {
        return {
            isShow: false,
            headers_tips: {
                list: [
                    {
                        title: "账户详情",
                        key: 1,
                        isSelected: true,
                    },
                    // {
                    //     title: "差额明细",
                    //     key: 2,
                    //     isSelected: false,
                    // },
                ],
            },
            datatable: {
                columns: [],
                dataSource: [],
                total: 0,
            },
        };
    },
    mounted() {


    },
    methods: {
        reset() { },
        //查询列表
        async searchBtn(page) {
            this.$refs["withdrawInfoTable"].reset(page);
        },
        tabDataBind(page = 1, size = 10, field, order) {
            console.log(`page:${page} ,size:${size},field:${field},order：${order}`);
            let key = this.headers_tips.list.find((t) => t.isSelected === true).key;
            switch (key) {
                case 1:
                    this.bindWithdrawData();
                    break;
                case 2:
                    //   this.bindMarginData();
                    break;
                default:
                    break;
            }
        },
        async HeadTipsOnClick(key) {
            this.headers_tips.list.forEach((item) => {
                item.isSelected = false;
                if (item.key === key) {
                    item.isSelected = true;
                }
            });
            switch (key) {
                case 1:
                    await this.withdrawConfig();
                    break;
                case 2:
                    //       await this.marginConfig();
                    break;
                default:
                    break;
            }
            this.searchBtn(1);
        },
        withdrawConfig() {
            this.datatable.columns = [
                {
                    title: "注册国家",
                    dataIndex: "config_name",
                    key: "config_name",

                },
                {
                    title: "邮箱+密码",
                    dataIndex: "account_remark",
                    key: "account_remark",

                },
                {
                    title: "TiKTok账号",
                    dataIndex: "tk_account_no",
                    key: "tk_account_no",
                },
                {
                    title: "TiKTok密码",
                    dataIndex: "tk_account_password",
                    key: "tk_account_password",

                },
                {
                    title: "注册日期",
                    dataIndex: "account_create_time",
                    key: "account_create_time",
                    customRender: (obj) => {
                        let value = this.$dayjs(obj.text).format("YYYY-MM-DD");;
                        return value || obj.text;
                    },
                },

            ];
        },

        // marginConfig() {
        //     this.datatable.columns = [
        //         {
        //             title: "业务时间",
        //             dataIndex: "summarydate",
        //             key: "summarydate",
        //             customRender: (obj) => {
        //                 return this.$dayjs(obj.text).format("YYYY-MM-DD");
        //             },
        //         },
        //         {
        //             title: "订单号",
        //             dataIndex: "order_no",
        //             key: "order_no",
        //         },
        //         {
        //             title: "差额收益",
        //             dataIndex: "margin_amout",
        //             key: "margin_amout",
        //             customRender: (obj) => {
        //                 if (obj.column.key === "margin_amout") {
        //                     return this.$util.formatPrice(obj.text);
        //                 }
        //             },
        //         },
        //     ];
        // },
        async bindWithdrawData(page, size) {
            let param = {
                page: page,
                size: size,
                order_no: this.order_no,
            };
            let res = await orderApi.GetShoppingAccountList(param);
            if (res.ret_code === 200) {
                this.datatable.dataSource = res.ret_obj;
                this.datatable.total = res.ret_count;
            }
        },
        // async bindMarginData(page, size) {
        //     let param = {
        //         page: page,
        //         size: size,
        //         date: this.date,
        //     };
        //     let res = await userApi.GetMarginBenfitInfo(param);
        //     if (res.ret_code === 200) {
        //         this.datatable.dataSource = res.ret_obj.details;
        //         this.datatable.total = res.ret_count;
        //     }
        // },
        downLoad() {
            var userid = util.getCookieUserKey("userid");
            console.log(userid)
            window.location.href = process.env.VUE_APP_BASE_URL + `/api/order/DownLoadAccount?user_id=${util.getCookieUserKey("userid")}&order_no=${this.order_no}&token=${util.getCookieUserKey("token")}&r=${nanoid()}`
        },
    },
    setup() { },
    watch: {
        visible: {
            handler(a) {
                this.isShow = a;
            },
        },
        isShow: {
            handler(a) {
                this.$emit("changeVisible", a);
            },
        },
        order_no: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.HeadTipsOnClick(1);
                }
            },
        },
    },
};
</script>
<style lang="scss" scoped>
.AccountInfoModal-wrap {
    :deep(.ant-modal-content) {
        border-radius: 12px;
        height: 100%;
    }

    :deep(.ant-modal-header) {
        text-align: center;
        background-color: #daf1ff;
        border-radius: 12px 12px 0px 0px;
    }

    :deep(.ant-modal-title) {
        color: #0086f5;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
    }

    :deep(.ant-modal) {
        height: auto;
    }

    .AccountInfoModal-wrap_header {
        padding-bottom: 0.7rem;
        display: flex;

        .AccountInfoModal-wrap_header_tips_seleted {
            width: 57px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #0086f5;
            line-height: 20px;
        }

        .AccountInfoModal-wrap_header_tips_unseleted {
            width: 57px;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #949494;
            line-height: 20px;
        }

        .AccountInfoModal-wrap_header_foot {
            width: 57px;
            height: 3px;
            background: #0086f5;
            box-shadow: 0px 2px 4px 0px rgba(0, 134, 245, 0.31);
            border-radius: 3px;
        }
    }
}
</style>
  