<template>
  <div class="contentWarp">
    <div class="content_header">
      <div
        v-for="(item, index) in headers"
        v-bind:key="index"
        class="header"
        :style="{ width: 100 / headers.length + '%' }"
      >
        <div class="header_item" :style="{ background: item.background }"></div>
        <div style="display: inline-block">
          <div class="header_item_1" v-if="item.key === 'Total revenue'">
            {{ totalRevenue }}{{ item.unit }}
          </div>
          <div class="header_item_1" v-else-if="item.key === `Today revenue`">
            {{ todaysEarnings.toFixed(2) }}{{ item.unit }}
          </div>
          <div
            class="header_item_1"
            v-else-if="item.key === 'Number of orders today'"
          >
            {{ orderTodayNum.toFixed(2) }}{{ item.unit }}
          </div>
          <div
            class="header_item_1"
            v-else-if="item.key === 'Subordinate account'"
          >
            {{ SubordinateNum.toFixed(2) }}{{ item.unit }}
          </div>
          <div class="header_item_2">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="content_body">
      <!-- 搜索模块 -->
      <div class="search_bar">
        <div class="search_bar_item">
          <div class="search_bar_item_label">订单编号：</div>
          <div class="">
            <a-input
              v-model:value="searchModel.orderNo"
              placeholder="订单编号"
            />
          </div>
        </div>
        <div class="search_bar_item">
          <div class="search_bar_item_label">TK号或视频链接：</div>
          <div class="">
            <a-input
              v-model:value="searchModel.videoLink"
              placeholder="TK号或视频链接"
            />
          </div>
        </div>
        <div class="search_bar_item">
          <div class="search_bar_item_label">业务名称：</div>
          <div class="">
            <a-input-group compact>
              <a-select
                v-model:value="searchModel.configCode"
                placeholder="请选择赛道"
                showSearch="true"
                :filter-option="
                  (input, option) => {
                    return (
                      option.text.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    );
                  }
                "
              >
                <a-select-option
                  :text="item.config_name"
                  :key="item.config_code"
                  v-for="item in configList"
                  :value="item.config_code"
                  >{{ item.config_name }}</a-select-option
                >
              </a-select>
            </a-input-group>
          </div>
        </div>
        <div class="search_bar_item">
          <div class="search_bar_item_label">执行状态：</div>
          <div class="">
            <a-input-group compact>
              <a-select
                v-model:value="searchModel.order_state"
                placeholder="执行状态"
                showSearch="true"
                :filter-option="
                  (input, option) => {
                    return (
                      option.text.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    );
                  }
                "
              >
                <a-select-option
                  :text="item.key"
                  :key="item.key"
                  v-for="item in order_state_menu"
                  :value="item.key"
                  >{{ item.text }}</a-select-option
                >
              </a-select>
            </a-input-group>
          </div>
        </div>
        <div class="search_bar_item">
          <div class="search_bar_item_label">时间：</div>
          <div class="">
            <a-input-group compact>
              <a-range-picker
                v-model:value="searchModel.time"
                :format="dateFormat"
              />
            </a-input-group>
          </div>
        </div>
        <div class="search_bar_item_button">
          <button class="button_blue" @click="onPageChange(1, null)">
            搜索
          </button>
        </div>
      </div>
      <a-table
        :dataSource="datatable.dataSource"
        :columns="datatable.columns"
        :pagination="datatable.pagination"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'operate'">
            <a-button
              v-if="record.order_type === 1004 || record.order_type === 21004"
              style="border-radius: 15px; margin-right: 0.7rem"
              type="primary"
              @click="showOrderCommentInfoModal(true, record)"
              >评论信息</a-button
            >
            <div style="display: flex" v-if="record.order_type == 1002">
              <a
                class="icon danmu-close-icon"
                title="暂停订单"
                v-if="record.order_state == 1"
                @click="OrderPause(record.order_no)"
              ></a>
              <a
                class="icon danmu-open-icon"
                title="开启订单"
                v-if="record.order_state == 3"
                @click="OrderUnPause(record.order_no)"
              ></a>
              <a
                class="icon add-time-icon"
                @click="delayTimeModalShow(true, record.order_no)"
                title="订单延长"
                v-if="record.order_state == 1"
              ></a>
              <a
                class="icon modify-comment-icon"
                @click="modifyCommentModalShow(true, record.order_no)"
                title="更换话术"
                v-if="record.order_state == 1"
              ></a>
              <a
                class="icon comment-frequency-icon"
                @click="commentFrequencyModalShow(true, record.order_no)"
                title="发言频率"
                v-if="record.order_state == 1"
              ></a>
            </div>
            <div v-if="record.order_type == 1001">
              <a
                class="icon add-time-icon"
                @click="delayTimeModalShow(true, record.order_no)"
                title="订单延长"
                v-if="record.order_state == 1"
              ></a>
            </div>
            <span
              v-if="record.business_type == 3"
              style="color: red; text-decoration: underline; cursor: pointer"
              @click="showAccountInfoModal(true, record)"
              >账号信息</span
            >
            <span
              v-if="
                record.order_type == 1001 ||
                record.order_type == 1005 ||
                record.order_type == 1006 ||
                record.order_type == 1007 ||
                record.order_type == 21005 ||
                record.order_type == 21006 ||
                record.order_type == 21007
              "
            >
              <a
                class="icon data-icon"
                @click="orderInitAndEndNoModalShow(true, record.order_no)"
                title="初始数据和结束数据"
                v-if="record.order_state == 2"
              ></a>
              <a
                class="icon abort-icon"
                @click="orderAbort(record.order_no)"
                title="停单"
                v-if="
                  (record.order_state == 1 || record.order_state == 4) &&
                  (record.order_type == 1006 || record.order_type == 21006)
                "
              ></a>
            </span>
          </template>
        </template>
      </a-table>
    </div>
    <account-info-modal
      v-model:visible="accountInfoModalEntity.isShow"
      :order_no="accountInfoModalEntity.orderNo"
      @changeVisible="showAccountInfoModal"
    >
    </account-info-modal>
    <order-comment-info-modal
      v-model:visible="orderCommentInfoModalData.isShow"
      @changeVisible="showOrderCommentInfoModal"
      :orderNo="orderCommentInfoModalData.orderNo"
    >
    </order-comment-info-modal>
    <modify-comment-modal
      v-model:visible="modifyCommentModalEntity.isShow"
      @changeVisible="modifyCommentModalShow"
      :submit="OrderModifyComments"
      :orderNo="orderNo"
    ></modify-comment-modal>
    <delay-time-modal
      v-model:visible="delayTimeModalEntity.isShow"
      @changeVisible="delayTimeModalShow"
      :submit="OrderDelayTime"
      :orderNo="orderNo"
    ></delay-time-modal>
    <comment-frequency-modal
      v-model:visible="commentFrequencyModalEntity.isShow"
      @changeVisible="commentFrequencyModalShow"
      :submit="OrderCommentsFrequency"
      :orderNo="orderNo"
    ></comment-frequency-modal>
    <order-init-and-end-no-modal
      v-model:visible="orderInitAndEndNoModalEntity.isShow"
      @changeVisible="orderInitAndEndNoModalShow"
      :orderNo="orderNo"
    ></order-init-and-end-no-modal>
  </div>
</template>

<script>
import gsap from "gsap";
import util from "@/common/util";
import dayjs from "dayjs";
import orderApi from "@/http/apiUtil/orderApi";
import userApi from "@/http/apiUtil/userApi";
import { h } from "vue";
import "@/css/icon.scss";

import modifyCommentModal from "@/components/order/modifyCommentModal.vue";
import delayTimeModal from "@/components/order/delayTimeModal.vue";
import commentFrequencyModal from "@/components/order/commentFrequencyModal.vue";
import orderCommentInfoModal from "@/components/order/orderCommentInfoModal.vue";
import orderInitAndEndNoModal from "@/components/order/orderInitAndEndNoModal.vue";
import AccountInfoModal from "@/components/shopping/AccountInfoModal.vue";
export default {
  components: {
    orderCommentInfoModal,
    modifyCommentModal,
    delayTimeModal,
    commentFrequencyModal,
    orderInitAndEndNoModal,
    AccountInfoModal,
  },
  props: {},
  data() {
    return {
      order_state_menu: [
        {
          key: null,
          text: "请选择",
        },
        {
          key: 1,
          text: "执行中",
        },
        {
          key: 2,
          text: "已完成",
        },
        {
          key: 3,
          text: "暂停",
        },
        {
          key: 4,
          text: "排队中",
        },
        {
          key: 5,
          text: "退费单",
        },
        {
          key: 9,
          text: "问题单",
        },
        {
          key: 99,
          text: "异常",
        },
      ],
      orderNo: null,
      totalRevenue: 0,
      todaysEarnings: 0,
      orderTodayNum: 0,
      SubordinateNum: 0,
      dateFormat: "YYYY/MM/DD",
      searchModel: {
        orderNo: null,
        videoLink: null,
        configCode: null,
        time: null,
        order_state: null,
      },
      modifyCommentModalEntity: {
        isShow: false,
      },
      orderInitAndEndNoModalEntity: {
        isShow: false,
      },
      delayTimeModalEntity: {
        isShow: false,
      },
      commentFrequencyModalEntity: {
        isShow: false,
      },
      accountInfoModalEntity: {
        isShow: false,
        orderNo: null,
      },
      orderCommentInfoModalData: {
        isShow: false,
        orderNo: null,
      },
      configList: [],
      headers: [
        {
          title: this.$lang.GetLangByKey("TotalRevenue"),
          key: "Total revenue",
          unit: this.$lang.GetLangByKey("PriceUnit"),
          background: "linear-gradient(180deg, #FFBCB0 0%, #FF8778 100%)",
        },
        {
          title: this.$lang.GetLangByKey("TodayRevenue"),
          key: "Today revenue",
          unit: this.$lang.GetLangByKey("PriceUnit"),
          background: "linear-gradient(360deg, #44D7B6 0%, #C3E9D5 100%)",
        },
        {
          title: this.$lang.GetLangByKey("TodayOrderCount"),
          key: "Number of orders today",
          background: "linear-gradient(150deg, #83CBFF 0%, #007AFF 100%)",
        },
        // {
        //   title: "Subordinate account",
        //   background: "linear-gradient(180deg, #F9D693 0%, #E39125 100%)",
        // },
      ],
      datatable: {
        dataSource: [],
        columns: [
          {
            title: this.$lang.GetLangByKey("OrderNumber"),
            dataIndex: "order_no",
            key: "order_no",
          },
          {
            title: "TK号或视频链接",
            dataIndex: "video_link",
            key: "video_link",
            width: "350px",
            customRender: (obj) => {
              let value = obj.text;
              if (!obj.text) {
                value = "无";
              }
              return value || obj.text;
            },
          },
          {
            title: this.$lang.GetLangByKey("OrderAmount"),
            dataIndex: "order_amout",
            key: "order_amout",
          },
          {
            title: this.$lang.GetLangByKey("BusinessName"),
            dataIndex: "config_name",
            key: "config_name",
            width: "100px",
          },
          {
            title: this.$lang.GetLangByKey("OrderCount"),
            dataIndex: "order_robot_count",
            key: "order_robot_count",
          },
          {
            title: "退费",
            dataIndex: "refund_amout",
            key: "refund_amout",
            customRender: (obj) => {
              let value = obj.text;
              return value / 100;
            },
          },
          {
            title: "发言频率",
            dataIndex: "order_speed_disc",
            key: "order_speed_disc",
            width: "80px",
          },
          {
            title: "订单状态",
            dataIndex: "order_state",
            key: "order_state",
            customRender: (obj) => {
              let value = "";
              value = util.getOrderState(obj.text, obj.record.refund_amout);

              return value || obj.text;
            },
            width: "200px",
          },
          {
            title: this.$lang.GetLangByKey("Time"),
            dataIndex: "order_time",
            key: "order_time",
            width: "150px",
          },
          {
            title: "操作",
            dataIndex: "operate",
            key: "operate",
            width: "120px",
          },
        ],
        pagination: {
          pageNo: 1,
          pageSize: 10,
          current: 1,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20", "50", "100"],
          showTotal: (total) =>
            `${this.$lang.GetLangByKey(
              "Total"
            )} ${total} ${this.$lang.GetLangByKey("Rows")}`,
          onShowSizeChange: (current, pageSize) =>
            this.onSizeChange(current, pageSize),
          onChange: (page, pageSize) => this.onPageChange(page, pageSize),
          total: 0, //总条数
        },
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    onSizeChange(page, size) {
      this.datatable.pagination.current = page;
      this.datatable.pagination.pageNo = page;
      this.datatable.pagination.pageSize = size;
      //this.getOrderList();
    },
    onPageChange(page, size) {
      this.datatable.pagination.current = page;
      this.datatable.pagination.pageNo = page;
      this.datatable.pagination.pageSize =
        size || this.datatable.pagination.pageSize;
      this.getOrderList();
    },
    async getOrderList() {
      let that = this.searchModel;
      console.log(that);
      let param = {
        order_userid: this.$cookies.get("userid"),
        page: this.datatable.pagination.pageNo,
        size: this.datatable.pagination.pageSize,
        config_code: this.searchModel.configCode,
        order_no: this.searchModel.orderNo,
        video_link: this.searchModel.videoLink,
        startime: null,
        endtime: null,
        order_state: this.searchModel.order_state,
      };
      //时间
      if (this.searchModel.time != null) {
        param.startime = this.$dayjs(this.searchModel.time[0]).format(
          "YYYY-MM-DD 00:00:00"
        );
        param.endtime = this.$dayjs(this.searchModel.time[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      var orderRes = await orderApi.OrderList(param);
      if (orderRes.ret_code == 200) {
        if (orderRes.ret_obj.length > 0) {
          orderRes.ret_obj.forEach((item) => {
            item.order_amout =
              util.formatPrice(item.order_amout) +
              this.$lang.GetLangByKey("PriceUnit");
            item.order_time = this.$dayjs(item.order_time).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          });
        }
        this.datatable.dataSource = orderRes.ret_obj;
        this.datatable.pagination.total = orderRes.ret_count;
      }
    },
    showOrderCommentInfoModal(flag, data) {
      this.orderCommentInfoModalData.isShow = flag;
      if (data) {
        this.orderCommentInfoModalData.orderNo = data.order_no;
        console.log(data);
      }
    },
    showAccountInfoModal(flag, data) {
      this.accountInfoModalEntity.isShow = flag;
      if (data) {
        this.accountInfoModalEntity.orderNo = data.order_no;
        console.log(data);
      }
    },
    async bindConfig() {
      let param = {};
      param.business_type = 1;
      var res = await userApi.ConfigInfo(param);
      this.configList = [];
      this.configList.push({
        config_name: "请选择",
        config_code: null,
      });
      if (res.ret_obj) {
        res.ret_obj.forEach((item) => {
          this.configList.push(item);
        });
      }
    },
    showShoppingModal() {},
    async getOrderStatistics() {
      let param = {
        order_userid: this.$cookies.get("userid"),
        page: this.datatable.pagination.pageNo,
        size: this.datatable.pagination.pageSize,
      };
      param.order_time = dayjs().format("YYYY-MM-DD");
      var statisticsRes = await orderApi.OrderStatistics(param);
      if (statisticsRes.ret_code == 200) {
        gsap.to(this, {
          duration: 1.0,
          totalRevenue: util.formatPrice(statisticsRes.ret_obj.totalRevenue),
        });
        gsap.to(this, {
          duration: 1.0,
          todaysEarnings: util.formatPrice(statisticsRes.ret_obj.todayRevenue),
        });
        gsap.to(this, {
          duration: 1.0,
          orderTodayNum: statisticsRes.ret_obj.numserOfOrderToday,
        });
        gsap.to(this, { duration: 1.0, SubordinateNum: 0 });
      }
    },
    //订单暂停
    OrderPause(order_no) {
      console.log(order_no);
      let that = this;
      this.$modal.confirm({
        content: h(
          "div",
          {
            style: {
              "font-size": "14px",
              "font-weight": "600",
            },
          },
          [
            h("div", "订单暂停发言，但会持续扣费哦"),
            h(
              "div",
              { style: "margin-left:calc(1rem + 22px);" },
              "是否确认 暂停 订单？"
            ),
          ]
        ),
        async onOk() {
          let param = {};
          param.order_no = order_no;
          let res = await orderApi.OrderPause(param);
          if (res.ret_code == 200) {
            that.$message.success("操作成功");
            that.getOrderList();
          }
        },
        cancelText: "取消",
        onCancel() {},
      });
    },
    //订单重启
    OrderUnPause(order_no) {
      let that = this;
      console.log(order_no);
      this.$modal.confirm({
        content: "是否确认 开启 订单？",
        async onOk() {
          let param = {};
          param.order_no = order_no;
          let res = await orderApi.OrderUnpause(param);
          if (res.ret_code == 200) {
            that.$message.success("操作成功");
            that.getOrderList();
          }
        },
        cancelText: "取消",
        onCancel() {},
      });
    },
    //订单延时
    async OrderDelayTime(param = {}) {
      console.log(param);
      let res = await orderApi.OrderDelayTime(param);
      if (res.ret_code == 200) {
        this.$message.success("操作成功");
        this.getOrderList();
        this.delayTimeModalShow(false, "");
      }
    },
    //更换弹幕内容
    async OrderModifyComments(param = {}) {
      console.log(param);
      let res = await orderApi.OrderModifyComments(param);
      if (res.ret_code == 200) {
        this.$message.success("操作成功");
        this.getOrderList();
        this.modifyCommentModalShow(false, "");
      }
    },
    //评论频率更改
    async OrderCommentsFrequency(param = {}) {
      console.log(param);
      let res = await orderApi.OrderCommentsFrequency(param);
      if (res.ret_code == 200) {
        this.$message.success("操作成功");
        this.getOrderList();
        this.commentFrequencyModalShow(false, "");
      }
    },
    //终止订单
    async orderAbort(order_no) {
      let that = this;
      this.$modal.confirm({
        content: h(
          "div",
          {
            style: {
              "font-size": "14px",
              "font-weight": "600",
            },
          },
          [
            h("div", "订单终止，已完成数量收费，未完成数量将于凌晨自动退费"),
            h(
              "div",
              { style: "margin-left:calc(1rem + 22px);" },
              "是否确认 终止 订单？"
            ),
          ]
        ),
        async onOk() {
          let res = await orderApi.OrderAbort({ order_no: order_no });
          if (res.ret_code == 200) {
            that.$message.success("操作成功");
            that.getOrderList();
          }
        },
        cancelText: "取消",
        onCancel() {},
      });
    },
    //评论更换
    modifyCommentModalShow(flag, orderNo) {
      this.orderNo = orderNo;
      this.modifyCommentModalEntity.isShow = flag;
    },
    //订单延长弹窗
    delayTimeModalShow(flag, orderNo) {
      this.orderNo = orderNo;
      this.delayTimeModalEntity.isShow = flag;
    },
    //发言频率弹窗
    commentFrequencyModalShow(flag, orderNo) {
      console.log(orderNo);
      this.orderNo = orderNo;
      this.commentFrequencyModalEntity.isShow = flag;
    },
    //初始数据和结束数据
    orderInitAndEndNoModalShow(flag, orderNo) {
      console.log(orderNo);
      this.orderNo = orderNo;
      this.orderInitAndEndNoModalEntity.isShow = flag;
    },
  },
  created() {},
  async mounted() {
    this.getOrderList();
    this.getOrderStatistics();
    this.bindConfig();
  },
};
</script>

<style lang="scss" scoped>
.contentWarp {
  width: 100%;
  height: 100%;
  background-color: white;

  :deep(.ant-input) {
    min-width: 150px;
    min-height: 32px;
    border-radius: 6px;
  }

  .ant-input-group.ant-input-group-compact
    > .ant-select:last-child
    > :deep(.ant-select-selector) {
    min-width: 150px;
    border-radius: 6px;
    min-height: 32px;
  }

  .ant-input-group.ant-input-group-compact > * {
    border-radius: 6px;
  }

  .content_header {
    padding: 2%;
    display: flex;

    .header {
      .header_item {
        width: 10px;
        height: 72px;
        background: linear-gradient(180deg, #ffbcb0 0%, #ff8778 100%);
        border-radius: 4px;
        display: inline-block;
      }

      .header_item_1 {
        width: 130px;
        height: 50px;
        font-size: 36px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #0086f5;
        line-height: 50px;
        padding-left: 1rem;
      }

      .header_item_2 {
        width: 174px;
        height: 33px;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #0086f5;
        line-height: 33px;
        padding-left: 1rem;
        white-space: nowrap;
        /* overflow: hidden; */
        text-overflow: ellipsis;
      }
    }
  }

  .content_body {
    ::v-deep {
      .ant-table-cell {
        border-bottom: 1px solid rgba(0, 134, 245, 0.34);
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #0086f5;
      }

      .ant-table-wrapper {
        clear: both;
        max-width: 100%;
        padding: 0px 2% 0px 2%;
      }
    }
  }
}
</style>
