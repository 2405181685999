import { createRouter, createWebHashHistory } from "vue-router";

import Login from "@/views/account/Login.vue";
import Register from "@/views/account/Register.vue";
import HomePage from "@/views/index/HomePage.vue";
import OrderRecord from "@/views/order/OrderRecord.vue";
import pay from "@/views/order/Pay.vue";
import payCallBack from "@/views/order/PayCallBack.vue";
import Transactions from "@/views/order/Transactions.vue";
import invateIndex from "@/views/invate/invateIndex.vue";
import earningsIndex from "@/views/earnings/earningsIndex.vue";
import proxyConfig from "@/views/earnings/proxyConfig.vue";
import modalTest from "@/views/test/modalTest.vue";
import IndexLayer from "@/views/index/indexLayer.vue";
import shoppingIndex from "@/views/shopping/shoppingIndex.vue";

import put from "@/views/advertising/put.vue";

import MLogin from "@/views/mobile/m_account/m_Login.vue";
import MRegister from "@/views/mobile/m_account/m_Register.vue";
import MHome from "@/views/mobile/m_index/home.vue";
import MIndex from "@/views/mobile/m_index/index.vue";
import MOrder from "@/views/mobile/order/OrderDetails.vue";
import MOrderList from "@/views/mobile/order/OrderList.vue";

import MPaymentList from "@/views/mobile/user/PaymentList.vue";
import MUserCenter from "@/views/mobile/user/UserCenter.vue";
import MUserIndex from "@/views/mobile/user/UserIndex.vue";

import MAgentIndex from "@/views/mobile/agent/AgentIndex.vue";
import MRebateIncome from "@/views/mobile/agent/RebateIncome.vue";
import MConsumeList from "@/views/mobile/agent/ConsumeList.vue";
import MWithdrawList from "@/views/mobile/agent/WithdrawList.vue";
import MUserList from "@/views/mobile/agent/UserList.vue";
import MProxyConfigList from "@/views/mobile/agent/ProxyConfigList.vue";
import MShoppingIndex from "@/views/mobile/shopping/shoppingIndex.vue";

const routes = [{
        path: "/",
        redirect: { path: "/Index/HomePage" },
    },
    {
        path: "/Login",
        name: "Login",
        component: Login,
    },
    {
        path: "/Register",
        name: "Register",
        component: Register,
    },
    {
        path: "/PayCallBack",
        name: "/PayCallBack",
        component: payCallBack,
    },
    {
        path: "/Index",
        name: "Index",
        component: IndexLayer,
        children: [{
                path: "/Index/HomePage",
                name: "/Index/HomePage",
                component: HomePage,
            },
            {
                path: "/Index/Transactions",
                name: "/Index/Transactions",
                component: Transactions,
            },
            {
                path: "/Index/shoppingIndex",
                name: "/Index/shoppingIndex",
                component: shoppingIndex,
            },
            {
                path: "/Index/OrderRecord",
                name: "/Index/OrderRecord",
                component: OrderRecord,
            },

            {
                path: "/Index/invateIndex",
                name: "/Index/invateIndex",
                component: invateIndex,
            },
            {
                path: "/Index/earnings",
                name: "/Index/earnings",
                component: earningsIndex,
                children: [{
                    path: "/Index/earnings/proxyConfig",
                    name: "/Index/earnings/proxyConfig",
                    component: proxyConfig,
                }, ],
            },
        ],
    },
    {
        path: "/pay",
        name: "/pay",
        component: pay,
    },
    {
        path: "/test/index",
        name: "/test/index",
        component: modalTest,
    },
    {
        path: "/advertising",
        name: "advertising",
        children: [{
            path: "/advertising/put",
            name: "/advertising/put",
            component: put,
        }, ],
    },

    //手机端地址
    {
        path: "/Mobile/Login",
        name: "/Mobile/Login",
        component: MLogin,
    },
    {
        path: "/Mobile/Register",
        name: "/Mobile/Register",
        component: MRegister,
    },

    {
        path: "/Mobile/Index",
        name: "/Mobile/Index",
        component: MIndex,
        children: [{
                path: "/Mobile/Home",
                name: "/Mobile/Home",
                component: MHome,
            },
            {
                path: "/Mobile/Order",
                name: "/Mobile/Order",
                component: MOrder,
                children: [{
                        path: "/Mobile/Order/OrderList",
                        name: "/Mobile/Order/OrderList",
                        component: MOrderList,
                    },
                    {
                        path: "/Mobile/Order/paymentList",
                        name: "/Mobile/Order/paymentList",
                        component: MPaymentList,
                    },
                ],
            },
            {
                path: "/Mobile/Agent",
                name: "/Mobile/Agent",
                component: MAgentIndex,
                children: [{
                        path: "/Mobile/Agent/RebateIncome",
                        name: "/Mobile/Agent/RebateIncome",
                        component: MRebateIncome,
                    },
                    {
                        path: "/Mobile/Agent/ConsumeList",
                        name: "/Mobile/Agent/ConsumeList",
                        component: MConsumeList,
                    },
                    {
                        path: "/Mobile/Agent/WithdrawList",
                        name: "/Mobile/Agent/WithdrawList",
                        component: MWithdrawList,
                    },
                    {
                        path: "/Mobile/Agent/UserList",
                        name: "/Mobile/Agent/UserList",
                        component: MUserList,
                    },
                    {
                        path: "/Mobile/Agent/ProxyConfigList",
                        name: "/Mobile/Agent/ProxyConfigList",
                        component: MProxyConfigList,
                    },
                ],
            },
            {
                path: "/Mobile/User",
                name: "/Mobile/User",
                component: MUserIndex,
                children: [{
                        path: "/Mobile/User/UserCenter",
                        name: "/Mobile/User/UserCenter",
                        component: MUserCenter,
                    },
                    {
                        path: "/Mobile/User/PaymentList",
                        name: "/Mobile/User/PaymentList",
                        component: MPaymentList,
                    },
                ],
            },
            {
                path: "/Mobile/Shpping",
                name: "/Mobile/Shpping",
                children: [{
                    path: "/Mobile/Shpping/ShppingIndex",
                    name: "/Mobile/Shpping/ShppingIndex",
                    component: MShoppingIndex,
                }, ],
            },
        ],
    },
];

// import put from "@/views/advertising/put.vue";
// import put_en from "@/views/advertising/put-en.vue";
// const routes = [{
//         path: "/",
//         name: "home",
//         component: put,
//     },
//     {
//         path: "/en",
//         name: "en",
//         component: put_en,
//     },
//     {
//         path: "/cn",
//         name: "cn",
//         component: put,
//     },
// ];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;