<template>
    <div class="modifyCommentModal-wrap" ref="modifyCommentModal_warp">
        <a-modal v-model:visible="isShow" :title="title" :footer="null" :confirm-loading="confirmLoading"
            :getContainer="() => this.$refs.modifyCommentModal_warp" :afterClose="reset">
            <div class="wrap-content">
                <div style="margin: 0px auto 1rem auto;
    min-width: 70%;
    text-align: center;font-weight: 600;
    color: #0086f5;">
                    <div>结束数量在订单完成后需等待十分钟左右才会显示。</div>
                </div>
                <div class="wrap-content-item">
                    <span>初始数量</span>
                    <a-input v-model:value="entity.init" disabled="disabled" style="background:white;color:black"></a-input>
                </div>
                <div class="wrap-content-item">
                    <span>结束数量</span>
                    <a-input v-model:value="entity.end" disabled="disabled" style="background:white;color:black"></a-input>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
import orderApi from "@/http/apiUtil/orderApi";

export default {
    props: {
        title: { type: String, default: "初始数据和结束数据" },
        visible: { type: Boolean, default: false },
        orderNo: { type: String, default: null },
    },
    data() {
        return {
            isShow: false,
            entity: {
                init: 0,
                end: 0,
            },
        };
    },
    mounted() {
    },
    methods: {
        reset() {
        },


    },
    setup() { },
    watch: {
        visible: {
            immediate: true,
            handler(a) {
                this.isShow = a;
            },
        },
        isShow: {
            handler(a) {
                this.$emit("changeVisible", a, this.orderNo);
            },
        },
        orderNo: {
            immediate: true,
            async handler(value) {
                console.log(value);
                if (value) {
                    let param = {};
                    param.order_no = value;
                    var res = await orderApi.GetOrderExecuteNumber(param);
                    if (res.ret_code == 200) {
                        this.entity.init = res.ret_obj.init_count;
                        this.entity.end = res.ret_obj.end_count;
                    }
                }
            },
        },
    },
};
</script>
<style lang="scss" scoped>
.ant-button {
    background: #0086F5;
    border-radius: 14px;
    color: rgba(255, 255, 255, 0.85);
}

.modifyCommentModal-wrap {
    ::v-deep {
        .ant-modal-content {
            //   min-width: 449px;
            //   min-height: 597px;
            background: #e5f3ff;
            box-shadow: 4px 13px 20px 0px rgba(146, 146, 146, 0.49);
            border-radius: 12px;
        }

        .modifyCommentModal-wrap_header {
            padding-left: 10%;
            padding-right: 10%;

            .modifyCommentModal-wrap_header_hint {
                font-size: 12px;
                font-family: PingFangSC-Semibold, PingFang SC;
                text-align: left;
                background: #cde8ff;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07),
                    inset 0px 1px 4px 0px rgba(0, 80, 157, 0.77);
                border-radius: 8px;
                border: 1px solid #0075f3;
                text-align: center;
                line-height: 38px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #0086f5;
            }
        }

        .modifyCommentModal-wrap_databody {
            .modifyCommentModal-wrap_databody_tips {
                padding-top: 0px;
                padding-left: 50%;
                font-size: 12px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #0091ff;

                .exceed {
                    color: red;
                }
            }
        }

        .ant-input-group.ant-input-group-compact {
            .ant-select:last-child {
                .ant-select-selector {
                    border-radius: 15px;
                }
            }
        }

        .ant-modal-header {
            background: #e5f3ff;
            border-radius: 12px;
            text-align: center;
            border-bottom: 0px;
        }

        .ant-modal-title {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #0086f5;
            line-height: 20px;
        }
    }

    :deep(.ant-select-selector) {
        border-radius: 15px !important;
    }

    .wrap-content {
        width: 100%;


        .wrap-content-item {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 1rem;

            span {
                display: grid;
                align-items: center;
                margin-right: 1rem;
                width: 40%;
                text-align: right;
            }

        }
    }

}
</style>
  