<template>
    <div class="m-usercenter-wrap">
        <div class="wrap-operate">
            <a-button type="primary" @click="showmodifyPwdModal(true)">修改密码</a-button>
            <a-button type="primary" danger @click="login">退出登录</a-button>
        </div>
    </div>
    <modify-pwd-modal v-model:visible="modifyPwdModalData.visible" @changeVisible="showmodifyPwdModal" title="修改密码"
        :on-submit="modifyPwd">
    </modify-pwd-modal>
</template>

<script>
import modifyPwdModal from "@/components/user/modifyPwdModal.vue";
import userApi from "@/http/apiUtil/userApi";
export default {
    name: '',
    mixins: [],
    components: { modifyPwdModal },
    props: {},
    data() {
        return {
            modifyPwdModalData: {
                visible: false,
            },
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() {
        this.$emit('onClickMenu', 1)
    },
    methods: {
        login() {
            window.location.href = "/#/mobile/login";
        },
        //修改密码
        async modifyPwd(param) {
            console.log(param);
            let res = await userApi.ModifPwd(param);
            if (res.ret_code == 200) {
                this.$message.success("修改成功");
                this.showmodifyPwdModal(false);
            }
        },
        showmodifyPwdModal(flag) {
            this.modifyPwdModalData.visible = flag;
        },
    }
}
</script>

<style scoped lang="scss">
.m-usercenter-wrap {
    .wrap-operate {
        display: flex;
        justify-content: space-around;
    }
}
</style>
