<template>
  <div class="contentWarp" ref="warp">
    <a-modal
      v-model:visible="isShow"
      :title="title"
      :footer="null"
      :confirm-loading="confirmLoading"
      :getContainer="() => this.$refs.warp"
    >
      <div class="TransactionsModal_two-wrap-tips">
        <p class="contentText" v-html="text"></p>
      </div>

      <div id="button" class="TransactionsModal_two-wrap-body">
        <a-input-group compact class="buttonGroup" style="">
          <a-input
            v-model:value="entity.linkUrl"
            style="width: 100%; border-radius: 15px; text-align: left"
            :placeholder="
              this.code == '1004' || this.code == '1005'
                ? this.$lang.GetLangTipsByKey('ModalVideoLink')
                : this.$lang.GetLangTipsByKey('ModalTkLink')
            "
          />
          <!-- <a-button
            @click="confirmBtn()"
            type="primary"
            style="border-radius: 15px; margin-left: -50px; z-index: 9999"
            >Confirm</a-button
          > -->
        </a-input-group>
        <a-input-group compact class="buttonGroup">
          <a-textarea
            v-model:value="entity.comment"
            :rows="4"
            style="width: 100%; border-radius: 15px; text-align: left"
            @blur="focus"
            @input="focus"
            :placeholder="this.$lang.GetLangTipsByKey('ModalComment')"
          />
          <div>
            {{ this.$lang.GetLangTipsByKey("inputTips") }} {{ lines
            }}{{ this.$lang.GetLangByKey("Lines") }}
          </div>
        </a-input-group>
        <a-input-group compact class="buttonGroup" v-if="code == '1002'">
          <a-input-number
            v-model:value="entity.orderQuantity"
            :onStep="onStep"
            min="0"
            :step="config_step"
            style="width: 70%; border-radius: 15px; text-align: left"
            oninput="value=value.replace(/[^\d]/g, '')"
            @blur="focus"
            :placeholder="this.$lang.GetLangTipsByKey('ModalOrderCount')"
          />
          <span
            v-if="config_info?.config_unit > 1"
            style="line-height: 32px; font-weight: 600"
            >&nbsp;*&nbsp;{{ config_info?.config_unit }}</span
          >
          <div>
            下单[{{
              entity.orderQuantity * config_info?.config_unit || 0
            }}]数量的 {{ title }}
          </div>
        </a-input-group>
        <a-input-group compact class="buttonGroup" v-if="showTime">
          <a-select
            :placeholder="this.$lang.GetLangTipsByKey('ModalOrderDuration')"
            ref="select"
            v-model:value="entity.time"
            style="width: 100%; border-radius: 15px; text-align: left"
            @blur="focus"
            @change="handleChange"
          >
            <a-select-option
              v-for="item in selectOptionTime"
              v-bind:key="item"
              :value="item"
              >{{ item }}H</a-select-option
            >
          </a-select>
        </a-input-group>
        <a-input-group compact class="buttonGroup">
          <a-input
            disabled="disabled"
            v-model:value="entity.orderNum"
            style="
              width: 80%;
              border-radius: 15px;
              background-color: white;
              color: #0086f5ff;
              text-align: center;
            "
            placeholder=""
          />

          <a-button
            type="primary"
            style="border-radius: 15px; margin-left: -50px; z-index: 9999"
            @click="orderBtn()"
            >{{ this.$lang.GetLangByKey("Order") }}</a-button
          >
        </a-input-group>
      </div>
      <promotion-home :promotionType="1" />
      <order-success-modal
        v-model:visible="orderSuccessVisible"
        @changeVisible="orderSuccesschangeVisible"
      ></order-success-modal>
    </a-modal>
  </div>
</template>
<script>
import orderApi from "@/http/apiUtil/orderApi";
import util from "@/common/util.js";
import promotionHome from "@/components/promotion/promotionHome.vue";
import orderSuccessModal from "@/components/order/orderSuccessModal.vue";
import { h } from "vue";
export default {
  components: {
    promotionHome,
    orderSuccessModal,
  },
  props: {
    title: { type: String, default: "" },
    visible: { type: Boolean, default: false },
    price: { type: Number, default: 0 },
    code: { type: String, default: "" },
    showTime: { type: Boolean, default: false },
    text: {
      type: String,
      default: ``,
    },
  },
  mounted() {},
  methods: {
    focus() {
      let list = [];
      this.entity.comment.split("\n").forEach((item) => {
        if (item) {
          list.push(item);
        }
      });
      this.lines = list.length;
      let price = 0;
      if (this.entity.time != null && this.showTime == true) {
        price = this.$util.format1000Price(this.price * this.entity.time);
      }
      if (this.showTime === false) {
        price = this.$util.format1000Price(this.price * list.length);
      }
      //直播间发言 总价 = 单价*时间*机器人数量
      if (this.code == "1002") {
        if (!this.entity.orderQuantity) {
          return;
        }
        price = this.$util.format1000Price(
          this.price * this.entity.time * this.entity.orderQuantity
        );
      }
      console.log(price);
      this.entity.orderNum = `${this.$lang.GetLangByKey(
        "Amount"
      )} ${price}${this.$lang.GetLangByKey("PriceUnit")}`;
    },
    confirmBtn() {
      alert(
        `linkUrl:${this.entity.linkUrl};comment:${this.entity.comment};orderNum:${this.entity.orderNum}`
      );
    },
    async orderBtn() {
      console.log("窗口2当前配置" + this.code);

      if (!this.entity.linkUrl) {
        if (this.code == "1004" || this.code == "1005") {
          this.$message.error(this.$lang.GetLangTipsByKey("ModalVideoLink"));
        } else {
          this.$message.error(this.$lang.GetLangTipsByKey("ModalTkLink"));
        }
        return;
      }
      if (!this.entity.comment) {
        this.$message.error(this.$lang.GetLangTipsByKey("ModalComment"));
        return;
      }
      if (this.showTime === true && this.entity.time === null) {
        this.$message.error(this.$lang.GetLangTipsByKey("ModalOrderDuration"));
        return;
      }
      let list = [];
      this.entity.comment.split("\n").forEach((item) => {
        if (item) {
          list.push(item);
        }
      });
      let param = {};
      param.config_code = this.code;
      if (param.config_code === "1002") {
        if (!this.entity.orderQuantity) {
          this.$message.error(this.$lang.GetLangTipsByKey("ModalOrderCount"));
          return;
        }
        param.order_robot_count = this.entity.orderQuantity;
        if (this.config_info.config_unit > 1) {
          param.order_robot_count =
            param.order_robot_count * this.config_info.config_unit;
        }
      } else {
        param.order_robot_count = list.length;
      }
      param.order_link = this.entity.linkUrl;
      param.live_time = this.entity.time;
      param.succ_comment_list = list;
      console.log(param);

      let that = this;
      if (that.config_info.config_unit == -1) {
        that.$modal.confirm({
          getContainer: () => this.$refs.warp,
          onOk: () => {
            that.placeOrder(param);
          },
          title: h(
            "div",
            {
              style: {
                color: "#0086f5",
                "font-size": "18px",
                "font-weight": "600",
              },
            },
            "提示"
          ),
          content: h(
            "div",
            {
              style: {
                color: "#0086f5",
                "font-size": "14px",
                "font-weight": "600",
              },
            },
            [
              h(
                "p",
                `下单 [ ${
                  that.entity.orderQuantity * that.config_info?.config_unit || 0
                } ] 数量的 ${that.title}`
              ),
              h("p", { style: "color:red" }, "确认下单吗？"),
            ]
          ),
        });
      } else {
        this.placeOrder(param);
      }
    },
    async placeOrder(param) {
      var res = await orderApi.LiveOrderPlace(param);
      if (res.ret_code == 200) {
        // this.$message.success(res.ret_msg);
        // c
        this.orderSuccesschangeVisible(true);
        this.isShow = false;
        this.$parent.getUserAmount();
        this.$parent.getOrderList();
        this.entity = {
          orderQuantity: null,
          linkUrl: "",
          comment: "",
          orderNum: `${this.$lang.GetLangByKey(
            "Amount"
          )} 0${this.$lang.GetLangByKey("PriceUnit")}`,
          time: null,
        };
      } else if (res.ret_code == 202) {
        var h_list = [];
        res.ret_msg.split("<br/>").forEach((item) => {
          h_list.push(h("p", item));
        });
        this.isShow = false;
        const modal = this.$modal.success({
          getContainer: () => this.$refs.warp,
          onOk: () => {
            modal.destroy();
          },
          title: h(
            "div",
            {
              style: {
                color: "#0086f5",
                "font-size": "18px",
                "font-weight": "600",
              },
            },
            "提示"
          ),
          content: h(
            "div",
            {
              style: {
                color: "red",
                "font-size": "14px",
                "font-weight": "600",
              },
            },
            h_list
          ),
        });
      }
    },
    orderSuccesschangeVisible(flag) {
      this.orderSuccessVisible = flag;
    },
  },
  data() {
    return {
      lines: 0,
      config_info: null,
      config_step: 1,
      isShow: false,
      confirmLoading: false,
      compact: false,
      orderSuccessVisible: false,
      entity: {
        orderQuantity: null,
        linkUrl: "",
        comment: "",
        orderNum: `${this.$lang.GetLangByKey(
          "Amount"
        )} 0${this.$lang.GetLangByKey("PriceUnit")}`,
        time: null,
      },
      selectOptionTime: util.selectOptionTime(),
    };
  },
  setup() {},
  watch: {
    visible: {
      handler(a) {
        this.isShow = a;
      },
    },
    isShow: {
      handler(a) {
        this.entity = {
          orderQuantity: null,
          linkUrl: "",
          comment: "",
          orderNum: `${this.$lang.GetLangByKey(
            "Amount"
          )} 0${this.$lang.GetLangByKey("PriceUnit")}`,
          time: null,
        };
        this.$emit("changeVisible", a);
      },
    },
    code: {
      async handler(value) {
        console.log("窗口2当前配置" + value);
        var res = await orderApi.OrderInfo({ config_code: value });
        this.config_info = res.ret_obj;
        this.config_step = res.ret_obj.config_step;
        console.log("加载数据.." + res);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.buttonGroup {
  padding-top: 25px;
  text-align: left;
}

.contentText {
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0086f5;
  line-height: 18px;
  text-align: left;
  width: -webkit-fit-content;
  margin: 0px auto;
  width: 70%;
}

.contentWarp {
  .TransactionsModal_two-wrap-tips {
    padding-left: 10%;
    padding-right: 10%;
  }

  .TransactionsModal_two-wrap-body {
    width: 85%;
    margin: 0px auto;
  }

  :deep(.ant-input-number-handler) {
    border-left: none;
  }

  :deep(.ant-input-number-handler-up) {
    background-image: url(/src/assets/icon/icon-add.png);
    background-size: cover;
    background-color: white;
    width: 48%;
    height: 100% !important;
  }

  :deep(.ant-input-number-handler-down) {
    background-image: url(/src/assets/icon/icon-minus.png);
    background-size: cover;
    background-color: white;
    width: 48%;
    margin-left: 2%;
    height: 100% !important;
    border-top: none;
  }

  :deep(.ant-input-number-handler-wrap) {
    display: flex;
    position: absolute;
    right: -4.6rem;
    width: 4.4rem !important;
    height: 1.4rem;
    top: 0.2rem;
  }

  :deep(.anticon-up > svg) {
    display: none;
  }

  :deep(.anticon-down > svg) {
    display: none;
  }

  ::v-deep {
    .ant-modal-content {
      //   min-width: 449px;
      //   min-height: 597px;
      background: #e5f3ff;
      box-shadow: 4px 13px 20px 0px rgba(146, 146, 146, 0.49);
      border-radius: 12px;
    }

    .ant-input-group.ant-input-group-compact {
      .ant-select:last-child {
        .ant-select-selector {
          border-radius: 15px;
        }
      }
    }

    .ant-modal-header {
      background: #e5f3ff;
      border-radius: 12px;
      text-align: center;
      border-bottom: 0px;
    }

    .ant-modal-title {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0086f5;
      line-height: 20px;
    }
  }
}

@media only screen and (max-width: 720px) {
  .contentWarp {
    .TransactionsModal_two-wrap-tips {
      padding-left: initial;
      padding-right: initial;

      .contentText {
        width: 90%;
      }
    }

    .TransactionsModal_two-wrap-body {
      width: 90%;
    }
  }
}
</style>
