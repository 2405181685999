const PayTypeMenu = [{
        value: 0,
        description: "订单",
    },
    {
        value: 1,
        description: "充值",
    },
    {
        value: 2,
        description: "退费",
    },
    {
        value: 3,
        description: "差额转余额",
    },
    {
        value: 4,
        description: "返佣转余额",
    },
    {
        value: 5,
        description: "赠送",
    },
    {
        value: 6,
        description: "转移",
    },
    {
        value: 7,
        description: "退款",
    },
];

const WithdrawalState = [{
        value: 1,
        description: "申请中",
    },
    {
        value: 2,
        description: "已提现",
    },
];
const UserState = [{
        value: 0,
        description: "停用",
    },
    {
        value: 1,
        description: "启用",
    },
];
const getMenuDesc = (key, value) => {
    let menu = eval(key);
    let description = "";
    if (menu != undefined) {
        let item = menu.find((t) => t.value === value);
        if (item != undefined) {
            description = menu.find((t) => t.value === value).description;
        }
    }
    return description;
};
export default {
    getMenuDesc,
    PayTypeMenu,
    WithdrawalState,
    UserState,
};