<template>
	<div class="batchOrderModal_warp" ref="warp">
		<a-modal
			v-model:visible="isShow"
			:title="title"
			:footer="null"
			:confirm-loading="confirmLoading"
			:getContainer="() => this.$refs.warp"
			:afterClose="reset"
		>
			<div class="batchOrderModal_warp-tips">
				<p class="contentText" v-html="text"></p>
			</div>

			<div class="batchOrderModal_warp_databody">
				<a-input-group compact class="buttonGroup">
					<a-textarea
						v-model:value="entity.comment"
						:rows="6"
						style="border-radius: 15px; text-align: left"
						@blur="focus"
						@input="focus"
						:placeholder="linkPlaceholder"
					/>
				</a-input-group>
				<div class="buttonGroup batchOrderModal_warp_databody_tips">
					<span
						>已输入<span :class="isExceed ? 'exceed' : ''">{{ lines }}</span
						>/200</span
					>
				</div>
				<a-input-group compact class="buttonGroup">
					<a-input
						disabled="disabled"
						v-model:value="entity.disc"
						style="
							width: calc(100% - 3rem);
							border-radius: 15px;
							background-color: white;
							color: #0086f5ff;
						"
						placeholder=""
					/>
					<!-- <a-input v-model:value="entity.test"> </a-input> -->
					<a-button
						type="primary"
						style="border-radius: 15px; margin-left: -50px; z-index: 9999"
						@click="checked()"
						>检测</a-button
					>
				</a-input-group>
				<promotion-home :promotionType="1" />
			</div>
		</a-modal>
	</div>
</template>
<script>
	import promotionHome from '@/components/promotion/promotionHome.vue';
	export default {
		components: {
			promotionHome,
		},
		props: {
			title: { type: String, default: '' },
			visible: { type: Boolean, default: false },
			price: { type: Number, default: 0 },
			code: { type: String, default: null },
			onSubmit: { type: Function, default: null },
			text: {
				type: String,
				default: ``,
			},
		},
		data() {
			return {
				lines: 0,
				isExceed: true,
				isShow: false,
				confirmLoading: false,
				compact: false,
				linkPlaceholder: '',
				entity: {
					comment: '',
					disc: '检测时间预估1-3分钟，请耐心等待',
				},
			};
		},
		mounted() {
			this.focus();
		},
		methods: {
			focus() {
				if (!this.entity.comment) {
					return;
				}
				let list = [];
				this.entity.comment.split('\n').forEach((item) => {
					if (item) {
						list.push(item);
					}
				});
				this.lines = list.length;
				if (this.lines > 200 || this.lines <= 0) {
					this.isExceed = true;
				} else {
					this.isExceed = false;
				}
			},
			reset() {
				this.entity.comment = '';
				this.lines = 0;
			},
			checked() {
				let param = {};
				param.comment = this.entity.comment;
				param.code = this.code;
				param.price = this.price;
				this.onSubmit(param);
			},
		},

		setup() {},
		watch: {
			visible: {
				immediate: true,
				handler(a) {
					this.isShow = a;
				},
			},
			isShow: {
				handler(a) {
					this.$emit('changeVisible', a);
				},
			},
			code: {
				handler(value) {
					console.log('当前批量窗口code：' + value);
					switch (value) {
						case '21007':
						case '21005':
						case '21004':
						case '21008':
						case '21009':
							this.linkPlaceholder = '请输入视频链接，换行代表一条数据';
							break;
						case '21006':
							this.linkPlaceholder = '请输入Tiktok号或主页链接，换行代表一条数据';
							break;
						default:
							break;
					}
				},
			},
		},
	};
</script>
<style lang="scss" scoped>
	.buttonGroup {
		padding-top: 25px;
		text-align: center;
	}

	.contentText {
		font-size: 12px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #0086f5;
		line-height: 18px;
		text-align: left;
	}

	.batchOrderModal_warp {
		.batchOrderModal_warp-tips {
			padding-left: 10%;
			padding-right: 10%;
		}

		::v-deep {
			.ant-modal-content {
				//   min-width: 449px;
				//   min-height: 597px;
				background: #e5f3ff;
				box-shadow: 4px 13px 20px 0px rgba(146, 146, 146, 0.49);
				border-radius: 12px;
			}

			.batchOrderModal_warp_databody {
				width: 85%;
				margin: 0px auto;

				.batchOrderModal_warp_databody_tips {
					padding-top: 0px;
					padding-left: 50%;
					font-size: 12px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #0091ff;

					.exceed {
						color: red;
					}
				}
			}

			.ant-input-group.ant-input-group-compact {
				.ant-select:last-child {
					.ant-select-selector {
						border-radius: 15px;
					}
				}
			}

			.ant-modal-header {
				background: #e5f3ff;
				border-radius: 12px;
				text-align: center;
				border-bottom: 0px;
			}

			.ant-modal-title {
				font-size: 14px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #0086f5;
				line-height: 20px;
			}
		}
	}

	@media only screen and (max-width: 720px) {
		.batchOrderModal_warp {
			.batchOrderModal_warp-tips {
				padding-left: initial;
				padding-right: initial;
			}

			.batchOrderModal_warp_databody {
				width: 90%;
				margin: 0px auto;
			}

			.contentText {
				width: 90%;
				margin: 0px auto;
			}
		}
	}
</style>
