<template>
  <div class="contentWarp" ref="contentWarp">
    <div class="contentWarp_body">
      <div class="title">充值余额</div>
      <div class="qrcode" ref="qrcode"></div>
      <div class="foot_1">微信客户端扫描二维码完成支付</div>
      <div class="foot_2">请尽快支付，该二维码半个小时内有效</div>
    </div>
  </div>
</template>

<script>

import { h } from "vue";
import QRCode from "qrcodejs2";
import userApi from "../../http/apiUtil/userApi";
export default {
  name: "",
  mixins: [],
  components: {},
  props: {
    url: { type: String, default: null },
    order_no: { type: String, default: null },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() { },
  async mounted() {
    var url1 = this.url;
    var n = this.order_no;
    if (!url1) {
      this.$message.error("参数异常");
    } else {
      this.makeQrcode(url1);
    }
    let i = 1;
    let interval = setInterval(async () => {
      console.log(i++ + "执行力了一次");
      //定时查询订单状态
      var res = await userApi.GetPayOrderState({ param: { orderNo: n } });
      if (res.ret_obj === 1) {
        this.timeoutModal();
        clearInterval(interval);
      }
    }, 2000);
  },
  methods: {
    //延时关闭弹窗
    timeoutModal() {
      let secondsToGo = 6;
      const modal = this.$modal.success({
        getContainer: () => this.$refs.contentWarp,
        onOk: () => {
          modal.destroy();
          this.$emit("qrcodeShow", false);
        },
        title: h(
          "div",
          {
            style: {
              color: "#0086f5",
              "font-size": "18px",
              "font-weight": "600",
            },
          },
          "提示"
        ),
        content: h(
          "div",
          {
            style: {
              color: "#0086f5",
              "font-size": "14px",
              "font-weight": "600",
            },
          },
          [
            h("p", `已成功支付，当前页面将在 ${secondsToGo} 秒后关闭，`),
            h("p", "当前页面关闭后，请点击支付完成按钮。"),
          ]
        ),
      });
      const interval = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          content: h(
            "div",
            {
              style: {
                color: "#0086f5",
                "font-size": "14px",
                "font-weight": "600",
              },
            },
            [
              h("p", `已成功支付，当前页面将在 ${secondsToGo} 秒后关闭，`),
              h("p", "当前页面关闭后，请点击支付完成按钮。"),
            ]
          ),
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        modal.destroy();
        this.$emit("qrcodeShow", false);
      }, secondsToGo * 1000);
    },
    makeQrcode(text) {
      new QRCode(this.$refs.qrcode, {
        // text 需要转二维码的内容 可以是文本也可以是一个链接 是链接会直接跳走
        text: text,
        width: 308,
        height: 250,
        colorDark: "#333", //二维码颜色
        colorLight: "#fff", //二维码背景颜色
        correctLevel: QRCode.CorrectLevel.L, //容错率,L/M/H
      });
    },
  },
};
</script>

<style scoped lang="scss">
.contentWarp {
  background-color: white;

  //   width: 100%;
  height: 100%;
  position: relative;

  .contentWarp_body {
    // top: 50%;
    // left: 50%;
    // margin: -266px 0 0 -266px;
    // position: absolute;
    // display: inline-block;
    // text-align: center;
    display: grid;
    justify-items: center;

    .title {
      font-size: 22px;
    }

    .qrcode {}

    .foot_1 {
      font-size: 22px;
      color: #666666;
    }

    .foot_2 {
      font-size: 18px;
      color: red;
    }
  }

  :deep(.ant-modal-content) {
    background: #e5f3ff;
    box-shadow: 4px 13px 20px 0px rgba(146, 146, 146, 0.49);
    border-radius: 12px;

    .ant-input-group.ant-input-group-compact {
      .ant-select:last-child {
        .ant-select-selector {
          border-radius: 15px;
        }
      }
    }

    .ant-btn {
      border-radius: 8px;
    }

    .ant-modal-header {
      background: #e5f3ff;
      border-radius: 12px;
      text-align: center;
      border-bottom: 0px;
    }
  }

  .ant-modal-title {
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #0086f5;
    line-height: 20px;
  }

  .contentLabel {
    line-height: 32px;
    padding-right: 0.5rem;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #0086f5;
  }
}
</style>
