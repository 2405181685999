<template>
    <div class="m-UserList-wrap" ref="m_UserList_wrap">
        <div class="wrap-summary">
            <div class="wrap-summary-item">
                <div class="p-red p"></div>
                <div>
                    <div>{{ statistics.totalAccount }}￥</div>
                    <div>总账户数</div>
                </div>
            </div>
            <div class="wrap-summary-item">
                <div class="p-green p"></div>
                <div>
                    <div>{{ statistics.todayAddAccount }}￥</div>
                    <div>今日新增账户数</div>
                </div>
            </div>

        </div>
        <div class="wrap-body">
            <div class="wrap-body-operate">
                <button class="button_red" @click="showCreateUserModal(true)">添加用户</button>
            </div>
            <div class="wrap-body-list">
                <van-list v-model:loading="listConfig.loading" :finished="listConfig.finished" finished-text="没有更多了"
                    @load="getList" v-model:error="listConfig.error" error-text="请求失败，点击重新加载">
                    <div class="wrap-body-list-item" v-for="(item, index) in table.data" :key="item.order_no"
                        :index="index">
                        <div class="item-rows">
                            <div>
                                <div>用户编号</div>
                                <div>{{ item.user_key }}</div>
                            </div>
                            <div>
                                <div>手机号码</div>
                                <div>{{ item.username }}</div>
                            </div>
                            <div>
                                <div>账号等级</div>
                                <div>{{ util.getIsAgent(item.is_agent) }}</div>
                            </div>
                        </div>
                        <div class="item-rows">
                            <div>
                                <div>账户金额</div>
                                <div>{{ util.formatPrice(item.user_amout) }}￥</div>
                            </div>
                            <div>
                                <div>用户状态</div>
                                <div>{{ util.getUserState(item.user_status) }}</div>
                            </div>
                            <div>
                                <div>注册时间</div>
                                <div>{{ dayjs(item.createtime).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                ) }}</div>
                            </div>
                        </div>
                        <div class="item-rows">
                            <div>
                                <div>
                                    操作
                                </div>
                                <div>
                                    <a class="a-red" @click="showAmountTransferModal(item.username)">代充余额</a>
                                    <a class="a-red" v-if="item.user_status == 1"
                                        @click="UpdateState(0, item.userid)">停用</a>
                                    <a class="a-red" v-else @click="UpdateState(1, item.userid)">启用</a>
                                    <a class="a-red" @click="
                                        showAgentProportionModal(item.username, item.share_proportion)">返点比例</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </van-list>

            </div>
        </div>
        <create-user-modal v-model:visible="createUserModalData.isShow" @changeVisible="showCreateUserModal">
        </create-user-modal>
        <amount-transfer-modal v-model:visible="amountTransferMData.visible" @changeVisible="changeVisible"
            :mobile="amountTransferMData.mobile" @submit="onSubmitAmountTransfer">
        </amount-transfer-modal>
        <agent-proportion-modal v-model:visible="agentProportionData.visible" @changeVisible="agentProportionChangeVisible"
            :mobile="agentProportionData.mobile" :shareProportion="agentProportionData.shareProportion"
            @submit="onSubmitAgentProportion">
        </agent-proportion-modal>
    </div>
</template>

<script>
import userApi from "@/http/apiUtil/userApi";
import util from "@/common/util";
import dayjs from "dayjs";
import { List } from "vant";
import createUserModal from "@/components/user/createUserModal.vue";
import amountTransferModal from "@/components/invate/amountTransferModal.vue";
import agentProportionModal from "@/components/invate/agentProportionModal.vue";
export default {
    name: '',
    mixins: [],
    components: {
        [List.name]: List,
        createUserModal,
        amountTransferModal,
        agentProportionModal
    },
    props: {},
    data() {
        return {
            table: {
                page: 0,
                size: 20,
                data: [],
            },
            util: util,
            dayjs: dayjs,
            searchModel: {

            },
            statistics: {
                totalAccount: 0,
                todayAddAccount: 0,
            },
            listConfig: {
                error: false,
                loading: false,
                finished: false,
            },
            createUserModalData: {
                isShow: false,
            },
            amountTransferMData: {
                mobile: "",
                visible: false,
            },
            agentProportionData: {
                mobile: "",
                visible: false,
                shareProportion: 0,
            },
        }
    },
    computed: {},
    watch: {},
    created() { },
    async mounted() {
        this.$emit('onClickMenu', 4)

    },
    methods: {
        search() {
            this.table.data = [];
            this.table.page = 0;
            this.getList();

        },
        //获取列表
        async getList() {
            let that = this.searchModel;
            console.log(that);
            this.table.page = this.table.page + 1;
            let param = {
                page: this.table.page,
                size: this.table.size,
            };
            param.SearchUserName = this.searchModel.username;
            param.SearchEmail = this.searchModel.email;
            var res = await userApi.UserAgentList(param);
            if (res.ret_code == 200) {
                this.listConfig.loading = false;
                if (res.ret_obj.list.length > 0) {
                    res.ret_obj.list.forEach((item) => {
                        this.table.data.push(item);
                    });
                } else {
                    this.listConfig.finished = true;
                }
                this.statistics.totalAccount = res.ret_obj.TotalChildrenUserCount;
                this.statistics.todayAddAccount = res.ret_obj.TodayChildrenUserCount;
            }
        },
        showCreateUserModal(flag) {
            this.createUserModalData.isShow = flag;
            if (!flag) {
                this.search();
            }
        },
        //修改用户状态
        UpdateState(userState, userId) {
            this.$modal.confirm({
                content: `是否【${userState ? "启用" : "停用"}】该用户？`,
                getContainer: () => this.$refs.m_UserList_wrap,
                // icon: createVNode(ExclamationCircleOutlined),
                onOk: async () => {
                    let param = {};
                    param.UserState = userState;
                    param.UserId = userId;
                    let res = await userApi.UpdateState(param);
                    if (res.ret_code === 200) {
                        this.$message.success("修改成功");
                        this.search();
                    }
                },
                cancelText: "取消",
                onCancel() { },
            });
        },
        changeVisible(flag) {
            this.amountTransferMData.visible = flag;
        },
        agentProportionChangeVisible(flag) {
            this.agentProportionData.visible = flag;
        },
        async onSubmitAmountTransfer(model) {
            let res = await userApi.AmountTransfer(model);
            if (res.ret_code == 200) {
                this.$message.success(res.ret_msg);
                this.amountTransferMData.visible = false;
                this.search();
            }
        },
        async onSubmitAgentProportion(model) {
            let res = await userApi.AgentProportionSettings(model);
            if (res.ret_code == 200) {
                this.$message.success(res.ret_msg);
                this.agentProportionChangeVisible(false);
                this.search();
            }
            console.log(model);
        },
        showAmountTransferModal(mobile) {
            this.amountTransferMData.visible = true;
            this.amountTransferMData.mobile = mobile;
        },
        showAgentProportionModal(mobile, shareProportion) {
            this.agentProportionData.visible = true;
            this.agentProportionData.mobile = mobile;
            this.agentProportionData.shareProportion = shareProportion;
        },
    }
}
</script>

<style scoped lang="scss">
.m-UserList-wrap {
    margin: 0px 0.5rem 0px 0.5rem;

    .wrap-summary {
        color: #0086F5;
        display: flex;
        justify-content: space-around;
        background: #E4F3FF;
        box-shadow: inset 0px 1px 3px 0px rgba(0, 134, 245, 0.51);
        border-radius: 8px;
        padding: 0.5rem 0px 0.5rem 0px;

        .wrap-summary-item {
            display: flex;


            .p {
                width: 5px;
                border-radius: 3px;
            }

            .p-red {
                background: linear-gradient(180deg, #FFB2B2 0%, #FF7A7A 100%);
            }

            .p-green {
                background: linear-gradient(360deg, rgb(68, 215, 182) 0%, rgb(195, 233, 213) 100%);
            }

            .p-blur {
                background: linear-gradient(150deg, rgb(131, 203, 255) 0%, rgb(0, 122, 255) 100%);
            }
        }

        .wrap-summary-item>div:last-child {
            margin-left: 0.5rem;
        }
    }

    .wrap-body {
        .wrap-body-search {
            display: flex;
            justify-content: space-around;
            padding: 0.5rem 0px;
            align-items: center;
        }

        .wrap-body-operate {
            display: flex;
            padding: 0.5rem 0px;

            button {
                margin-right: 1rem;
            }
        }

        .wrap-body-list {


            .wrap-body-list-item {
                margin-bottom: 1rem;
                color: #0091FFFF;
                background: #E4F3FF;
                border-radius: 8px;
                padding: 0.5rem;

                .item-rows {
                    display: flex;
                    justify-content: space-around;
                }

                .item-rows>div:not(:first-child) {
                    padding-left: 0.5rem;
                }

                .item-rows>div {
                    min-width: 33.3%;
                    flex-grow: 1;
                    word-break: break-all;
                    white-space: normal;
                }


            }

            .wrap-body-list-item>div:not(:first-child) {
                margin-top: 1rem;
            }
        }
    }
}

.button_red {
    cursor: pointer;
    width: 96px;
    height: 32px;
    background: linear-gradient(180deg, #FFB2B2 0%, #FF7A7A 100%);
    border-radius: 7px;
    border: 2px solid #FFB2B2;
    color: white;
}

.a-red {
    margin-right: 1rem;
    color: red;
    text-decoration: underline;
}
</style>
